import React, { useState } from "react";
import { useField } from "formik";
import { StyledOTPInput } from "./Styled";
import { useTheme } from "styled-components";

function OtpField({ name, error }) {
  const [placeholder] = useState("------");
  const [field] = useField(name);
  const theme = useTheme();
  return (
    <StyledOTPInput
      value={field.value}
      onChange={field.onChange(name)}
      numInputs={6}
      placeholder={placeholder}
      isInputNum={true}
      error={error}
      renderInput={(props) => <input {...props} />}
      inputStyle={{
        width: 40,
        height: 50,
        borderRadius: 6,
        backgroundColor: theme.colors.gray,
        border: "gray 1.5px solid",
        marginRight: 5,
        padding: 0,
      }}
      containerStyle={{ maxWidth: 450 }}
    />
  );
}

export default OtpField;
