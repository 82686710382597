export interface APIKey {
  name: string;
  api_key_hash: string;
  api_key_masked: string;
  status: "REVOKED" | "ACTIVE" | "DELETED";
  issued_at: number;
}

export interface OptimisticAPIKey extends APIKey {
  _optimistic: true;
}

export function isOptimisticAPIKey(apiKey: APIKey): apiKey is OptimisticAPIKey {
  return (apiKey as OptimisticAPIKey)._optimistic === true;
}

export type APIResponse<TData = unknown> = {
  request_id: string;
} & (
  | {
      status: "success";
      data: TData;
    }
  | {
      status: "error";
      error: {
        message: string;
        code: string;
      };
    }
);
