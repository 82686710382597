import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  ChartData,
  ChartOptions,
  LinearScale,
  PointElement,
  Tooltip,
} from "chart.js";
import "chartjs-adapter-moment";
import moment from "moment";
import React from "react";
import { Bar } from "react-chartjs-2";
import styled from "styled-components";

ChartJS.defaults.font.family = "Nunito";

ChartJS.register(LinearScale, BarElement, CategoryScale, PointElement, Tooltip);

const options: ChartOptions<"bar"> = {
  responsive: true,
  font: {
    size: 12,
  },
  plugins: {
    tooltip: {
      displayColors: false,
      callbacks: {
        label: (context) => {
          const label = context.dataset.label || "";
          const value = context.parsed.y || 0;
          return `${value} ${label} `;
        },
        title: (context) => {
          const value = context[0].parsed.x || 0;
          return moment(value).format("MMMM YYYY");
        },
      },
      backgroundColor: "#000",
      titleFont: {
        size: 12,
        weight: "normal",
      },
      bodyFont: {
        size: 14,
        weight: "bold",
      },
    },
  },
  layout: {
    padding: 0,
  },
  scales: {
    y: {
      ticks: {
        stepSize: 1,
      },
      position: "right",
      grid: {
        display: false,
      },
      beginAtZero: true,
    },
    x: {
      type: "time",
      time: {
        unit: "month",
        displayFormats: {
          month: "MMM",
        },
      },
    },
  },
  maintainAspectRatio: false,
};

/** https://www.chartjs.org/docs/latest/configuration/responsive.html#important-note */
const Container = styled.div`
  position: relative;
  height: 300px;
  width: 100%;
`;

export default function DesktopChart({ data }: { data?: ChartData<"bar"> }) {
  return <Container>{data && <Bar options={options} data={data} />}</Container>;
}
