import { ChartData } from "chart.js";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useDailyUsage } from "./useDailyUsage";
import DesktopChart from "./DesktopChart";
import MobileChart from "./MobileChart";

export default function ResponsiveChart() {
  const [mediaQueryList] = useState(window.matchMedia("(max-width: 768px)"));
  const [isMobile, setIsMobile] = useState(() => mediaQueryList.matches);
  const { data: dailyUsage } = useDailyUsage();

  const data = useMemo(() => {
    if (dailyUsage) {
      // remove first 7 consecutive days with 0 requests
      const filteredDailyUsage = dailyUsage.filter(
        (item, index, array) =>
          index < 7 || array[index - 1].count !== 0 || item.count !== 0
      );
      const labels = filteredDailyUsage?.map((item) =>
        moment({
          year: item.year,
          month: item.month - 1,
          day: item.day,
        })
      );
      const data: ChartData<"line"> = {
        labels,
        datasets: [
          {
            label: "API requests",
            data: filteredDailyUsage?.map((item) => item.count) || [],
            borderColor: "#FFCD23",
            pointBorderColor: "#FFCD23",
            pointBackgroundColor: "#fff",
            pointBorderWidth: 2,
          },
        ],
      };
      return data;
    }
  }, [dailyUsage]);

  useEffect(() => {
    const mediaQueryList = window.matchMedia("(max-width: 768px)");
    const listener = (e: MediaQueryListEvent) => {
      setIsMobile(e.matches);
    };
    mediaQueryList.addEventListener("change", listener);
    return () => {
      mediaQueryList.removeEventListener("change", listener);
    };
  }, [setIsMobile, mediaQueryList]);

  if (isMobile) {
    return <MobileChart data={data} />;
  } else {
    return <DesktopChart data={data} />;
  }
}
