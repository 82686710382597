import React from "react";
import { Logo, StickyHeader } from "./Styled";
import Group from "../../../Assets/Images/Group.svg";
const Header = () => {
  return (
    <StickyHeader>
      <Logo src={Group} />
    </StickyHeader>
  );
};

export default Header;
