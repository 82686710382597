import React from "react";
import styled from "styled-components";
import { useGetAllKeysQuery } from "../APIKeys/utils/getAllKeys";
import { useCustomerInfo } from "../Billing/useCustomerInfo";
import CreateKeyWidget from "./CreateKeyWidget";
import FreeCallsWidget from "./FreeCallsWidget";
import TotalCallsWidget from "./TotalCallsWidget";

export default function Presentational() {
  const { hasFreeTrial, isLoading: customerLoading } = useCustomerInfo();
  const { data: keys, isLoading: keysLoading } = useGetAllKeysQuery();
  const hasNoKeys = keys?.length === 0;

  if (keysLoading || customerLoading) return null;

  return (
    <Container>
      {hasNoKeys ? (
        <CreateKeyWidget />
      ) : hasFreeTrial ? (
        <FreeCallsWidget />
      ) : (
        <TotalCallsWidget />
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 0 40px;
  margin: 0 auto;
  max-width: 1000px;
`;
