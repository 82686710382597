import React, { useContext } from "react";
import { AuthContext } from "../../../AppContext";
import Header from "../../Common/Header/Presendational";
import RegistrationForm from "./RegisterForm";
import SignInForm from "./SignInForm";
import {
  DetailsContainer,
  DetailsTitle,
  PageDetails,
  RegisterContainer,
} from "./Styled";

const Register = ({ method }) => {
  const { step } = useContext(AuthContext);

  // This is a custom state that happens only
  // when you "Sign in with Google"

  if (method === "success") {
    window.location.replace("/dashboard");
  }

  return (
    <div style={{ display: "flex" }}>
      <Header />
      <RegisterContainer step={step}>
        {method === "register" ? (
          <RegistrationForm />
        ) : (
          method === "login" && <SignInForm />
        )}
      </RegisterContainer>
      <DetailsContainer step={step}>
        <DetailsTitle>
          Get the power of our AI. <br />
          Now. For free.
        </DetailsTitle>
        <PageDetails>
          With our free trial, you're granted access to the whole botshelf.ai
          Library of pre-trained BOTs, for a total of 1000 API requests.
        </PageDetails>
      </DetailsContainer>
    </div>
  );
};

export default Register;
