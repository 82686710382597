import { Auth } from "aws-amplify";
import { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

export default function PrivacySection() {
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleHideModal = () => {
    setShowModal(false);
  };
  async function deleteUser() {
    try {
      const result = await Auth.deleteUser();
      console.log(result);
      setShowModal(false);
    } catch (error) {
      console.log("Error deleting user", error);
    }
  }

  const modalBody = (
    <div>
      <p>
        All your models and data will be deleted permanently. This cannot be
        undone. Are you sure you want to proceed?
      </p>
      <p>
        If you confirm this action you will receive an email with a link to
        proceed with the process.
      </p>

      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          marginTop: "48px",
        }}
      >
        <Button primary onClick={handleHideModal}>
          Cancel
        </Button>
        <Button onClick={deleteUser}>Confirm</Button>
      </div>
    </div>
  );
  return (
    <div>
      <Heading>Privacy preferences</Heading>
      <Container>
        <Title> Cookie preferences</Title>
        <Wrapper>
          <p>
            {" "}
            View and manage cookie and privacy preferences for your account.
          </p>
          <StyledLink to="https://botshelf.ai/manage-cookies/" target="_blank">
            Manage Cookies
          </StyledLink>
        </Wrapper>
      </Container>
      {/*<div style={{ paddingLeft: "24px"}}>*/}
      {/*    <Title>Close account</Title>*/}
      {/*    <Wrapper>*/}
      {/*      <p>*/}
      {/*          This can’t be reversed. All boards you’ve created will be permanently*/}
      {/*          erased.*/}
      {/*      </p>*/}
      {/*      <StyledButton onClick={handleShowModal}>Delete account</StyledButton>*/}

      {/*  </Wrapper>*/}
      {/*    <CustomModal*/}
      {/*        open={showModal}*/}
      {/*        body={modalBody}*/}
      {/*        title={<ModalTitle>Delete your account and data?</ModalTitle>}*/}
      {/*        onCancel={handleHideModal}*/}
      {/*    />*/}
      {/*</div>*/}
    </div>
  );
}

const Heading = styled.h1`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 33px;
  line-height: 116.4%;
  margin: 85px 0 15px;
`;
const ModalTitle = styled.p`
  font-style: normal;
  font-weight: 900;
  font-size: 27px;
  line-height: 116.4%;
  /* or 31px */

  /* Black */

  color: #000000;
`;
const Container = styled.div`
  background-color: white;
  padding-left: 24px;
  padding-bottom: 24px;
  padding-top: 24px;
  border-radius: 20px;
`;
const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 24px;
  align-items: center;
`;
const Title = styled.p`
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 116.4%;
  margin-bottom: 0;
`;
export const StyledLink = styled(Link)`
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 116.4%;
  text-decoration-line: underline;
  color: #000000;
`;

const StyledButton = styled.p`
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 116.4%;
  text-decoration-line: underline;
  color: #b3001b;
`;

const Button = styled.button`
  background: ${(props) => (props.primary ? "white" : "black")};
  color: ${(props) => (props.primary ? "black" : "white")};
  width: 207px;
  height: 57px;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.18);
  border-radius: 6px;
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 34px;
  /* identical to box height */
  text-align: center;
  text-transform: uppercase;
`;
