import styled from "styled-components";
import NotifyBanner from "../../Common/NotifyBanner";
import LogInSection from "./LogInSection";
import PrivacySection from "./PrivacySection";
import UserData from "./UserData";
import { bannerContent } from "../PaymentMethod/Presentational";

export default function Account() {
  return (
    <Container>
      <Heading>Account details</Heading>
      <Description>
        Have a look at your account & billing info and how you want us to
        interact with you
      </Description>
      <NotifyBanner
        content={bannerContent}
        title={<NotifyBanner.Title />}
        description={<NotifyBanner.Description />}
        icon={<NotifyBanner.InfoIcon />}
      />
      <LogInSection />
      <UserData />
      <PrivacySection />
    </Container>
  );
}

const Container = styled.div`
  padding: 1em 24px;
  @media (min-width: 768px) {
    padding: 1em 40px;
  }
  max-width: 1000px;
  margin: 0 auto;
`;

const Heading = styled.h1`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 33px;
  line-height: 116.4%;
  margin: 0 0 8px;
`;

const Description = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  margin: 0 0 16px;
`;
