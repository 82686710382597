import { StyledWarningIcon } from "./Styled";
import React from "react";

export type NotifyBannerWarningIconProps = React.ComponentProps<
  typeof StyledWarningIcon
>;

export function NotifyBannerWarningIcon(props: NotifyBannerWarningIconProps) {
  return <StyledWarningIcon {...props} />;
}
