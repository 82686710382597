import styled from "styled-components";

const StatusBadge = styled.span`
  font-weight: 700;
  font-size: 14px;
  line-height: 116.4%;
  padding: 5px 10px;
  border-radius: 4px;
  min-width: 88px;
  display: block;
  text-align: center;
  width: max-content;
`;

export const GreenStatusBadge = styled(StatusBadge)`
  color: #ffffff;
  background: ${({ theme }) => theme.colors.green};
  border: 1px solid ${({ theme }) => theme.colors.green};
  border-radius: 4px;
`;

export const RedStatusBadge = styled(StatusBadge)`
  color: #ffffff;
  background: ${({ theme }) => theme.colors.red};
  border: 1px solid ${({ theme }) => theme.colors.red};
`;

export const GrayStatusBadge = styled(StatusBadge)`
  color: #ffffff;
  background: #868686;
  border: 1px solid #868686;
`;

export default StatusBadge;
