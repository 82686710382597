//import { useEffect } from "react";
import styled from "styled-components";
import { useMainContainerContext } from "./Provider";

const WarningIcon = styled.svg.attrs({
  width: "24",
  height: "24",
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: (
    <>
      <circle cx="12" cy="12" r="12" />
      <circle cx="12" cy="12" r="9" />
      <path
        d="M12 6.75C12.45 6.75 12.75 7.1 12.75 7.625L12.75 12.875C12.75 13.4 12.45 13.75 12 13.75C11.55 13.75 11.25 13.4 11.25 12.875L11.25 7.625C11.25 7.1 11.55 6.75 12 6.75Z"
        fill="black"
      />
      <path
        d="M12 15.25C11.5858 15.25 11.25 15.5858 11.25 16C11.25 16.4142 11.5858 16.75 12 16.75C12.4142 16.75 12.75 16.4142 12.75 16C12.75 15.5858 12.4142 15.25 12 15.25Z"
        fill="black"
      />
    </>
  ),
})`
  circle:nth-child(1) {
    fill: ${({ theme }) => theme.colors.gray};
  }
  circle:nth-child(2) {
    fill: ${({ theme }) => theme.colors.white};
  }
`;

function NotificationBar() {
  return (
    <Container>
      <WarningIcon />
      <Message>
        We were unable to process your last bill time.
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a href="#">Contact us</a>
      </Message>
    </Container>
  );
}

export default function NotificationBarWrapper() {
  const { isNotificationBarVisible /*setIsNotificationBarVisible*/ } =
    useMainContainerContext();

  // useEffect(() => {
  //   // This code is here to simulate a request to the backend to check if the
  //   // user has an outstanding bill. If they do, we show the notification bar.
  //   const random = Math.floor(Math.random() * 1000);
  //   const timeout = setTimeout(() => {
  //     setIsNotificationBarVisible(true);
  //   }, random);
  //   return () => clearTimeout(timeout);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return isNotificationBarVisible ? <NotificationBar /> : null;
}

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  height: var(--notification-bar-height);
  display: flex;
  overflow: hidden;
  align-items: center;
  background: #ae0016;
  color: ${({ theme }) => theme.colors.white};
  padding: 0 25px;
  gap: 18px;
`;

const Message = styled.p`
  font-weight: 700;
  font-size: 16px;
  line-height: 116.4%;

  a {
    color: inherit;
    text-decoration: underline;
    text-decoration-thickness: 1px;
    text-underline-offset: 2px;
    font-weight: 500;
    margin-left: 0.5em;
  }
`;
