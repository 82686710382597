import { useEffect, useState } from "react";
import styled from "styled-components";
import DesktopTable from "./DesktopTable";
import MobileTable from "./MobileTable";
import { useGetAllKeysQuery } from "./utils/getAllKeys";
import React from "react";

// Over this amount of pixels, we render the desktop table
const DESKTOP_TABLE_BREAKPOINT = 1280;

export default function Table() {
  const { data = [] } = useGetAllKeysQuery();

  const [isDesktop, setIsDesktop] = useState(
    () => window.innerWidth >= DESKTOP_TABLE_BREAKPOINT
  );

  useEffect(() => {
    // listen for media query changes
    const mediaQueryList = window.matchMedia(
      `(min-width: ${DESKTOP_TABLE_BREAKPOINT}px)`
    );
    mediaQueryList.addEventListener("change", handleMediaQueryChange);
    return () => {
      mediaQueryList.removeEventListener("change", handleMediaQueryChange);
    };
    function handleMediaQueryChange(e: MediaQueryListEvent) {
      setIsDesktop(e.matches);
    }
  }, []);

  return (
    <Container>
      <TotalKeys>
        {data.length} API {data.length === 1 ? "key" : "keys"}
      </TotalKeys>
      {isDesktop ? <DesktopTable /> : <MobileTable />}
    </Container>
  );
}

const Container = styled.div`
  flex: 1;
`;

const TotalKeys = styled.p`
  font-style: normal;
  font-weight: 800;
  font-size: 17px;
  line-height: 23px;
  margin: 0 0 10px;
`;
