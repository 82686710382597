import styled from "styled-components";
import React from "react";

export const StyledContainer = styled.div`
  background: #ffffff;
  border: 2px solid #000000;
  border-radius: 8px;
  padding: 20px;
  display: flex;
  gap: 10px;
`;

export const StyledTitle = styled.h3`
  font-weight: 800;
  font-size: 17px;
  line-height: 23px;
  margin: 0 0 4px;
`;

export const StyledDescription = styled.h3`
  font-weight: 500;
  font-size: 16px;
  line-height: 116.4%;
  color: #757575;
  margin: 0;
`;

export const StyledWarningIcon = styled.svg.attrs({
  viewBox: "0 0 32 32",
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  "aria-hidden": "true",
  children: (
    <>
      <circle cx="16" cy="16" r="16" />
      <circle cx="16" cy="16" r="12" />
      <path
        d="M16 9c.6 0 1 .466 1 1.166v7c0 .7-.4 1.167-1 1.167s-1-.467-1-1.167v-7c0-.7.4-1.166 1-1.166ZM16 20.333a1 1 0 1 0 0 2 1 1 0 0 0 0-2Z"
        fill="#000"
      />
    </>
  ),
})`
  width: 32px;
  height: 32px;
  position: relative;
  top: -4px;
  flex: 0 0 auto;

  circle:nth-child(1) {
    fill: ${({ theme }) => theme.colors.gray};
  }
  circle:nth-child(2) {
    fill: ${({ theme }) => theme.colors.yellow};
  }
`;

<svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
  <circle cx="16" cy="16" r="12" fill="#4F9D69" />
  <path
    d="M16 22.333c-.6 0-1-.466-1-1.166v-7c0-.7.4-1.167 1-1.167s1 .467 1 1.167v7c0 .7-.4 1.166-1 1.166ZM16 11a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
    fill="#fff"
  />
</svg>;

export const StyledInfoIcon = styled.svg.attrs({
  viewBox: "0 0 32 32",
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  "aria-hidden": "true",
  children: (
    <>
      <circle cx="16" cy="16" r="12" />
      <path d="M16 22.333c-.6 0-1-.466-1-1.166v-7c0-.7.4-1.167 1-1.167s1 .467 1 1.167v7c0 .7-.4 1.166-1 1.166ZM16 11a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z" />
    </>
  ),
})`
  width: 32px;
  height: 32px;
  position: relative;
  top: -4px;
  flex: 0 0 auto;

  circle {
    fill: ${({ theme }) => theme.colors.green};
  }
  path {
    fill: ${({ theme }) => theme.colors.white};
  }
`;

export const StyledHideButton = styled.button`
  align-self: flex-end;
  text-align: right;
  flex: 1 0 auto;
  cursor: pointer;

  appearance: none;
  border: none;
  background: none;

  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 116.4%;
  text-decoration-line: underline;
`;
