import styled from "styled-components";
import React from "react";

export default function LoadingOverlay() {
  return (
    <Container>
      <div>
        <Message>Loading...</Message>
      </div>
    </Container>
  );
}

const Container = styled.div`
  position: absolute;
  top: var(--row-height);
  bottom: 0;
  left: 0;
  right: 0;
  background: #ffffff;
  display: grid;
  place-content: center;
  z-index: 1;
  border-radius: var(--border-radius);

  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    max-width: 500px;
    text-align: center;
  }
`;

const Message = styled.p`
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  margin: 0;
`;
