import styled from "styled-components";
import Header from "./Header";
import Table from "./Table";
import NotifyBanner, { NotifyBannerContent } from "../../Common/NotifyBanner";
import React, { useMemo } from "react";
import { SWRConfig } from "swr";
import { useGetAllKeysQuery } from "./utils/getAllKeys";
import { MAX_NUMBER_OF_KEYS } from "./utils/common";

const bannerContent: NotifyBannerContent = {
  title: "API key Limit reached",
  description: `You hit the maximum number of API keys manageable by a user. To create a new one, please delete one or more of your revoked keys.`,
};

const Section = styled.section`
  padding: 1em 24px;
  @media (min-width: 768px) {
    padding: 1em 40px;
  }

  display: flex;
  flex-direction: column;
  gap: 40px;

  max-width: 1000px;
  margin: 0 auto;
`;

export function APIKeysComponent() {
  const { data } = useGetAllKeysQuery();
  const hasReachedLimit = useMemo(
    () => (data?.length ?? 0) >= MAX_NUMBER_OF_KEYS,
    [data]
  );
  return (
    <Section>
      <Header />
      {hasReachedLimit && (
        <NotifyBanner
          content={bannerContent}
          title={<NotifyBanner.Title />}
          description={<NotifyBanner.Description />}
          icon={<NotifyBanner.WarningIcon />}
        />
      )}
      <Table />
    </Section>
  );
}

export default function APIKeys() {
  return (
    <SWRConfig
      value={{
        // Calls to the API are not cached.
        // We disable revalidation to avoid unnecessary calls.
        revalidateIfStale: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
      }}
    >
      <APIKeysComponent />
    </SWRConfig>
  );
}
