import React from "react";
import { Customer } from "../../Billing/useCustomerInfo";
import CounterList from "./CounterList";

export type BillableRequestsProps = {
  customer?: Customer;
};

export default function BillableRequests({ customer }: BillableRequestsProps) {
  const totalRequests = customer?.total_requests;
  const freeRequestsUsed = customer?.free_requests_used;
  return (
    <>
      <CounterList.Label>Billable requests</CounterList.Label>
      <CounterList.Value>
        {typeof totalRequests === "number" &&
        typeof freeRequestsUsed === "number"
          ? totalRequests - freeRequestsUsed
          : "-"}
      </CounterList.Value>
    </>
  );
}
