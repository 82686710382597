import NotifyBanner from "../../Common/NotifyBanner";
import React from "react";

const content = {
  title: "Free trial",
  description:
    "Good News! You're still enjoying our free trial: no bills, no invoices.",
};

export default function FreeTrial() {
  return (
    <NotifyBanner
      content={content}
      title={<NotifyBanner.Title />}
      description={<NotifyBanner.Description />}
      icon={<NotifyBanner.InfoIcon />}
    />
  );
}
