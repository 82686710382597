import { SortDirection, SortingColumn } from "@tanstack/react-table";
import React, { PropsWithChildren } from "react";
import styled from "styled-components";
import { APIKey } from "./utils/types";

export type SortableHeaderProps = PropsWithChildren<{
  isSorted?: SortDirection | false;
  toggleSorting: SortingColumn<APIKey>["toggleSorting"];
}>;

export default function SortableHeader({
  children,
  isSorted,
  toggleSorting,
}: SortableHeaderProps) {
  return (
    <Container>
      <SortButton
        aria-label="Sort"
        onClick={() => toggleSorting()}
        $isSorted={isSorted}
      >
        <svg
          role="presentation"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.24953 21C6.24953 21.4142 6.58532 21.75 6.99953 21.75C7.41374 21.75 7.74953 21.4142 7.74953 21H6.24953ZM7.52986 2.46967C7.23697 2.17678 6.76209 2.17678 6.4692 2.46967L1.69623 7.24264C1.40334 7.53553 1.40334 8.01041 1.69623 8.3033C1.98912 8.59619 2.464 8.59619 2.75689 8.3033L6.99953 4.06066L11.2421 8.3033C11.535 8.59619 12.0099 8.59619 12.3028 8.3033C12.5957 8.01041 12.5957 7.53553 12.3028 7.24264L7.52986 2.46967ZM7.74953 21V3H6.24953V21H7.74953Z"
            fill="currentColor"
          />
          <path
            d="M16.2495 3C16.2495 2.58579 16.5853 2.25 16.9995 2.25C17.4137 2.25 17.7495 2.58579 17.7495 3H16.2495ZM17.5298 21.5303C17.2369 21.8232 16.7621 21.8232 16.4692 21.5303L11.6962 16.7574C11.4033 16.4645 11.4033 15.9896 11.6962 15.6967C11.9891 15.4038 12.464 15.4038 12.7569 15.6967L16.9995 19.9393L21.2421 15.6967C21.535 15.4038 22.0099 15.4038 22.3028 15.6967C22.5957 15.9896 22.5957 16.4645 22.3028 16.7574L17.5298 21.5303ZM17.7495 3V21H16.2495V3H17.7495Z"
            fill="currentColor"
          />
        </svg>
      </SortButton>
      {children}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const SortButton = styled.button<{
  $isSorted?: SortableHeaderProps["isSorted"];
}>`
  appearance: none;
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  cursor: pointer;

  svg path:first-child {
    fill: ${(props) =>
      props.$isSorted === "asc" ? "#000" : "rgba(0,0,0,0.5)"};
  }
  svg path:last-child {
    fill: ${(props) =>
      props.$isSorted === "desc" ? "#000" : "rgba(0,0,0,0.5)"};
  }
`;
