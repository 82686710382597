import { ComponentPropsWithoutRef } from "react";
import styled, { css } from "styled-components";

type ColumnStylesProps = { $colSpan?: number };
const columnStyles = css<ColumnStylesProps>`
  grid-column: span ${({ $colSpan }) => $colSpan || 1};
`;

const CounterListContainer = styled.dl`
  display: grid;
  grid-template-rows: auto auto;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  max-width: 100%;
  gap: 0 24px;
  margin: 0;

  @media (min-width: 768px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

const CounterListLabel = styled.dt<ColumnStylesProps>`
  ${columnStyles}
  grid-column: span ${({ $colSpan }) => $colSpan || 1};
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  text-align: left;
  color: rgba(0, 0, 0, 0.6);
  margin: 0;
  background: #fff;
  padding: 16px 16px 8px;
  border-radius: 16px 16px 0 0;
`;

const CounterListValue = styled.dd<ColumnStylesProps>`
  ${columnStyles}
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 36px;
  text-align: left;
  margin: 0;
  background: #fff;
  padding: 0 16px 16px;
  border-radius: 0 0 16px 16px;

  span:nth-child(2),
  span:nth-child(3) {
    color: rgba(0, 0, 0, 0.3);
  }

  display: flex;
  align-items: center;
  gap: 24px;
`;

export type CounterListProps = ComponentPropsWithoutRef<
  typeof CounterListContainer
>;

/**
 * @example
 * <CounterList>
 *  <CounterList.Label>Total requests</CounterList.Label>
 *  <CounterList.Value>100</CounterList.Value>
 * </CounterList>
 */
const CounterList = CounterListContainer as typeof CounterListContainer & {
  Label: typeof CounterListLabel;
  Value: typeof CounterListValue;
};

CounterList.displayName = "CounterList";
CounterList.Label = CounterListLabel;
CounterList.Value = CounterListValue;

export default CounterList;
