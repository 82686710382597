import React from "react";
import styled from "styled-components";
import { Customer } from "../../Billing/useCustomerInfo";
import CounterList from "./CounterList";

export type FreeRequestsProps = {
  customer?: Customer;
};

export default function FreeRequests({ customer }: FreeRequestsProps) {
  const freeRequests = customer?.free_requests;
  const freeRequestsUsed = customer?.free_requests_used;
  return (
    <>
      <CounterList.Label $colSpan={4}>Free trial requests</CounterList.Label>
      <CounterList.Value $colSpan={4}>
        <div>
          <span>{freeRequestsUsed ?? "-"}</span>
          <span>/</span>
          <span>{freeRequests ?? "-"}</span>
        </div>
        {freeRequests != null && freeRequestsUsed !== null && (
          <ProgressBar
            aria-hidden
            value={freeRequestsUsed}
            max={freeRequests}
          />
        )}
      </CounterList.Value>
    </>
  );
}

const ProgressBar = styled.progress`
  display: none;

  @media (min-width: 768px) {
    display: block;
    flex: 1;

    -webkit-appearance: none;
    appearance: none;
    height: 25px;
    max-width: 500px;
    position: relative;

    &::-webkit-progress-bar {
      border: 2px solid rgba(0, 0, 0, 0.5);
      background: transparent;
      border-radius: 99999px;
      overflow: hidden;
    }

    &::-webkit-progress-value {
      background: ${({ theme }) => theme.colors.yellow};
      border-radius: 99999px;
    }

    &::after {
      display: ${({ value, max }) =>
        Number(value) / Number(max) < 0.2 && "none"};
      content: "${({ value, max }) =>
        Math.floor((Number(value) / Number(max)) * 100) + "%"}";
      font-size: 16px;
      font-weight: 800;
      position: absolute;
      left: ${({ value, max }) =>
        Math.floor((Number(value) / Number(max)) * 100) + "%"};
      transform: translateX(calc(-50% - 32px));
      top: -5px;
    }
  }
`;
