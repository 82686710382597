import { Field, Form, Formik } from "formik";
import React, { useEffect, useRef, useState, useContext } from "react";
import {
  Button,
  CustomLabel,
  Details,
  FieldError,
  FlexBox,
  Input,
  List,
  RadioButton,
  RadioLabel,
  RadioText,
  RegistrationFormWrapper,
  SectionTitle,
  Separator,
  Steps,
  StyledCheckbox,
  StyledLink,
  StyledLinkSmallText,
  SubmitButton,
  Title,
  TooltipContainer,
  TooltipContent,
  Validations,
  ValidationsTitle,
  Wrapper,
} from "./Styled";
import GoogleLoginButton from "../../Common/Oauth/GoogleLogin";
import { Auth } from "aws-amplify";
import OtpField from "./OtpField";
import {
  PasswordValidation,
  Step1Schema,
  Step2Schema,
  Step3Schema,
  hasLowerCaseAndUpperCase,
} from "../../../Constants/Validations";
import CustomSelectField from "../../Common/Fields/CustomSelectField";
import Payment from "../../PaymentForm/PaymentForm";
import isEqual from "lodash.isequal";
import { v4 as uuidv4 } from "uuid";
import ticket from "../../../Assets/Images/ticket.svg";
import tooltip from "../../../Assets/Images/tooltip.svg";

import {
  billing_customer_create,
  setup_intent_create,
} from "../../Common/Billing";
import { AuthContext } from "../../../AppContext";
import { notification } from "antd";
import TagManager from "react-gtm-module";
import {
  countryOptions,
  countryPrefixes,
  roleOptions,
  taxIdTypes,
} from "../../../Constants/SelectOptions";
import { StepsTitle, initialValues } from "./Constants";
const TAG_MANAGER_ID = process.env.REACT_APP_TAG_MANAGER_ID;

const RegistrationForm = () => {
  const formikRef = useRef();
  const {
    step,
    setStep,
    setupIntent,
    setSetupIntent,
    setUpdatedUser,
    userEmail,
    oauthState,
  } = useContext(AuthContext);
  const [passwordInput, setPasswordInput] = useState("");
  const [containUpperCase, setContainUppercase] = useState(false);
  const [containLetterNumber, setContainLetterNumber] = useState(false);
  const [containCharacter, setContainCharacter] = useState(false);
  const [passwordLength, setPasswordLength] = useState(false);
  const [privacyCheck, setPrivacyCheck] = useState(false);
  const [offersCheck, setOffersCheck] = useState(false);

  const [isProcessing, setIsProcessing] = useState(false);
  const [loading, setIsLoading] = useState(false);
  const [check, setShowCheck] = useState(false);
  // We need to track the submitted values of the form to be able to
  // update the idempotency key when the user submits the form again
  const [prospect, setProspect] = useState(null);
  const [idempotencyKey, setIdempotencyKey] = useState(null);

  const onChange = (e) => {
    e.persist();
    setPasswordInput(e.target.value);
  };

  useEffect(() => {
    setContainUppercase(hasLowerCaseAndUpperCase(passwordInput));
    setContainLetterNumber(
      Boolean(passwordInput.match(/[a-zA-Z]/) && passwordInput.match(/\d/))
    );
    setContainCharacter(
      // eslint-disable-next-line no-useless-escape
      Boolean(passwordInput.match(/[!@#$%^.&*()_+\-=\[\]{};':"\\|,.<>\/?]/))
    );
    setPasswordLength(passwordInput.length >= 12);
  }, [passwordInput]);

  async function signUp(values, { setErrors }) {
    if (isProcessing) {
      return false;
    }
    setIsProcessing(true);
    try {
      await Auth.signUp({
        username: values.email,
        password: values.password,
        autoSignIn: {
          enabled: true,
        },
      });
      setIsProcessing(false);
      setStep(step + 1);
    } catch (error) {
      let message = error.message;
      setIsProcessing(false);
      if (error.code === "UsernameExistsException") {
        message =
          "An account with the given email already exists, Please Signin to continue.";
      }
      setErrors({ email: message });
      console.log("error signing up:", error.message);
    }
  }

  async function resendVerificationCode(
    values,
    { setFieldValue, setFieldError }
  ) {
    try {
      setFieldValue("verificationCode", "");
      setFieldError("verificationCode", "");
      await Auth.resendSignUp(values.email);
      setShowCheck(true);
    } catch (error) {
      setIsProcessing(false);
    }
  }

  const verify = async (values, { setErrors }) => {
    Auth.confirmSignUp(values.email, values.verificationCode)
      .then(() => {
        setStep(step + 1);
      })
      .catch((error) => {
        // Handle error
        setIsProcessing(false);
        setErrors({ verificationCode: error.message });
      });
  };

  const submitForm = async (values, { setErrors, setFieldError }) => {
    if (loading === true) {
      return false;
    }
    setIsLoading(true);
    const res = await Auth.currentSession();
    const accessToken = res.getAccessToken();
    const jwt = accessToken.getJwtToken();

    // Check if the user has already submitted the form,
    // if the request is a duplicate, we use the same idempotency key
    const isDuplicate = prospect && isEqual(prospect, values);
    const localIdempotencyKey = isDuplicate ? idempotencyKey : uuidv4();
    if (!isDuplicate) {
      setProspect(values);
      setIdempotencyKey(localIdempotencyKey);
    }

    try {
      const billing_customer = await billing_customer_create(
        jwt,
        {
          first_name: values.name,
          last_name: values.surname,
          phone: values.prefix + "-" + values.phoneNumber,
          business_name: values.companyName,
          role: values.role,
          country: values.country,
          city: values.city,
          line1: values.address,
          line2: values.address2,
          newsletter: values.offers,
          pec: values.pec,
          postal_code: values.zipCode,
          tax_id: values.taxId,
          type: values.businessType,
          state: values.state,
          vat_id: values.vat,
          sdi: values.sdiCode,
          vat_type: values.taxType,
        },
        localIdempotencyKey
      );
      if (billing_customer.status === "failed") {
        if (billing_customer.error.message.includes("VAT")) {
          formikRef.current.setFieldError("vat", "Invalid vat number");
        } else {
          notification.error({ message: billing_customer.error.message });
        }
      }

      const setup_intent = await setup_intent_create(
        jwt,
        {
          billing_customer_id: billing_customer.data.billing_customer_id,
        },
        localIdempotencyKey
      );

      setUpdatedUser(
        await Auth.currentAuthenticatedUser({ bypassCache: true })
      );
      setSetupIntent(setup_intent);
      setStep(step + 1);
    } catch (error) {
      notification.error({ message: "Something went wrong" });
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = (values, { setSubmitting, setErrors }) => {
    switch (step) {
      case 1:
        return signUp(values, { setErrors }).then(sendGTMEvent);
      case 2:
        return verify(values, { setErrors }).then(sendGTMEvent);
      case 3:
        return submitForm(values, { setErrors }).then(sendGTMEvent);
      default:
        return setTimeout(() => {
          alert(JSON.stringify(values, null, 2));
          setSubmitting(false);
        }, 400);
    }
  };
  const prevStep = ({ setFieldValue }) => {
    //clean the field values of step 1
    setFieldValue("verificationCode", "");
    setShowCheck(false);
    setStep(step - 1);
  };

  useEffect(() => {
    // FIXME: temporary solution
    if (window.location.pathname === "/google/auth-success") {
      Auth.currentSession().then((res) => {
        const payload = res.getIdToken().payload;
        const cognitoUserName = payload["cognito:username"];
        const email = payload["email"];
        if (cognitoUserName.startsWith("Google_")) {
          if (payload && !payload.name) {
            setStep(3);
          }
          // else if (payload && payload.name && !hasUpdatedPayment) {
          //   setStep(4);
          // }
          formikRef.current.setFieldValue("email", email);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (step === 3 && oauthState === "register") {
      formikRef.current.setFieldValue("email", userEmail);
    }
  }, [step, userEmail, oauthState]);

  const sendGTMEvent = () => {
    console.log("logged event");
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "sign_up",
      step: step,
      eventLabel: StepsTitle[step - 1].title,
    });
  };

  return (
    <>
      <RegistrationFormWrapper>
        {/** During loading we must keep the form mounted, otherwise we lose the state. */}
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          validateOnBlur={false}
          validationSchema={() => {
            switch (step) {
              case 1:
                return Step1Schema;
              case 2:
                return Step2Schema;
              case 3:
                return Step3Schema;
            }
          }}
          onSubmit={handleSubmit}
        >
          {({ errors, values, touched, setFieldValue, setFieldError }) => (
            <div>
              {step === 1 && (
                <Form>
                  <FlexBox>
                    <Title>{StepsTitle[step - 1].title} </Title>
                    <Details>
                      Already have an account?{" "}
                      <u>
                        <strong>
                          <StyledLink to="/login">Sign in</StyledLink>
                        </strong>
                      </u>
                    </Details>
                    <GoogleLoginButton
                      state={"register"}
                      text={"Sign up with Google"}
                    />
                    <Separator>Or sign up using your email</Separator>
                    <CustomLabel htmlFor="email">Email</CustomLabel>
                    <Input
                      placeholder="Email"
                      type="text"
                      name="email"
                      error={errors.email && touched.email}
                      autoComplete="email"
                    />
                    <FieldError
                      name="email"
                      className="FieldError"
                      component="div"
                    />
                    <CustomLabel htmlFor="password">Password</CustomLabel>
                    <Input
                      placeholder="Password"
                      type="password"
                      name="password"
                      onInput={onChange}
                      error={errors.password && touched.password}
                      autoComplete="new-password"
                    />
                    <FieldError name="password" component="div" />
                    {passwordInput !== "" && (
                      <div>
                        <ValidationsTitle>
                          {" "}
                          Password should be composed of:
                        </ValidationsTitle>
                        <List>
                          <Validations condition={passwordLength}>
                            At least 12 characters
                          </Validations>
                          <Validations condition={containUpperCase}>
                            Both uppercase and lowercase letters
                          </Validations>
                          <Validations condition={containLetterNumber}>
                            Both letters and numbers
                          </Validations>
                          <Validations condition={containCharacter}>
                            {" "}
                            {`At least one special character (e.g. ! @ # ? . ] )`}
                          </Validations>
                        </List>
                      </div>
                    )}

                    <CustomLabel htmlFor="confirmPassword">
                      Confirm Password
                    </CustomLabel>
                    <Input
                      placeholder="Confirm password"
                      type="password"
                      name="confirmPassword"
                      error={errors.confirmPassword && touched.confirmPassword}
                      autoComplete="new-password"
                    />
                    <FieldError name="confirmPassword" component="div" />
                    <StyledCheckbox
                      name="privacy"
                      onChange={(e) => {
                        setFieldValue("privacy", e.target.checked);
                        setPrivacyCheck(e.target.checked);
                      }}
                      checked={privacyCheck}
                      error={errors.privacy && touched.privacy}
                    >
                      I agree to botshelf.ai
                      <StyledLinkSmallText
                        to="https://botshelf.ai/terms-of-service/"
                        target="_blank"
                      >
                        Terms of Service
                      </StyledLinkSmallText>
                      , and I have read and acknowledge the
                      <StyledLinkSmallText
                        to="https://botshelf.ai/privacy-policy/"
                        target="_blank"
                      >
                        {" "}
                        Privacy Policy{" "}
                      </StyledLinkSmallText>
                      {privacyCheck === false &&
                      errors.privacy &&
                      touched.privacy ? (
                        <FieldError name="privacy" component="div" />
                      ) : null}
                    </StyledCheckbox>
                    {/*valid={!isValid || !dirty || isSubmitting}*/}
                    <Button type="submit" disabled={isProcessing}>
                      <span id="button-text">
                        {isProcessing ? "Processing ... " : "Start for free"}
                      </span>
                    </Button>
                  </FlexBox>
                </Form>
              )}

              {step === 2 && (
                <Form>
                  <FlexBox>
                    <Title>{StepsTitle[step - 1].title}</Title>
                    <Details>
                      We sent a verification code to the address <br />{" "}
                      <strong>{values.email}</strong>
                    </Details>
                    <Details
                      style={{ cursor: "pointer" }}
                      onClick={() => prevStep({ setFieldValue })}
                    >
                      <u>
                        <strong>Wrong Address?</strong>
                      </u>
                    </Details>
                    <Details>Please insert your code here</Details>
                    <OtpField
                      name="verificationCode"
                      error={
                        errors.verificationCode && touched.verificationCode
                      }
                    />
                    <span style={{ height: "16px" }} />
                    <FieldError name="verificationCode" component="div" />
                    <SubmitButton type="submit">Continue</SubmitButton>
                    <Details>
                      {" "}
                      Please note: emails could take up to 10 minutes before
                      being delivered to your inbox.
                    </Details>
                    <Details>
                      {" "}
                      Still not there? Check your spam, or{" "}
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          resendVerificationCode(values, {
                            setFieldValue,
                            setFieldError,
                          })
                        }
                      >
                        <u>
                          <strong>Send again</strong>
                        </u>
                      </span>
                    </Details>
                    {check && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-evenly",
                        }}
                      >
                        <img
                          src={ticket}
                          alt=""
                          style={{ marginRight: "10px" }}
                        />
                        <Details>
                          A new verification code has been sent to your email
                        </Details>
                      </div>
                    )}
                  </FlexBox>
                </Form>
              )}

              {step === 3 && (
                <Form>
                  <FlexBox>
                    <Steps>{step - 2} OF 2</Steps>
                    <Title>{StepsTitle[step - 1].title}</Title>
                    <Details>
                      Please fill in the fields below with the required
                      information.
                    </Details>
                    <SectionTitle>Contact info</SectionTitle>

                    <CustomLabel htmlFor="email">Email</CustomLabel>
                    <Input type="email" name="email" disabled />
                    <FieldError name="email" component="div" />

                    <CustomLabel htmlFor="name">Name*</CustomLabel>
                    <Input
                      placeholder="Your name"
                      type="text"
                      name="name"
                      error={errors.name && touched.name}
                      autoComplete="given-name"
                    />
                    <FieldError name="name" component="div" />

                    <CustomLabel htmlFor="surname">Surname*</CustomLabel>
                    <Input
                      placeholder="Your surname"
                      type="text"
                      name="surname"
                      error={errors.surname && touched.surname}
                      autoComplete="family-name"
                    />
                    <FieldError name="surname" component="div" />
                    <CustomLabel htmlFor="phoneNumber">
                      Phone number*
                    </CustomLabel>
                    <Wrapper style={{ alignItems: "baseline" }}>
                      <CustomSelectField
                        options={countryPrefixes}
                        name="prefix"
                        error={errors.prefix && touched.prefix}
                        // autoComplete="tel-country-code"
                      />
                      <div>
                        <Input
                          placeholder="Phone number"
                          style={{ width: "280px" }}
                          type="tel"
                          name="phoneNumber"
                          error={errors.phoneNumber && touched.phoneNumber}
                          autoComplete="tel-national"
                        />
                        <FieldError name="phoneNumber" component="div" />
                      </div>
                    </Wrapper>
                    <CustomLabel htmlFor="role">Role</CustomLabel>
                    <CustomSelectField
                      name="role"
                      options={roleOptions}
                      space="full"
                      autoComplete="organization-title"
                    />
                    <FieldError name="role" component="div" />
                    <SectionTitle>Billing info</SectionTitle>

                    <CustomLabel>
                      Who are you subscribing to botshelf.ai for?{" "}
                      <TooltipContainer>
                        <img src={tooltip} />
                        <TooltipContent className="TooltipContent">
                          {
                            'This setting will help us collect the correct billing information for your account. Select "Your company" if you\'re subscribing to botshelf.ai on behalf of a Business or Organization. Select "Yourself" if you\'re subscribing to botshelf.ai as an individual.'
                          }
                        </TooltipContent>
                      </TooltipContainer>
                    </CustomLabel>
                    <RadioLabel>
                      <Field
                        type="radio"
                        name="businessType"
                        value="BUSINESS"
                        checked={values.businessType === "BUSINESS"}
                        as={RadioButton}
                      />
                      <RadioText>A company - For business reasons</RadioText>
                    </RadioLabel>
                    <RadioLabel>
                      <Field
                        type="radio"
                        name="businessType"
                        value="PRIVATE"
                        checked={values.businessType === "PRIVATE"}
                        as={RadioButton}
                      />
                      <RadioText>
                        Yourself - For your own personal projects"
                      </RadioText>
                    </RadioLabel>
                    <FieldError name="businessType" component="div" />
                    <div style={{ height: "16px" }} />
                    <CustomLabel htmlFor="country">Country*</CustomLabel>
                    <CustomSelectField
                      name="country"
                      options={countryOptions}
                      space="full"
                      error={errors.country && touched.country}
                      autoComplete="country"
                    />
                    <FieldError name="country" component="div" />

                    {values.businessType !== "PRIVATE" && (
                      <>
                        <CustomLabel htmlFor="companyName">
                          Company Name*
                        </CustomLabel>
                        <Input
                          placeholder="Company name"
                          type="text"
                          name="companyName"
                          error={errors.companyName && touched.companyName}
                          className="organization-name"
                        />
                        <FieldError name="companyName" component="div" />
                      </>
                    )}

                    {values.businessType === "BUSINESS" && (
                      <>
                        <CustomLabel htmlFor="taxType">VAT type*</CustomLabel>
                        <CustomSelectField
                          name="taxType"
                          options={taxIdTypes}
                          space="full"
                          error={errors.taxType && touched.taxType}
                        />
                        <FieldError name="taxType" component="div" />

                        <CustomLabel htmlFor="vat">VAT number*</CustomLabel>
                        <Input
                          placeholder="Vat number"
                          type="text"
                          name="vat"
                          error={errors.vat && touched.vat}
                        />
                        <FieldError name="vat" component="div" />
                      </>
                    )}

                    {values.businessType === "PRIVATE" && (
                      <>
                        <CustomLabel htmlFor="taxId">TAX ID*</CustomLabel>
                        <Input
                          placeholder="TAX ID"
                          type="text"
                          name="taxId"
                          error={errors.taxId && touched.taxId}
                        />
                        <FieldError name="taxId" component="div" />
                      </>
                    )}

                    <CustomLabel htmlFor="city">City*</CustomLabel>
                    <Input
                      placeholder="city"
                      type="text"
                      name="city"
                      error={errors.city && touched.city}
                      autoComplete="address-level2"
                    />
                    <FieldError name="city" component="div" />
                    <CustomLabel htmlFor="state">State/Region*</CustomLabel>
                    <Input
                      placeholder="State/Region"
                      type="text"
                      name="state"
                      autoComplete="address-level1"
                    />
                    <FieldError name="state" component="div" />
                    <CustomLabel htmlFor="address">Address*</CustomLabel>
                    <Input
                      placeholder="Address"
                      type="text"
                      name="address"
                      error={errors.address && touched.address}
                      autoComplete="address-line3"
                    />
                    <FieldError name="address" component="div" />
                    <CustomLabel htmlFor="address2">Address line 2</CustomLabel>
                    <Input
                      placeholder="Address"
                      type="text"
                      name="address2"
                      autoComplete="address-level4"
                    />
                    <FieldError name="address2" component="div" />
                    <CustomLabel htmlFor="zipCode">ZIP code*</CustomLabel>
                    <Input
                      placeholder="ZIP code"
                      type="text"
                      name="zipCode"
                      error={errors.zipCode && touched.zipCode}
                      autoComplete="postal-code"
                    />
                    <FieldError name="zipCode" component="div" />

                    {values.country === "IT" &&
                      values.businessType !== "PRIVATE" && (
                        <>
                          <CustomLabel htmlFor="sdiCode">SDI code*</CustomLabel>
                          <Input
                            placeholder="SDI code"
                            type="text"
                            name="sdiCode"
                            error={errors.sdiCode && touched.sdiCode}
                          />
                          <FieldError name="sdiCode" component="div" />
                          <CustomLabel htmlFor="pec">PEC address</CustomLabel>
                          <Input
                            placeholder="Pec address"
                            type="email"
                            name="pec"
                          />
                          <FieldError name="pec" component="div" />
                        </>
                      )}
                    <StyledCheckbox
                      name="offers"
                      onChange={(e) => {
                        setFieldValue("offers", e.target.checked);
                        setOffersCheck(e.target.checked);
                      }}
                      checked={offersCheck}
                    >
                      I want to receive custom offers and find out about
                      botshelf.ai latest updates by email.
                    </StyledCheckbox>
                    <Button disabled={isProcessing} type="submit">
                      <span id="button-text">
                        {loading ? "Processing ... " : "Continue"}
                      </span>
                    </Button>
                  </FlexBox>
                </Form>
              )}

              {step === 4 && (
                <FlexBox>
                  <Steps>{step - 2} OF 2</Steps>
                  <Title>{StepsTitle[step - 1].title}</Title>
                  <Details style={{ marginBottom: "40px" }}>
                    You will be billed only after your free trial has ended,
                    month-end, on a pay-per-use fee model.
                  </Details>
                  <Payment intent={setupIntent} />
                </FlexBox>
              )}
            </div>
          )}
        </Formik>
      </RegistrationFormWrapper>
    </>
  );
};
export default RegistrationForm;
