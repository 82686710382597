import styled from "styled-components";

export const DashboardIcon = styled.svg.attrs({
  role: "presentation",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 20 20",
  children: (
    <path d="M18.237 20h-5.759a1.766 1.766 0 0 1-1.763-1.764V11.05c0-.972.791-1.763 1.763-1.763h5.759c.972 0 1.764.791 1.764 1.763v7.187C20 19.21 19.209 20 18.237 20Zm-5.759-9.286a.335.335 0 0 0-.335.335v7.187c0 .185.15.335.335.335h5.759c.185 0 .335-.15.335-.335V11.05a.335.335 0 0 0-.335-.335h-5.759ZM18.237 7.857h-5.759a1.766 1.766 0 0 1-1.763-1.763v-4.33C10.715.79 11.506 0 12.478 0h5.759c.972 0 1.764.791 1.764 1.764v4.33c0 .972-.792 1.763-1.764 1.763ZM12.478 1.43a.335.335 0 0 0-.335.335v4.33c0 .185.15.335.335.335h5.759c.185 0 .335-.15.335-.335v-4.33a.335.335 0 0 0-.335-.335h-5.759ZM7.522 10.714H1.764A1.766 1.766 0 0 1 0 8.951V1.764C0 .79.791 0 1.764 0h5.758c.972 0 1.764.791 1.764 1.764V8.95c0 .972-.792 1.763-1.764 1.763ZM1.764 1.43a.335.335 0 0 0-.335.335V8.95c0 .185.15.335.335.335h5.758c.185 0 .335-.15.335-.335V1.764a.335.335 0 0 0-.335-.335H1.764ZM7.522 20H1.764A1.766 1.766 0 0 1 0 18.237v-4.33c0-.973.791-1.764 1.764-1.764h5.758c.972 0 1.764.791 1.764 1.764v4.33c0 .972-.792 1.763-1.764 1.763Zm-5.758-6.428a.335.335 0 0 0-.335.335v4.33c0 .185.15.335.335.335h5.758c.185 0 .335-.15.335-.335v-4.33a.335.335 0 0 0-.335-.335H1.764Z" />
  ),
})`
  width: 20px;
  path {
    fill: currentColor;
  }
`;
