import * as Yup from "yup";
export const PasswordValidation =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+~`=[{}\]:;"'\\<,>.?/\|])[a-zA-Z\d!@#$%^&*=()_+~`[{}\]\\:;"'<,>.?/\|]{12,}$/;
const fieldErrorMessage = "This field is mandatory";
export const hasLowerCaseAndUpperCase = (str) => {
  return /[a-z]/.test(str) && /[A-Z]/.test(str);
};
export const Step1Schema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required(fieldErrorMessage),
  password: Yup.string()
    .matches(PasswordValidation, fieldErrorMessage)
    .required(fieldErrorMessage),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required(fieldErrorMessage),
  privacy: Yup.boolean()
    .oneOf([true], "Please accept our terms and conditions")
    .required("Please accept our terms and conditions"),
});

export const Step2Schema = Yup.object().shape({
  verificationCode: Yup.string().required(fieldErrorMessage),
});
export const Step3Schema = Yup.object().shape(
  {
    name: Yup.string().required(fieldErrorMessage),
    surname: Yup.string().required(fieldErrorMessage),
    phoneNumber: Yup.string().required(fieldErrorMessage),
    businessType: Yup.string().required(fieldErrorMessage),
    prefix: Yup.string().required(fieldErrorMessage),
    country: Yup.string().required(fieldErrorMessage),
    // This is the input field.
    vat: Yup.string().when(["businessType", "country"], {
      is: (businessType) => businessType === "BUSINESS",
      then: () => Yup.string().required(fieldErrorMessage),
    }),
    taxType: Yup.string().when(["businessType", "country"], {
      is: (businessType, country) => businessType === "BUSINESS",
      then: () => Yup.string().required(fieldErrorMessage),
    }),
    companyName: Yup.string().when("businessType", {
      is: (businessType) => businessType !== "PRIVATE",
      then: () => Yup.string().required(fieldErrorMessage),
    }),
    sdiCode: Yup.string().when(["businessType", "country", "pec"], {
      is: (businessType, country, pec) =>
        businessType !== "PRIVATE" && country === "IT" && !pec,
      then: () => Yup.string().required(fieldErrorMessage),
    }),
    pec: Yup.string().when(["businessType", "country", "sdiCode"], {
      is: (businessType, country, sdiCode) =>
        businessType !== "PRIVATE" && country === "IT" && !sdiCode,
      then: () => Yup.string().required(fieldErrorMessage),
    }),
    city: Yup.string().required(fieldErrorMessage),
    address: Yup.string().required(fieldErrorMessage),
    zipCode: Yup.string().required(fieldErrorMessage),
  },
  ["sdiCode", "pec"]
);
