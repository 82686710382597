import React, { useMemo } from "react";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm";

const stripeKey = process.env.REACT_APP_STRIPE_KEY;

function Payment({ intent }) {
  const stripePromise = useMemo(() => loadStripe(stripeKey), []);
  const clientSecret = intent.data.client_secret;

  if (clientSecret && stripePromise) {
    return (
      <Elements stripe={stripePromise} options={{ clientSecret }}>
        <CheckoutForm />
      </Elements>
    );
  } else {
    // FIXME: what should happen here? throw an error?
    console.error(
      "Payment component cannot be rendered because either the client secret or the stripe promise is null."
    );
    return <div>Something went wrong</div>;
  }
}

export default Payment;
