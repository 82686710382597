import React from "react";
import styled from "styled-components";

export const StyledDocsLink = styled.a`
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;

  a {
    font-weight: 700;
    text-decoration: underline;
    color: inherit;
  }
`;

<svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 23">
  <path
    d="M11.674 1.002a10.288 10.288 0 0 0-7.22 3.106 10.295 10.295 0 0 0-2.975 7.274 10.4 10.4 0 0 0 1.072 4.576l.05.103-1.53 4.452v.001c-.16.468-.044.964.305 1.314a1.262 1.262 0 0 0 1.315.305l4.453-1.53.103.05c1.413.7 2.99 1.07 4.576 1.072h.017c2.728 0 5.3-1.054 7.256-2.975a10.287 10.287 0 0 0 3.105-7.218 10.302 10.302 0 0 0-3.034-7.496 10.313 10.313 0 0 0-7.496-3.035l.003.001Zm.012.743a9.551 9.551 0 0 1 6.957 2.817 9.555 9.555 0 0 1 2.817 6.957c-.082 5.226-4.407 9.473-9.634 9.465a9.633 9.633 0 0 1-4.477-1.112.364.364 0 0 0-.294-.024L2.45 21.431a.495.495 0 0 1-.414-.047h-.056l-.08-.079v-.001a.553.553 0 0 1-.127-.551l1.582-4.604a.367.367 0 0 0-.023-.294 9.638 9.638 0 0 1-1.111-4.476C2.214 6.15 6.46 1.828 11.686 1.745Z"
    fill="#000"
    stroke="#000"
    stroke-width=".4"
  />
  <path
    d="M11.524 14.677c-.553 0-1 .46-1 1.013 0 .54.434 1.014 1 1.014.566 0 1.013-.474 1.013-1.014 0-.553-.46-1.013-1.013-1.013ZM11.695 7.003c-1.777 0-2.593 1.053-2.593 1.764 0 .513.434.75.79.75.71 0 .42-1.013 1.763-1.013.658 0 1.185.29 1.185.895 0 .71-.737 1.119-1.172 1.487-.381.329-.882.869-.882 2 0 .685.185.883.724.883.645 0 .777-.29.777-.54 0-.684.013-1.08.737-1.645.356-.277 1.474-1.172 1.474-2.41 0-1.236-1.118-2.171-2.803-2.171Z"
    fill="#000"
  />
</svg>;

export const StyledQuestionIcon = styled.svg.attrs((props) => ({
  ...props,
  "aria-hidden": true,
  viewBox: "0 0 23 23",
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  children: (
    <>
      <path d="M11.674 1.002a10.288 10.288 0 0 0-7.22 3.106 10.295 10.295 0 0 0-2.975 7.274 10.4 10.4 0 0 0 1.072 4.576l.05.103-1.53 4.452v.001c-.16.468-.044.964.305 1.314a1.262 1.262 0 0 0 1.315.305l4.453-1.53.103.05c1.413.7 2.99 1.07 4.576 1.072h.017c2.728 0 5.3-1.054 7.256-2.975a10.287 10.287 0 0 0 3.105-7.218 10.302 10.302 0 0 0-3.034-7.496 10.313 10.313 0 0 0-7.496-3.035l.003.001Zm.012.743a9.551 9.551 0 0 1 6.957 2.817 9.555 9.555 0 0 1 2.817 6.957c-.082 5.226-4.407 9.473-9.634 9.465a9.633 9.633 0 0 1-4.477-1.112.364.364 0 0 0-.294-.024L2.45 21.431a.495.495 0 0 1-.414-.047h-.056l-.08-.079v-.001a.553.553 0 0 1-.127-.551l1.582-4.604a.367.367 0 0 0-.023-.294 9.638 9.638 0 0 1-1.111-4.476C2.214 6.15 6.46 1.828 11.686 1.745Z" />
      <path d="M11.524 14.677c-.553 0-1 .46-1 1.013 0 .54.434 1.014 1 1.014.566 0 1.013-.474 1.013-1.014 0-.553-.46-1.013-1.013-1.013ZM11.695 7.003c-1.777 0-2.593 1.053-2.593 1.764 0 .513.434.75.79.75.71 0 .42-1.013 1.763-1.013.658 0 1.185.29 1.185.895 0 .71-.737 1.119-1.172 1.487-.381.329-.882.869-.882 2 0 .685.185.883.724.883.645 0 .777-.29.777-.54 0-.684.013-1.08.737-1.645.356-.277 1.474-1.172 1.474-2.41 0-1.236-1.118-2.171-2.803-2.171Z" />
    </>
  ),
}))`
  width: 20px;
  margin-right: 5px;
  position: relative;
  top: 4px;

  path {
    fill: ${(props) => props.theme.colors.black};
  }

  path:nth-child(1) {
    stroke: ${(props) => props.theme.colors.black};
    stroke-width: 0.4px;
  }
`;
