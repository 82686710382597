import React, { ReactNode } from "react";
import styled from "styled-components";

export type PaymentCardProps = {
  number: string | ReactNode;
  owner: string;
  expiration: string;
  logo?: {
    src: string;
    alt: string;
  };
};

export default function PaymentCard(props: PaymentCardProps) {
  return (
    <Container>
      {props.logo && <CardLogo src={props.logo.src} alt={props.logo.alt} />}
      <div>
        <CardNumber>{props.number}</CardNumber>
        <CardOwner>{props.owner}</CardOwner>
        <CardExpiration>{props.expiration}</CardExpiration>
      </div>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  gap: 12px;
  width: 341px;
  min-height: 176px;
  background: #ffffff;
  padding: 24px;
  margin-top: 32px;
  border-radius: 20px;
`;

const CardLogo = styled.img`
  width: 45px;
  height: 32px;
  object-fit: contain;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 2.5px;
`;

const CardNumber = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 116.4%;
  display: block;
  height: 32px;
  display: flex;
  align-items: center;

  font-weight: 700;
  font-size: 16px;
  line-height: 116.4%;
`;

const CardOwner = styled.span`
  font-weight: 500;
  font-size: 16px;
  line-height: 116.4%;
  display: block;
`;

const CardExpiration = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 116.4%;
  margin-top: 16px;
  color: rgba(0, 0, 0, 0.5);
`;
