import styled from "styled-components";
import { useState, useCallback } from "react";
import CreateKeyFunnel from "./CreateKeyFunnel";
import { MAX_NUMBER_OF_KEYS } from "./utils/common";
import React from "react";
import { useGetAllKeysQuery } from "./utils/getAllKeys";

export default function EmptyOverlay() {
  const [showFunnel, setShowFunnel] = useState(false);
  const { data = [] } = useGetAllKeysQuery();
  const numberOfKeysLimitReached = data.length >= MAX_NUMBER_OF_KEYS;

  // Memoize the functions passed to the funnel
  const startFunnel = useCallback(() => setShowFunnel(true), []);
  const stopFunnel = useCallback(() => setShowFunnel(false), []);

  return (
    <>
      <Container>
        <div>
          <Illustration />
          <Message>
            Create your first API key and start enjoying all the benefits of
            botshelf.ai
          </Message>
          <CreateKeyButton
            onClick={startFunnel}
            disabled={numberOfKeysLimitReached}
          />
        </div>
      </Container>
      {showFunnel && <CreateKeyFunnel onComplete={stopFunnel} />}
    </>
  );
}

const CreateKeyButton = styled.button.attrs(() => ({
  "aria-label": "Create API key",
  children: (
    <>
      <svg
        role="presentation"
        width="21"
        height="20"
        viewBox="0 0 21 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.5 0C4.97301 0 0.5 4.4725 0.5 10C0.5 15.5269 4.9725 20 10.5 20C16.027 20 20.5 15.5275 20.5 10C20.5 4.47301 16.0275 0 10.5 0ZM10.5 18.6046C5.75539 18.6046 1.89535 14.7446 1.89535 10C1.89535 5.25535 5.75539 1.39535 10.5 1.39535C15.2446 1.39535 19.1046 5.25535 19.1046 10C19.1046 14.7446 15.2446 18.6046 10.5 18.6046Z"
          fill="currentColor"
        />
        <path
          d="M14.875 10.625H6.125C5.78 10.625 5.5 10.345 5.5 10C5.5 9.655 5.78 9.375 6.125 9.375H14.875C15.22 9.375 15.5 9.655 15.5 10C15.5 10.345 15.22 10.625 14.875 10.625Z"
          fill="currentColor"
        />
        <path
          d="M10.5 15C10.155 15 9.875 14.72 9.875 14.375V5.625C9.875 5.28 10.155 5 10.5 5C10.845 5 11.125 5.28 11.125 5.625V14.375C11.125 14.72 10.845 15 10.5 15Z"
          fill="currentColor"
        />
      </svg>
      <span>Create API key</span>
    </>
  ),
}))`
  appearance: none;
  border: none;
  background: none;
  padding: 0;
  display: flex;
  gap: 8px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 116.4%;
  text-decoration-line: underline;
  cursor: pointer;
`;

const Illustration = styled.svg.attrs({
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 76 81",
  children: (
    <>
      <path d="m24.363 13.727 5-8.182 2.728-2.272 8.181-1.364 8.182 5.454 3.182 8.182-2.273 7.728-5.454 6.818-12.273-.455-1.818-2.273-5.455-7.272v-6.364Z" />
      <path d="M69.172 41.781v-.009l-.081-.142-.008-.01a1.232 1.232 0 0 0-.875-.635l-13.75-2.293a1.25 1.25 0 0 0-.41 2.466l6.375 1.063-22.422 3.761s-16.195-2.701-22.464-3.75l6.419-1.07a1.25 1.25 0 1 0-.41-2.466l-13.75 2.293a1.235 1.235 0 0 0-.875.633l-.01.011-5 8.75a1.25 1.25 0 0 0 .84 1.84l30 6.25a1.25 1.25 0 0 0 1.369-.665l2.63-5.263v25.73l-26.456-4.41a1.25 1.25 0 0 1-1.043-1.233V57.25a1.25 1.25 0 1 0-2.5 0v15.382a3.739 3.739 0 0 0 3.133 3.699l27.912 4.651c.135.024.274.024.41 0l27.91-4.651a3.739 3.739 0 0 0 3.135-3.699V57.25a1.25 1.25 0 1 0-2.5 0v15.382a1.25 1.25 0 0 1-1.044 1.233l-26.456 4.41v-25.73l2.625 5.264a1.25 1.25 0 0 0 1.375.665l30-6.25a1.251 1.251 0 0 0 .83-1.844l-4.91-8.599ZM32.313 55.83 4.94 50.125l3.714-6.5 27.473 4.58-3.813 7.625Zm11.375 0-3.812-7.625 27.472-4.58 3.714 6.5-27.374 5.705Z" />
      <path d="M14.25 22.25V26a1.25 1.25 0 0 0 2.5 0v-.733l3.258 3.259A18.625 18.625 0 0 1 25.5 41.783v.467a1.25 1.25 0 0 0 2.5 0v-.467a21.115 21.115 0 0 0-6.224-15.026L18.517 23.5h.733a1.25 1.25 0 1 0 0-2.5H15.5a1.25 1.25 0 0 0-1.25 1.25ZM48 41.783v.467a1.25 1.25 0 0 0 2.5 0v-.467a18.625 18.625 0 0 1 5.493-13.257l3.257-3.259V26a1.25 1.25 0 0 0 2.5 0v-3.75A1.25 1.25 0 0 0 60.5 21h-3.75a1.25 1.25 0 0 0 0 2.5h.733l-3.26 3.257A21.116 21.116 0 0 0 48 41.783ZM61.75 11H63v1.25a1.25 1.25 0 0 0 2.5 0V11h1.25a1.25 1.25 0 1 0 0-2.5H65.5V7.25a1.25 1.25 0 0 0-2.5 0V8.5h-1.25a1.25 1.25 0 0 0 0 2.5ZM74.25 31H73v-1.25a1.25 1.25 0 1 0-2.5 0V31h-1.25a1.25 1.25 0 0 0 0 2.5h1.25v1.25a1.25 1.25 0 0 0 2.5 0V33.5h1.25a1.25 1.25 0 1 0 0-2.5ZM9.25 13.5h1.25v1.25a1.25 1.25 0 0 0 2.5 0V13.5h1.25a1.25 1.25 0 1 0 0-2.5H13V9.75a1.25 1.25 0 0 0-2.5 0V11H9.25a1.25 1.25 0 0 0 0 2.5ZM3 29.75a1.25 1.25 0 0 0 2.5 0V28.5h1.25a1.25 1.25 0 1 0 0-2.5H5.5v-1.25a1.25 1.25 0 1 0-2.5 0V26H1.75a1.25 1.25 0 0 0 0 2.5H3v1.25ZM29.037 28.014c.354.269.656.6.89.98a3.72 3.72 0 0 0 .68 6.808A6.25 6.25 0 0 0 36.75 41h2.5a6.25 6.25 0 0 0 6.144-5.198 3.72 3.72 0 0 0 .668-6.815c.22-.361.507-.677.846-.93a15 15 0 0 0-10.63-26.961 14.99 14.99 0 0 0-7.241 26.918ZM39.25 38.5h-2.5a3.75 3.75 0 0 1-3.52-2.5h9.54a3.75 3.75 0 0 1-3.52 2.5Zm5-5h-12.5a1.25 1.25 0 0 1 0-2.5h12.5a1.25 1.25 0 0 1 0 2.5ZM38 21a1.25 1.25 0 1 1 0-2.5 1.25 1.25 0 0 1 0 2.5Zm-12.345-7A12.593 12.593 0 0 1 36.556 3.577a12.5 12.5 0 0 1 8.866 22.467A5.928 5.928 0 0 0 43.5 28.5h-4.25v-5.23a3.75 3.75 0 1 0-2.5 0v5.23h-4.265a6.132 6.132 0 0 0-1.953-2.489A12.549 12.549 0 0 1 25.655 14Z" />
    </>
  ),
})`
  width: 75px;
  path {
    fill: currentColor;
  }
  path:first-child {
    fill: ${({ theme }) => theme.colors.yellow};
  }
`;

const Container = styled.div`
  position: absolute;
  top: var(--row-height);
  bottom: 0;
  left: 0;
  right: 0;
  display: grid;
  place-content: center;
  z-index: 1;
  border-radius: var(--border-radius);
  background-color: ${({ theme }) => theme.colors.white};

  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 22px;
    max-width: 400px;
    text-align: center;
  }
`;

const Message = styled.p`
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  margin: 0;
`;
