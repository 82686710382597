const awsConfig = {
  Auth: {
    userPoolId: process.env.REACT_APP_AUTH_USER_ID,
    userPoolWebClientId: process.env.REACT_APP_AUTH_CLIENT_ID,
    oauth: {
      domain: process.env.REACT_APP_AUTH_OAUTH_DOMAIN,
      scope: [
        "email",
        "openid",
        "phone",
        "profile",
        "aws.cognito.signin.user.admin",
      ],
      redirectSignIn: process.env.REACT_APP_AUTH_GOOGLE_SIGNIN_REDIRECT_URL,
      redirectSignOut: process.env.REACT_APP_AUTH_GOOGLE_SIGNOUT_REDIRECT_URL,
      clientId: process.env.REACT_APP_AUTH_CLIENT_ID,
      responseType: "code", // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
  },
};

export default awsConfig;
