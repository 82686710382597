import styled from "styled-components";
import Header from "./Header";
import Sidebar from "./Sidebar";
import MainArea from "./MainArea";
import MainContainerProvider, { useMainContainerContext } from "./Provider";
import NotificationBar from "./NotificationBar";

function MainLayout() {
  const context = useMainContainerContext();
  return (
    <Container $ctx={context}>
      <NotificationBar />
      <Header />
      <Content>
        <Sidebar />
        <MainArea />
      </Content>
      <div id="dialog-root" />
    </Container>
  );
}

const Content = styled.div`
  display: flex;
  min-height: 100%;
`;

const Container = styled.div`
  height: 100%;
  --notification-bar-height: ${({ $ctx }) =>
    $ctx.isNotificationBarVisible ? "52px" : "0px"};
  --header-height: 60px;
  --content-gap: calc(var(--header-height) + var(--notification-bar-height));
`;

export default function MainLayoutWrapper() {
  return (
    <MainContainerProvider>
      <MainLayout />
    </MainContainerProvider>
  );
}
