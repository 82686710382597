import { useContext } from "react";
import { NotifyBannerContext } from "./Context";
import { StyledDescription } from "./Styled";
import React from "react";

export type NotifyBannerDescriptionProps = React.ComponentProps<
  typeof StyledDescription
>;

export function NotifyBannerDescription({
  children,
  ...props
}: NotifyBannerDescriptionProps) {
  const { description } = useContext(NotifyBannerContext);
  return (
    <StyledDescription {...props}>{children || description}</StyledDescription>
  );
}
