import { useCallback, useState } from "react";
import { HelpBanner } from "./PostFreeTrial/HelpBanner";
import { TopBanner } from "./PostFreeTrial/TopBanner";
import { BillingInfo } from "./PostFreeTrial/BillingInfo";
import React from "react";

export default function PostFreeTrial() {
  const [showTopBanner, setShowTopBanner] = useState(true);
  const hideTopBanner = useCallback(() => setShowTopBanner(false), []);
  return (
    <>
      {showTopBanner && <TopBanner onHide={hideTopBanner} />}
      <BillingInfo />
      <HelpBanner />
    </>
  );
}
