import useSWR, { Fetcher, SWRConfiguration } from "swr";
import { getAccessToken } from "../../APIKeys/utils/getAccessToken";
import { APIResponse } from "../../APIKeys/utils/types";

export type DailyUsage = {
  year: number;
  month: number;
  day: number;
  count: number;
}[];

export type DailyUsageResponse = APIResponse<DailyUsage>;

export async function getDailyUsage(limit: number) {
  const url = new URL(
    "/v1/metrics-system/daily-usage",
    process.env.REACT_APP_API_URL
  );
  url.searchParams.append("limit", limit.toString());
  const accessToken = await getAccessToken();
  const res = await fetch(url, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  const json = (await res.json()) as DailyUsageResponse;
  if (json.status === "success") {
    return json.data;
  } else {
    console.error(json.error);
    throw new Error("Failed to fetch daily usage");
  }
}

export const DAILY_USAGE = "DAILY_USAGE";

export type UseDailyUsageQueryKey = [typeof DAILY_USAGE, number];
export type UseDailyUsageData = Awaited<ReturnType<typeof getDailyUsage>>;
export type UseDailyUsageFetcher = Fetcher<
  UseDailyUsageData,
  UseDailyUsageQueryKey
>;
export type UseDailyUsageError = Error;

const fetcher: UseDailyUsageFetcher = (arg) => {
  const limit = arg[1];
  return getDailyUsage(limit);
};

/**
 * SWR configuration for the get all keys query
 */
export type UseGetAllKeysConfiguration = SWRConfiguration<
  UseDailyUsageData,
  UseDailyUsageError,
  UseDailyUsageFetcher
>;

export function useDailyUsage(
  limit: number = 31,
  options: UseGetAllKeysConfiguration = {}
) {
  return useSWR<UseDailyUsageData, UseDailyUsageError, UseDailyUsageQueryKey>(
    [DAILY_USAGE, limit],
    fetcher,
    options
  );
}
