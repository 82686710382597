import React from "react";
import styled from "styled-components";
import { useCustomerInfo } from "../Billing/useCustomerInfo";

export default function FreeCallsCounter() {
  const { customer } = useCustomerInfo();
  const total = customer?.free_requests || 1000;
  const count = customer?.free_requests_used || 0;
  return (
    <Container>
      <Heading>Free API calls consumed</Heading>
      <ProgressContainer>
        <ProgressBar
          aria-labelledby="free-calls-counter"
          value={Math.min(count, total)}
          max={total}
        />
        <ProgressFraction>
          {Math.min(count, total)}/{total}
        </ProgressFraction>
      </ProgressContainer>
    </Container>
  );
}

const Container = styled.div`
  margin: 24px 0;
`;

const Heading = styled.h4`
  margin: 0 0 8px;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
`;

const ProgressContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 25px;
`;

const ProgressFraction = styled.div.attrs((props) => ({
  "aria-hidden": true,
  ...props,
}))`
  font-weight: 400;
  font-size: 16px;
  line-height: 116.4%;
`;

const ProgressBar = styled.progress`
  -webkit-appearance: none;
  appearance: none;
  width: 230px;
  height: 24px;

  &::-webkit-progress-bar {
    border: 2px solid rgba(0, 0, 0, 0.5);
    background: transparent;
    border-radius: 35px;
    overflow: hidden;
  }

  &::-webkit-progress-value {
    background: ${({ theme }) => theme.colors.yellow};
    border: 3px solid rgba(0, 0, 0, 0.09);
    border-radius: 35px;
  }
`;
