import styled from "styled-components";

export const UsageIcon = styled.svg.attrs({
  role: "presentation",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 20 20",
  children: (
    <path d="M11.654 10.925H7.883a.787.787 0 0 0-.793.78v7.514c0 .431.355.78.793.78h3.771c.435 0 .787-.349.787-.78v-7.514a.78.78 0 0 0-.787-.78Zm-.8 7.514h-2.18v-5.95h2.18v5.95ZM4.558 9.07H.79a.786.786 0 0 0-.79.78v9.368c0 .43.352.78.79.78h3.768c.441 0 .793-.35.793-.78V9.85a.787.787 0 0 0-.793-.78Zm-.793 9.368H1.586v-7.805h2.182v7.805h-.003ZM18.747 6.344h-3.773c-.435 0-.79.35-.79.777V19.22c0 .43.355.78.79.78h3.77c.436 0 .794-.35.794-.78V7.124a.784.784 0 0 0-.79-.78Zm-.795 12.096H15.77V7.904h2.184V18.44h-.002ZM9.765 8.88a.805.805 0 0 0 .595-.265l5.232-5.787v1.608c0 .433.355.783.79.783.44 0 .793-.35.793-.783V.78a.814.814 0 0 0-.063-.312V.459c-.006-.006-.014-.014-.017-.02a.77.77 0 0 0-.24-.273.802.802 0 0 0-.472-.166h-3.569a.786.786 0 0 0-.793.777c0 .431.358.783.793.783h1.801L9.776 6.908 6.87 3.588a.781.781 0 0 0-.558-.268.768.768 0 0 0-.584.206l-3.6 3.32a.77.77 0 0 0-.034 1.101c.3.316.802.327 1.12.034l2.992-2.757 2.958 3.383c.151.169.38.222.6.273h.001Z" />
  ),
})`
  width: 20px;
  path {
    fill: currentColor;
  }
`;
