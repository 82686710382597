import { StyledButton } from "./Styled";
import { WidgetContext } from "./Context";
import React, { useContext } from "react";

export type WidgetButtonProps = React.ComponentPropsWithoutRef<
  typeof StyledButton
>;

export function WidgetButton({ children, ...props }: WidgetButtonProps) {
  const { buttonLabel } = useContext(WidgetContext);
  return (
    <StyledButton {...props}>
      {children || buttonLabel || "Get started"}
    </StyledButton>
  );
}
