import "@aws-amplify/ui-react/styles.css";
import { notification } from "antd";
import { Amplify, Auth } from "aws-amplify";
import isEqual from "lodash.isequal";
import { useContext, useEffect, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import "./App.css";
import { AuthContext } from "./AppContext";
import AppWrapper from "./AppWrapper";
import { setup_intent_create } from "./Components/Common/Billing";
import CustomLoader from "./Components/Common/Loader/CustomLoader";
import RequireAuth from "./Components/Common/RequireAuth";
import RequireUnauthed from "./Components/Common/RequireUnauthed";
import APIKeys from "./Components/Screens/APIKeys/Presentational";
import Billing from "./Components/Screens/Billing/Presentational";
import Usage from "./Components/Screens/Usage/Presentational";
import Dashboard from "./Components/Screens/Dashboard/Presentational";
import MainLayout from "./Components/Screens/MainContainer/Presentational";
import Account from "./Components/Screens/Account/Presentational";
import Register from "./Components/Screens/Register/Presentantional";
import PaymentMethod from "./Components/Screens/PaymentMethod/Presentational";
import PasswordResetPage from "./Components/Screens/ResetPassword/PasswordResetPage";
import ResetPassword from "./Components/Screens/ResetPassword/Presentantional";
import Survey from "./Components/Screens/Survey/Survey";
import NotFound from "./Components/Screens/NotFound/Presentational";
import awsConfig from "./aws-exports";

Amplify.configure(awsConfig);

function App() {
  const {
    setStep,
    user,
    setSetupIntent,
    hasUpdatedPayment,
    setUserEmail,
    oauthState,
    setOauthState,
    customer,
    loader,
    setLoader,
  } = useContext(AuthContext);

  const [prospect, setProspect] = useState(null);
  const [idempotencyKey, setIdempotencyKey] = useState(null);

  const createBillingPage = async () => {
    const res = await Auth.currentSession();
    const accessToken = res.getAccessToken();
    const jwt = accessToken.getJwtToken();
    // Check if the user has already submitted the form,
    // if the request is a duplicate, we use the same idempotency key
    const isDuplicate = prospect && isEqual(prospect, user.attributes);
    const localIdempotencyKey = isDuplicate ? idempotencyKey : uuidv4();
    if (!isDuplicate) {
      setProspect(user.attributes);
      setIdempotencyKey(localIdempotencyKey);
    }

    try {
      const setup_intent = await setup_intent_create(
        jwt,
        {
          billing_customer_id: user.attributes["custom:stripe_customer_id"],
        },
        localIdempotencyKey
      );
      setSetupIntent(setup_intent);
    } catch (error) {
      notification.error({ message: "Something went wrong" });
    } finally {
      setStep(4);
      setLoader(false);
    }
  };

  useEffect(() => {
    if (process.env.REACT_APP_STAGE) {
      console.info("STAGE: ", process.env.REACT_APP_STAGE);
    }
    if (user) {
      setLoader(true);
      if (customer && hasUpdatedPayment) {
        setLoader(false);
        setOauthState("success");
      } else if (!customer) {
        setLoader(false);
        setUserEmail(user.attributes.email);
        setStep(3);
        setOauthState("register");
      } else if (customer && !hasUpdatedPayment) {
        setLoader(true);
        createBillingPage();
        setOauthState("register");
      }
    }
  }, [user, hasUpdatedPayment, customer]);

  return (
    <BrowserRouter>
      {loader ? <CustomLoader /> : null}
      <AppWrapper>
        <Routes>
          <Route
            path="/google/auth-success"
            element={<Register method={oauthState} />}
          />
          <Route path="/reset" element={<PasswordResetPage />} />
          {/* FIXME: is this route in use? */}
          <Route
            path="/added-payment-method"
            element={<Navigate to="/dashboard" />}
          />
          <Route
            path="/register"
            element={
              <RequireUnauthed>
                <Register method={"register"} />
              </RequireUnauthed>
            }
          />
          <Route
            path="/"
            element={
              <RequireUnauthed>
                <Register method={"register"} />
              </RequireUnauthed>
            }
          />
          <Route
            path="/login"
            element={
              <RequireUnauthed>
                <Register method={"login"} />
              </RequireUnauthed>
            }
          />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="survey" element={<Survey />} />

          <Route
            path=""
            element={
              <RequireAuth>
                <MainLayout />
              </RequireAuth>
            }
          >
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="usage" element={<Usage />} />
            <Route path="api-keys" element={<APIKeys />} />
            <Route path="billing" element={<Billing />} />
            <Route path="account" element={<Account />} />
            <Route path="payment-method" element={<PaymentMethod />} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </AppWrapper>
    </BrowserRouter>
  );
}

export default App;
