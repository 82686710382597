import { Link } from "react-router-dom";
import styled from "styled-components";
import { useMainContainerContext } from "./Provider";

export default function Header() {
  const { isMenuOpenMobile, setIsMenuOpenMobile } = useMainContainerContext();
  return (
    <Container $isMenuOpenMobile={isMenuOpenMobile}>
      <HomeLink to="/dashboard">
        <HomeLinkLogo />
        <HomeLinkTitle>botshelf console</HomeLinkTitle>
      </HomeLink>
      <MenuButton
        isOpen={isMenuOpenMobile}
        onClick={() => setIsMenuOpenMobile(!isMenuOpenMobile)}
      />
    </Container>
  );
}

const lightTheme = `
background: #fff;
color: #000;
`;

const darkTheme = `
color: #fff;
background: #000;
`;

const Container = styled.header`
  position: fixed;
  top: var(--notification-bar-height);
  left: 0;
  right: 0;
  height: var(--header-height);
  background: #fff;
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.18);
  z-index: 100;

  @media (max-width: 767px) {
    transition: color 0.2s ease-in-out, background 0.2s ease-in-out;
    ${({ $isMenuOpenMobile }) => ($isMenuOpenMobile ? darkTheme : lightTheme)};
  }
`;

const HomeLink = styled(Link)`
  display: flex;
  align-items: center;
  margin-left: 25px;
  gap: 10px;
  text-decoration: none;
  color: inherit;
`;

const HomeLinkTitle = styled.span`
  font-weight: 700;
  font-size: 20px;
  line-height: 116.4%;
`;

const HomeLinkLogo = styled.svg.attrs({
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 30 29",
  role: "presentation",
  children: (
    <>
      <path d="M18.553 14.22a3.778 3.778 0 0 0-1.613-3.099h-.026l-.39-.234c-1.093-.73-4.215-3.152-4.007-7.11A3.777 3.777 0 0 0 8.744 0a3.783 3.783 0 0 0-3.799 3.777c0 1.172.547 2.213 1.38 2.917.051.052.103.078.181.13.833.65 4.398 3.62 4.502 7.605 0 0 .234 4.792-3.799 6.876l-.156.078h-.026A3.78 3.78 0 0 0 5.1 24.665a3.777 3.777 0 0 0 3.773 3.776 3.777 3.777 0 0 0 3.773-3.776c0-.13 0-.287-.026-.417h.026s.026-4.063 3.695-6.616c1.327-.573 2.211-1.875 2.211-3.412Z" />
      <path d="M29.999 14.22a3.778 3.778 0 0 0-1.613-3.099h-.027l-.39-.234c-1.119-.73-4.241-3.152-4.033-7.11A3.777 3.777 0 0 0 20.163 0a3.777 3.777 0 0 0-3.772 3.777c0 1.172.546 2.213 1.379 2.917.052.052.104.078.182.13.832.65 4.397 3.62 4.501 7.605 0 0 .234 4.792-3.799 6.876l-.156.078h-.026a3.78 3.78 0 0 0-1.925 3.282 3.777 3.777 0 0 0 3.773 3.776 3.777 3.777 0 0 0 3.772-3.776c0-.13 0-.287-.026-.417h.026s.026-4.063 3.695-6.616a3.712 3.712 0 0 0 2.212-3.412Z" />
      <path d="M3.825 18.052a3.827 3.827 0 0 0 3.825-3.829 3.827 3.827 0 0 0-3.825-3.829A3.827 3.827 0 0 0 0 14.225a3.827 3.827 0 0 0 3.825 3.828Z" />
    </>
  ),
})`
  width: 30px;
  @media (min-width: 1024px) {
    height: 36px;
  }
  path {
    fill: currentColor;
  }
  path:nth-child(1),
  path:nth-child(2) {
    fill: ${({ theme }) => theme.colors.yellow};
  }
`;

function MenuButton(props) {
  return (
    <MenuButtonContainer onClick={props.onClick}>
      {props.isOpen ? <MenuButtonCloseIcon /> : <MenuButtonOpenIcon />}
    </MenuButtonContainer>
  );
}

const MenuButtonContainer = styled.button`
  appearance: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: none;
  padding: 25px;
  margin: 0;
  cursor: pointer;
  color: inherit;

  @media (min-width: 1024px) {
    display: none;
  }
`;

const MenuButtonOpenIcon = styled((props) => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    aria-label="Open menu"
    {...props}
  >
    <rect y="3" width="24" height="2" rx="1" fill="currentColor" />
    <rect y="11" width="24" height="2" rx="1" fill="currentColor" />
    <rect y="19" width="24" height="2" rx="1" fill="currentColor" />
  </svg>
))`
  width: 24px;
`;

const MenuButtonCloseIcon = styled((props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.0718 19.0713C19.4624 18.6807 19.4624 18.0476 19.0718 17.657L13.4149 12.0002L19.0717 6.34335C19.4623 5.95283 19.4623 5.31966 19.0717 4.92914C18.6812 4.53862 18.0481 4.53862 17.6575 4.92914L12.0007 10.5859L6.34392 4.92912C5.9534 4.5386 5.32023 4.5386 4.92971 4.92912C4.53918 5.31965 4.53918 5.95281 4.92971 6.34334L10.5865 12.0002L4.92961 17.6571C4.53909 18.0476 4.53909 18.6808 4.92961 19.0713C5.32014 19.4618 5.9533 19.4618 6.34383 19.0713L12.0007 13.4144L17.6576 19.0713C18.0482 19.4618 18.6813 19.4618 19.0718 19.0713Z"
      fill="currentColor"
    />
  </svg>
))`
  width: 24px;
`;
