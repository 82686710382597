import { Auth } from "aws-amplify";
import { Form, Formik } from "formik";
import React, { useContext, useRef, useState } from "react";
import * as Yup from "yup";
import {
  Button,
  CustomLabel,
  Details,
  FieldError,
  FlexBox,
  Input,
  MarginText,
  Title,
} from "../Register/Styled";

import { notification } from "antd";
import { AuthContext } from "../../../AppContext";
import ticket from "../../../Assets/Images/ticket.svg";
import Header from "../../Common/Header/Presendational";
import { InvisibleButton, StyledLink } from "../Register/Styled";

const ResetPassword = ({ props }) => {
  const formikRef = useRef();
  const { userEmail, resetPageStep, setResetPageStep } =
    useContext(AuthContext);

  const initialValues = {
    email: userEmail,
    verificationCode: "",
    password: "",
    confirmPassword: "",
  };
  const [isProcessing, setIsProcessing] = useState(false);
  const fieldErrorMessage = "This field is mandatory";
  const Step1Schema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required(fieldErrorMessage),
  });
  const [check, setCheck] = useState(false);

  async function sendResetEmail(values, { setErrors }) {
    setIsProcessing(true);
    try {
      const { user } = await Auth.forgotPassword(values.email);
    } catch (error) {
      let message = error.message;
      notification.error({ message: message });
    }
    if (resetPageStep === 1) {
      setResetPageStep(resetPageStep + 1);
    } else {
      setCheck(true);
    }
  }

  const prevStep = ({ setFieldValue }) => {
    //clean the field values of resetPageStep 1
    setFieldValue("email", "");
    setResetPageStep(resetPageStep - 1);
  };

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Header />
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={Step1Schema}
        onSubmit={sendResetEmail}
      >
        {({ errors, isSubmitting, values, setFieldValue, setErrors }) => (
          <div>
            {resetPageStep === 1 && (
              <Form>
                <FlexBox>
                  <Title>Password reset</Title>
                  <Details>
                    Please enter the email address you registered on botshelf.ai
                  </Details>
                  <CustomLabel htmlFor="email">Email</CustomLabel>
                  <Input type="email" name="email" placeholder="Email" />
                  <FieldError name="email" component="div" />

                  <Button type="submit" disabled={isSubmitting}>
                    Continue
                  </Button>
                  <InvisibleButton>
                    <StyledLink to="/login">&lt; Back to sign in</StyledLink>
                  </InvisibleButton>
                </FlexBox>
              </Form>
            )}

            {resetPageStep === 2 && (
              <Form>
                <FlexBox>
                  <Title>Password reset</Title>
                  <MarginText>
                    {" "}
                    We’ve sent an email with instructions for resetting your
                    password at <strong>{values.email}</strong>{" "}
                  </MarginText>
                  <MarginText
                    style={{ cursor: "pointer" }}
                    onClick={() => prevStep({ setFieldValue })}
                  >
                    <strong>
                      <u>Wrong address?</u>
                    </strong>
                  </MarginText>
                  <MarginText>
                    {" "}
                    Note: email could take up to 10 minutes to arrive, make sure
                    you check your spam.{" "}
                  </MarginText>
                  <MarginText>
                    {" "}
                    Still not there?{" "}
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => sendResetEmail(values, { setErrors })}
                    >
                      <u>
                        <strong>Send again</strong>
                      </u>
                    </span>{" "}
                    {check && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-evenly",
                        }}
                      >
                        <img src={ticket} alt="" />
                        <Details>A new password reset email was sent.</Details>
                      </div>
                    )}
                  </MarginText>
                </FlexBox>
              </Form>
            )}
          </div>
        )}
      </Formik>
    </div>
  );
};
export default ResetPassword;
