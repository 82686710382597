import { Field, Form, Formik } from "formik";
import React from "react";

const Survey = () => {
  const initialValues = {
    question: "",
  };

  const handleSubmit = (values, { setSubmitting }) => {
    setTimeout(() => {
      alert(JSON.stringify(values, null, 2));
      setSubmitting(false);
    }, 400);
  };

  return (
    <div
      style={{
        position: "fixed",
        width: " 406px",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <h1>Delete Account</h1>
      <p>
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry's standard dummy text ever
        since the 1500s, when an unknown printer took a galley of type and
        scrambled it to make a type specimen book. It has survived not only five
        centuries, but also the leap into electronic typesetting, remaining
        essentially unchanged. It was popularised in the 1960s with the release
        of Letraset sheets containing Lorem Ipsum passages,
      </p>
      <p>
        {" "}
        and more recently with desktop publishing software like Aldus PageMaker
        including versions of Lorem Ipsum.
      </p>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ isSubmitting }) => (
          <Form style={{ display: "flex", flexDirection: "column" }}>
            <label>
              <Field type="checkbox" name="question" value="option1" />
              Option 1
            </label>

            <label>
              <Field type="checkbox" name="question" value="option2" />
              Option 2
            </label>

            <label>
              <Field type="checkbox" name="question" value="option3" />
              Option 3
            </label>
            <label>
              <Field type="checkbox" name="question" value="option3" />
              Option 4
            </label>
            <label>
              <Field type="checkbox" name="question" value="option3" />
              Option 5
            </label>
            <label>
              <Field type="checkbox" name="question" value="option3" />
              Option 6
            </label>
            <label htmlFor="comment">or text your own reason</label>
            <Field name="comment" as="textarea" />

            <button type="submit" disabled={isSubmitting}>
              Submit
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};
export default Survey;
