import { ChartData } from "chart.js";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useMonthlyUsage } from "./useMonthlyUsage";
import DesktopChart from "./DesktopChart";
import MobileChart from "./MobileChart";

export default function ResponsiveChart() {
  const [mediaQueryList] = useState(window.matchMedia("(max-width: 768px)"));
  const [isMobile, setIsMobile] = useState(() => mediaQueryList.matches);
  const { data: monthlyUsage } = useMonthlyUsage();

  const data = useMemo(() => {
    if (monthlyUsage) {
      // remove first 7 consecutive days with 0 requests
      const filteredMonthlyUsage = monthlyUsage.filter(
        (item, index, array) =>
          index < 3 || array[index - 1].count !== 0 || item.count !== 0
      );
      const data: ChartData<"bar"> = {
        labels: filteredMonthlyUsage?.map((item) =>
          moment({
            year: item.year,
            month: item.month - 1,
            day: 1,
          })
        ),
        datasets: [
          {
            label: "API requests",
            data: filteredMonthlyUsage?.map((item) => item.count) || [],
            backgroundColor: "#FFCD23",
          },
        ],
      };
      return data;
    }
  }, [monthlyUsage]);

  useEffect(() => {
    const listener = (e: MediaQueryListEvent) => {
      setIsMobile(e.matches);
    };
    mediaQueryList.addEventListener("change", listener);
    return () => {
      mediaQueryList.removeEventListener("change", listener);
    };
  }, [setIsMobile, mediaQueryList]);

  if (isMobile) {
    return <MobileChart data={data} />;
  } else {
    return <DesktopChart data={data} />;
  }
}
