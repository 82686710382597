import React from "react";
import { RevokeConfirmationDialog } from "./Dialog";
import { useOptimisticRevokeKey } from "./utils/revokeKey";
import { APIKey } from "./utils/types";

export interface RevokeKeyDialogProps {
  apiKey: APIKey;
  closeModal: () => void;
}

export default function RevokeKeyDialog({
  apiKey,
  closeModal,
}: RevokeKeyDialogProps) {
  const [revokeKey] = useOptimisticRevokeKey();
  return (
    <RevokeConfirmationDialog
      apiKeyName={apiKey.name}
      onCancel={closeModal}
      onConfirm={() => {
        closeModal();
        revokeKey(apiKey.api_key_hash);
      }}
    />
  );
}
