import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import React, { useState } from "react";
import styled from "styled-components";
import { GreenStatusBadge, RedStatusBadge } from "./StatusBadge";
import { formatIssuedAt, parseIssuedAt } from "./utils/common";
import { useGetAllKeysQuery } from "./utils/getAllKeys";
import { APIKey, isOptimisticAPIKey } from "./utils/types";
import EmptyOverlay from "./EmptyOverlay";
import ErrorOverlay from "./ErrorOverlay";
import LoadingOverlay from "./LoadingOverlay";
import RowActions from "./RowActions";
import SortableHeader from "./SortableHeader";

const columnHelper = createColumnHelper<APIKey>();

const defaultColumns = [
  columnHelper.accessor("issued_at", {
    header: (info) => {
      const label = "Date created";
      if (info.column.getCanSort()) {
        return (
          <SortableHeader
            isSorted={info.column.getIsSorted()}
            toggleSorting={info.column.toggleSorting}
          >
            {label}
          </SortableHeader>
        );
      } else {
        return label;
      }
    },
    cell: (info) => {
      return <DataCell apiKey={info.row.original} />;
    },
  }),
  columnHelper.display({
    id: "actions",
    cell: (props) => <RowActions row={props.row} />,
  }),
];

export default function MobileTable() {
  const { data = [], error, isLoading } = useGetAllKeysQuery();
  const [sorting, setSorting] = useState([{ id: "issued_at", desc: true }]);
  const [rowSelection, setRowSelection] = useState({});

  const table = useReactTable({
    data,
    columns: defaultColumns,
    state: {
      sorting,
      rowSelection,
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    onRowSelectionChange: setRowSelection,
    enableRowSelection: true,
    enableMultiRowSelection: false,
  });

  return (
    <TableWrapper>
      {isLoading ? (
        <LoadingOverlay />
      ) : error ? (
        <ErrorOverlay />
      ) : data.length === 0 ? (
        <EmptyOverlay />
      ) : null}
      <TableContainer>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => {
            const isOptimistic = isOptimisticAPIKey(row.original);
            const isSelected = row.getIsSelected();
            return (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <TableCell
                    key={cell.id}
                    $isOptimistic={isOptimistic}
                    $rowSelected={isSelected}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </tr>
            );
          })}
        </tbody>
      </TableContainer>
    </TableWrapper>
  );
}

const TableCell = styled.td<{
  $isOptimistic?: boolean;
  $rowSelected?: boolean;
}>`
  ${({ $isOptimistic }) =>
    $isOptimistic && `pointer-events: none; & > * { opacity: 0.5; }`}
  ${({ $rowSelected, theme }) =>
    $rowSelected && `background-color: ${theme.colors.gray};`}
`;

const TableWrapper = styled.div`
  --row-height: 65px;

  display: flex;
  position: relative;
  flex-direction: column;
`;

const TableContainer = styled.table`
position: relative;
background: #ffffff;
width: 100%;
min-height: calc(var(--row-height) * 8);
border-radius: 20px;
overflow-x: auto;
border-collapse: collapse;
text-align: left;

display: grid;
grid-template-columns: 1fr auto;
grid-template-rows: fit-content(auto);
align-content: flex-start;

thead,
tbody,
tr {
  display: contents;
}

th {
  height: var(--row-height);
}
th:first-child > * {
  position: absolute;
}

td:last-child {
  position: relative;
  overflow: visible;
}

tbody tr:last-child td {
  border-bottom: none;
  background: #fff;
}

th {
  background: ${({ theme }) => theme.colors.yellow};
  font-weight: 700;
  font-size: 21px;
  line-height: 116.4%;
  display: flex;
  align-items: center;
}
td {
  border-bottom: 2px solid rgba(0, 0, 0, 0.5);
  font-weight: 500;
  font-size: 16px;
  line-height: 116.4%;
  padding: 16px 8px;

  &:first-child {
    border-left: solid 1px #fff;
  }
  &:last-child {
    border-right: solid 1px #fff;
  }
}

th,td {
  padding-left 16px;
  &:last-child {
    padding-right: 16px;
  }
}
`;

function DataCell({ apiKey: data }: { apiKey: APIKey }) {
  const dateCreated = parseIssuedAt(data.issued_at);
  const BadgeComponent =
    data.status === "ACTIVE" ? GreenStatusBadge : RedStatusBadge;

  return (
    <DataCellContainer>
      <DataCellName>{data.name}</DataCellName>
      <DataCellKey>{data.api_key_masked}</DataCellKey>
      <BadgeComponent>{data.status}</BadgeComponent>
      {dateCreated.isValid() && (
        <DataCellTime>{formatIssuedAt(dateCreated)}</DataCellTime>
      )}
    </DataCellContainer>
  );
}

const DataCellContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
`;

const DataCellName = styled.p`
  margin: 0;
  font-weight: 700;
  font-size: 16px;
  line-height: 1;

  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const DataCellKey = styled.code`
  margin: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 1;
`;

const DataCellTime = styled.p`
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 1;
  color: rgba(0, 0, 0, 0.5);
`;
