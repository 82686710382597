import React, { ComponentPropsWithoutRef } from "react";
import { StyledContainer } from "./Styled";

export type WidgetContainerProps = ComponentPropsWithoutRef<
  typeof StyledContainer
>;

export function WidgetContainer({ children, ...props }: WidgetContainerProps) {
  return <StyledContainer {...props}>{children}</StyledContainer>;
}
