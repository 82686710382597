const base_url = process.env.REACT_APP_API_URL + "/v1";

/**
 * Creates a billing customer on the backend
 * @param {string} access_token - access token of the user
 * @param {object} payload - payload of the request
 * @param {string} idempotency_key - used to prevent duplicate requests
 */
export async function billing_customer_create(
  access_token,
  payload,
  idempotency_key
) {
  if (!idempotency_key) {
    // Safeguard to prevent duplicate requests
    throw new Error(
      `Missing idempotency_key on 'billing_customer_create' call. This is required to prevent duplicate requests.'`
    );
  }
  // we need to append something to this key because stripe
  // doesn't allow sharing the same idempotency key between different endpoints
  const idempotencyKey = idempotency_key + "_billing_customer_create";

  const params = {
    method: "POST",
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json",
      Authorization: access_token,
      "Idempotency-Key": idempotencyKey,
    },
    body: JSON.stringify(payload),
  };
  const res = await fetch(`${base_url}/customer-system/customer`, params);
  return res.json();
}

export async function get_customer(access_token, idempotency_key) {
  if (!idempotency_key) {
    // Safeguard to prevent duplicate requests
    throw new Error(
      `Missing idempotency_key on 'get_customer' call. This is required to prevent duplicate requests.'`
    );
  }
  // we need to append something to this key because stripe
  // doesn't allow sharing the same idempotency key between different endpoints
  const idempotencyKey = idempotency_key + "_get_customer";

  const params = {
    method: "GET",
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json",
      Authorization: access_token,
      "Idempotency-Key": idempotencyKey,
    },
  };
  const res = await fetch(`${base_url}/customer-system/customer`, params);
  return res.json();
}

/**
 * Creates an intent on the backend
 * @param {string} access_token - access token of the user
 * @param {object} payload - payload of the request
 * @param {string} idempotency_key - used to prevent duplicate requests
 */
export async function setup_intent_create(
  access_token,
  payload,
  idempotency_key
) {
  if (!idempotency_key) {
    // Safeguard to prevent duplicate requests
    throw new Error(
      `Missing idempotency_key on 'setup_intent_create' call. This is required to prevent duplicate requests.'`
    );
  }
  // we need to append something to this key because stripe
  // doesn't allow sharing the same idempotency key between different endpoints
  const idempotencyKey = idempotency_key + "_setup_intent_create";

  const params = {
    method: "POST",
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json",
      Authorization: access_token,
      "Idempotency-Key": idempotencyKey,
    },
    body: JSON.stringify(payload),
  };
  const res = await fetch(`${base_url}/billing-system/setup-intent`, params);
  const data = await res.json();
  return data;
}
