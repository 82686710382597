import React from "react";
import { NotifyBannerContextType, NotifyBannerProvider } from "./Context";
import { NotifyBannerContainer } from "./Container";
import { NotifyBannerTitle } from "./Title";
import { NotifyBannerDescription } from "./Description";
import { NotifyBannerWarningIcon } from "./WarningIcon";
import { NotifyBannerInfoIcon } from "./InfoIcon";
import { NotifyBannerHideButton } from "./HideButton";

export type NotifyBannerProps = {
  content: NotifyBannerContextType;
  title?: React.ReactNode;
  description?: React.ReactNode;
  icon?: React.ReactNode;
  hideButton?: React.ReactNode;
  className?: string;
};

export type NotifyBannerContent = NotifyBannerProps["content"];

export default function NotifyBanner({
  content,
  title,
  description,
  icon,
  hideButton,
  className,
}: NotifyBannerProps) {
  return (
    <NotifyBannerProvider value={content}>
      <NotifyBannerContainer className={className}>
        {icon}
        <div>
          {title}
          {description}
        </div>
        {hideButton}
      </NotifyBannerContainer>
    </NotifyBannerProvider>
  );
}

NotifyBanner.Container = NotifyBannerContainer;
NotifyBanner.Title = NotifyBannerTitle;
NotifyBanner.Description = NotifyBannerDescription;
NotifyBanner.WarningIcon = NotifyBannerWarningIcon;
NotifyBanner.InfoIcon = NotifyBannerInfoIcon;
NotifyBanner.HideButton = NotifyBannerHideButton;
