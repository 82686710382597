import { Auth } from "aws-amplify";

let accessToken: string;

export async function getAccessToken() {
  if (!accessToken) {
    const session = await Auth.currentSession();
    accessToken = session.getAccessToken().getJwtToken();
  }
  return accessToken;
}
