import { notification } from "antd";
import { useCallback } from "react";
import { useSWRConfig } from "swr";
import useSWRMutation, { SWRMutationConfiguration } from "swr/mutation";
import { getAccessToken } from "./getAccessToken";
import { GET_ALL_KEYS, UseGetAllKeysData } from "./getAllKeys";
import { OptimisticAPIKey, APIResponse, APIKey } from "./types";

export const REVOKE_API_KEY = "REVOKE_API_KEY";

export type RevokeKeyResponse = APIResponse<APIKey>;

/**
 * Revokes the given API key
 */
export async function revokeKey(apiKeyHash: string) {
  const endpoint = process.env.REACT_APP_API_URL + "/v1/api-key-system/api-key";
  const res = await fetch(endpoint, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${await getAccessToken()}`,
    },
    body: JSON.stringify({ api_key_hash: apiKeyHash }),
    // For security reasons, we don't want to cache this response
    cache: "no-store",
  });
  const json = (await res.json()) as RevokeKeyResponse;
  if (json.status === "success") {
    return json.data;
  } else {
    console.error(json.error);
    throw new Error("Failed to revoke API key");
  }
}

/**
 * The cache key for the revoke key mutation
 */
export type UseRevokeKeyMutationKey = typeof REVOKE_API_KEY;

/**
 * The data returned by the revoke key mutation
 */
export type UseRevokeKeyMutationData = Awaited<ReturnType<typeof revokeKey>>;

/**
 * The error returned by the revoke key mutation
 */
export type UseRevokeKeyMutationError = Error;

/**
 * The argument for the revoke key mutation is the hash of the key
 */
export type UseRevokeKeyMutationArg = string;

/**
 * SWR configuration for the revoke key mutation
 */
export type UseRevokeKeyMutationConfiguration = SWRMutationConfiguration<
  UseRevokeKeyMutationData,
  UseRevokeKeyMutationError,
  UseRevokeKeyMutationArg,
  UseRevokeKeyMutationKey,
  UseRevokeKeyMutationData
>;

/**
 * Hook to invoke the revoke key mutation
 */
export function useRevokeKeyMutation(
  options: UseRevokeKeyMutationConfiguration = {}
) {
  return useSWRMutation<
    UseRevokeKeyMutationData,
    UseRevokeKeyMutationError,
    UseRevokeKeyMutationKey,
    UseRevokeKeyMutationArg
  >(
    REVOKE_API_KEY,
    (_, { arg: apiKeyHash }) => {
      return revokeKey(apiKeyHash);
    },
    options
  );
}

/**
 * A custom hook to revoke an API key optimistically
 */
export function useOptimisticRevokeKey() {
  const { mutate } = useSWRConfig();
  const { trigger, ...mutation } = useRevokeKeyMutation({
    onError: (error, _key, _config) => {
      notification.error({
        message: error.message,
      });
    },
    onSuccess: (data) => {
      notification.success({
        message: `${data.name} has been revoked`,
      });
    },
  });

  const revokeKey = useCallback(
    async (apiKeyHash: string) => {
      mutate<UseGetAllKeysData>(
        GET_ALL_KEYS,
        // Update the cache immediately with revoked key
        function optimisitUpdate(data) {
          return data?.map((item) => {
            if (item.api_key_hash === apiKeyHash) {
              const optimisticKey: OptimisticAPIKey = {
                ...item,
                status: "REVOKED",
                _optimistic: true,
              };
              return optimisticKey;
            }
            return item;
          });
        },
        {
          revalidate: false,
        }
      );
      // Trigger the API call to revoke the key
      await trigger(apiKeyHash);
      // Revalidate the cache to ensure it's up to date
      mutate(GET_ALL_KEYS);
    },
    [mutate, trigger]
  );

  return [revokeKey, mutation] as const;
}
