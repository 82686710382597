import { useEffect } from "react";
import { CreateKeyDialog, KeyCreatedDialog } from "./Dialog";
import { useOptimisticCreateKey } from "./utils/createKey";
import React from "react";

export type CreateKeyFunnelProps = {
  onComplete: () => void;
};

/**
 * A funnel to create an API key
 * @example
 *
 * import { CreateKeyFunnel } from "./CreateKeyFunnel";
 *
 * export default function Example() {
 *   // Function passed to onComplete needs to be memoized,
 *   // otherwise it will be called on every render
 *   const sayHello = useCallback(() => {
 *      console.log("Hello!");
 *   }, []);
 *   return <CreateKeyFunnel onComplete={sayHello} />;
 * }
 */
export default function CreateKeyFunnel({ onComplete }: CreateKeyFunnelProps) {
  const [createKey, { data, error, isMutating }] = useOptimisticCreateKey();

  useEffect(() => {
    if (error) {
      onComplete();
    }
    // Make sure that on complete is memoized.
  }, [error, onComplete]);

  if (isMutating) {
    return null;
  }
  if (error) {
    return null;
  }

  if (data?.api_key) {
    return (
      <KeyCreatedDialog secretApiKey={data.api_key} onContinue={onComplete} />
    );
  } else {
    return <CreateKeyDialog onCancel={onComplete} onConfirm={createKey} />;
  }
}
