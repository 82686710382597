import React from "react";
import styled from "styled-components";
import NotifyBanner from "../../Common/NotifyBanner";
import PaymentCard, { PaymentCardProps } from "./PaymentCard";
import { useBillingInfo } from "./useBillingInfo";
import MASTERCARD from "./Mastercard.png";
import VISA from "./Visa.png";

export const bannerContent = {
  title: "Need to change some info?",
  description: (
    <>
      Momentarily you can request changes by contacting our support team at
      <a
        href="mailto:support@botshelf.ai"
        style={{ marginLeft: "0.5ch", color: "#000", fontWeight: "bold" }}
      >
        support@botshelf.ai
      </a>
    </>
  ),
};

function capitalCase(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export default function PaymentMethod() {
  const { data } = useBillingInfo();

  const cardInfo = data?.default_payment_method?.card;
  const cardOwner = data?.email;
  const cardBrand = cardInfo?.brand;

  let cardLogo: PaymentCardProps["logo"] = undefined;
  if (cardBrand === "mastercard") {
    cardLogo = {
      src: MASTERCARD,
      alt: "Mastercard",
    };
  } else if (cardBrand === "visa") {
    cardLogo = {
      src: VISA,
      alt: "Visa",
    };
  }

  return (
    <Container>
      <Heading>Payment Method</Heading>
      <Paragraph>
        Check below the Payment Method that will be used for your monthly bill.
      </Paragraph>
      <NotifyBanner
        content={bannerContent}
        title={<NotifyBanner.Title />}
        description={<NotifyBanner.Description />}
        icon={<NotifyBanner.InfoIcon />}
      />
      {cardInfo && cardOwner && (
        <PaymentCard
          number={
            <>
              {capitalCase(cardBrand || "")} &bull;&bull;&bull;&bull;{" "}
              {cardInfo.last4}
            </>
          }
          owner={cardOwner}
          expiration={`${cardInfo.exp_month}/${cardInfo.exp_year}`}
          logo={cardLogo}
        />
      )}
    </Container>
  );
}

const Container = styled.div`
  padding: 1em 24px;
  @media (min-width: 768px) {
    padding: 1em 40px;
  }
  max-width: 1000px;
  min-height: 100%;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
`;

const Heading = styled.h1`
  font-style: normal;
  font-weight: 700;
  font-size: 33px;
  line-height: 116.4%;
  margin: 0 0 12px;
`;

const Paragraph = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  margin: 0 0 24px;
`;
