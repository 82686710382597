import useSWR from "swr";
import { getAccessToken } from "../APIKeys/utils/getAccessToken";
import { APIResponse } from "../APIKeys/utils/types";

type BillingCustomer = {
  id: string;
  address: Record<string, any>;
  business_name: string;
  created: number;
  description?: string;
  default_payment_method?: {
    id: string;
    card: {
      brand?: string;
      country: string;
      exp_month: number;
      exp_year: number;
      last4: string;
    };
  };
  email: string;
  metadata?: Record<string, string>;
  phone?: string;
  type: Record<string, any>;
  vat_id?: string;
  vat_type?: any;
  valid_vat?: boolean;
  tax_id?: string;
  sdi?: string;
  pec?: string;
};

async function getBillingInfo() {
  const token = await getAccessToken();

  const url = new URL(
    "/v1/billing-system/customer",
    process.env.REACT_APP_API_URL
  );
  const res = await fetch(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  const json = (await res.json()) as APIResponse<BillingCustomer>;
  if (json.status === "error") {
    console.error(json.error);
    throw new Error("Failed to fetch API keys");
  }
  return json.data;
}

export function useBillingInfo() {
  return useSWR(["BILLING_INFO"], getBillingInfo);
}
