import { StyledHideButton } from "./Styled";
import { useContext } from "react";
import { NotifyBannerContext } from "./Context";
import React, { ComponentProps } from "react";

export type NotifyBannerHideButtonProps = ComponentProps<
  typeof StyledHideButton
>;

export function NotifyBannerHideButton({
  children,
  ...props
}: NotifyBannerHideButtonProps) {
  const { hideButtonLabel } = useContext(NotifyBannerContext);
  return (
    <StyledHideButton {...props}>
      {hideButtonLabel || hideButtonLabel}
    </StyledHideButton>
  );
}
