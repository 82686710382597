/* eslint-disable no-undef */
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AuthProvider } from "./AppContext";
import { ThemeProvider } from "styled-components";
import { datadogRum } from "@datadog/browser-rum";
import { myTheme } from "./myTheme";

if (process.env.REACT_APP_STAGE) {
  datadogRum.init({
    applicationId: "c2beee16-64d6-4e65-a111-4472f8bf4c61",
    clientToken: "pub9cf11595413915eca3fc9bd502fb8e76",
    site: "datadoghq.eu",
    env: process.env.REACT_APP_STAGE,
    service: process.env.REACT_APP_DATADOG_SERVICE,
    version: process.env.REACT_APP_DATADOG_VERSION,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
  });
  datadogRum.startSessionReplayRecording();
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ThemeProvider theme={myTheme}>
    <AuthProvider>
      <App />
    </AuthProvider>
  </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
