import React from "react";
import { WidgetButton } from "./Button";
import { WidgetContainer } from "./Container";
import { WidgetContextType, WidgetProvider } from "./Context";
import { WidgetGreeting } from "./Greeting";
import { WidgetHeading } from "./Heading";
import { WidgetImage } from "./Image";
import { WidgetParagraph } from "./Paragraph";
import { WidgetFooter } from "./Footer";

export interface WidgetProps {
  content: WidgetContextType;
  image?: React.ReactNode;
  greeting?: React.ReactNode;
  heading?: React.ReactNode;
  paragraph?: React.ReactNode;
  button?: React.ReactNode;
  children?: React.ReactNode;
  footer?: React.ReactNode;
}

export type WidgetContent = WidgetProps["content"];

export default function Widget({
  content,
  image,
  greeting,
  heading,
  paragraph,
  button,
  children,
  footer,
}: WidgetProps) {
  return (
    <WidgetProvider value={content}>
      <WidgetContainer>
        <div>
          {greeting}
          {heading}
          {paragraph}
          {button}
          {children}
        </div>
        {image}
        {footer}
      </WidgetContainer>
    </WidgetProvider>
  );
}

Widget.Container = WidgetContainer;
Widget.Image = WidgetImage;
Widget.Greeting = WidgetGreeting;
Widget.Heading = WidgetHeading;
Widget.Paragraph = WidgetParagraph;
Widget.Button = WidgetButton;
Widget.Footer = WidgetFooter;
