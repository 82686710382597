import React, { useMemo } from "react";
import CounterList from "./CounterList";
import { useDailyUsage } from "../DailyUsage/useDailyUsage";

export default function MonthlyRequests() {
  const { data: dailyUsage } = useDailyUsage(30);

  const count = useMemo(
    () =>
      dailyUsage
        ?.map((info) => info.count)
        ?.reduce((acc, count) => acc + count, 0),
    [dailyUsage]
  );

  return (
    <>
      <CounterList.Label>Last 30 days</CounterList.Label>
      <CounterList.Value>{count ?? "-"}</CounterList.Value>
    </>
  );
}
