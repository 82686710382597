import React, { createContext } from "react";

export type WidgetContextType = {
  username?: string;
  title?: string;
  description?: string;
  buttonLabel?: string;
  image?: {
    src: string;
    alt: string;
  };
};

export const WidgetContext = createContext<WidgetContextType>({});

export const WidgetProvider = ({
  children,
  value,
}: {
  children: React.ReactNode;
  value: WidgetContextType;
}) => {
  return (
    <WidgetContext.Provider value={value}>{children}</WidgetContext.Provider>
  );
};
