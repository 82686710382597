import useSWR, { Fetcher, SWRConfiguration } from "swr";
import { getAccessToken } from "../../APIKeys/utils/getAccessToken";
import { APIResponse } from "../../APIKeys/utils/types";

export type MonthlyUsage = {
  year: number;
  month: number;
  count: number;
}[];

export type MonthlyUsageResponse = APIResponse<MonthlyUsage>;

export async function getMonthlyUsage(limit: number) {
  const url = new URL(
    "/v1/metrics-system/monthly-usage",
    process.env.REACT_APP_API_URL
  );
  url.searchParams.append("limit", limit.toString());
  const accessToken = await getAccessToken();
  const res = await fetch(url, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  const json = (await res.json()) as MonthlyUsageResponse;
  if (json.status === "success") {
    return json.data;
  } else {
    console.error(json.error);
    throw new Error("Failed to fetch monthly usage");
  }
}

export const MONTHLY_USAGE = "MONTHLY_USAGE";

export type UseMonthlyUsageQueryKey = [typeof MONTHLY_USAGE, number];
export type UseMonthlyUsageData = Awaited<ReturnType<typeof getMonthlyUsage>>;
export type UseMonthlyUsageFetcher = Fetcher<
  UseMonthlyUsageData,
  UseMonthlyUsageQueryKey
>;
export type UseMonthlyUsageError = Error;

const fetcher: UseMonthlyUsageFetcher = (arg) => {
  const limit = arg[1];
  return getMonthlyUsage(limit);
};

/**
 * SWR configuration for the get all keys query
 */
export type UseGetAllKeysConfiguration = SWRConfiguration<
  UseMonthlyUsageData,
  UseMonthlyUsageError,
  UseMonthlyUsageFetcher
>;

export function useMonthlyUsage(
  limit: number = 12,
  options: UseGetAllKeysConfiguration = {}
) {
  return useSWR<
    UseMonthlyUsageData,
    UseMonthlyUsageError,
    UseMonthlyUsageQueryKey
  >([MONTHLY_USAGE, limit], fetcher, options);
}
