import styled from "styled-components";
import { useLocation } from "react-router-dom";

const Container = styled.div`
  display: grid;
  place-content: center;
  min-height: 100%;
  width: 100%;
  padding: 20px;
  text-align: center;

  @media (min-width: 768px) {
    padding: 100px;
  }
`;

const Title = styled.h1`
  font-size: 48px;
  opacity: 0.2;
  margin: 0;
  line-height: 1.1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (min-width: 768px) {
    font-size: 72px;
  }
`;

const Subtitle = styled.span`
  opacity: 0.2;
  font-size: 32px; 
  font-weight: 500;

  @media (min-width: 768px) {
    font-size: 48px;    
  }
}
`;

export default function NotFound() {
  const location = useLocation();
  return (
    <Container>
      <Title>Page not found</Title>
      <Subtitle>{location.pathname}</Subtitle>
    </Container>
  );
}
