import styled from "styled-components";

export const APIKeysIcon = styled.svg.attrs({
  role: "presentation",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 20 20",
  children: (
    <>
      <path d="M17.265 2.735a5.925 5.925 0 0 0-9.418 6.983L1 16.565V19h2.435l.715-.714-.01-1.507 1.527-.01.956-.957v-1.389l1.403-.014 2.257-2.256a5.927 5.927 0 0 0 6.982-9.418Z" />
      <path d="M15.522 6.804a1.644 1.644 0 1 1-2.325-2.325 1.644 1.644 0 0 1 2.325 2.325Z" />
    </>
  ),
})`
  width: 20px;
  path {
    stroke: currentColor;
    stroke-width: 1.5;
    stroke-miterlimit: 10;
  }
`;
