import React from "react";
import styled from "styled-components";
import { useCustomerInfo } from "../useCustomerInfo";

export function BillingInfo() {
  const { customer } = useCustomerInfo();
  if (customer) {
    return (
      <Container>
        <Heading>
          All your invoices have been sent via email to {customer.email}
        </Heading>
        <Paragraph>
          Please check your inbox (including your Spam folder) to ensure that
          you have received them.
        </Paragraph>
      </Container>
    );
  } else {
    return null;
  }
}

const Container = styled.div`
  margin-bottom: 24px;
`;

const Heading = styled.h3`
  margin: 0;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
`;

const Paragraph = styled.p`
  margin: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
`;
