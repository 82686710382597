import React, { ComponentProps, useContext } from "react";
import { NotifyBannerContext } from "./Context";
import { StyledTitle } from "./Styled";

export type NotifyBannerTitleProps = ComponentProps<typeof StyledTitle>;

export function NotifyBannerTitle({
  children,
  ...props
}: NotifyBannerTitleProps) {
  const { title } = useContext(NotifyBannerContext);
  return <StyledTitle {...props}>{children || title}</StyledTitle>;
}
