import { createContext, useContext, useState } from "react";

const MainContainerContext = createContext({
  isMenuOpenMobile: false,
  setIsMenuOpenMobile: () => {},
  isNotificationBarVisible: false,
  setIsNotificationBarVisible: () => {},
});

export default function MainContainerProvider({ children }) {
  const [isMenuOpenMobile, setIsMenuOpenMobile] = useState(false);
  const [isNotificationBarVisible, setIsNotificationBarVisible] =
    useState(false);

  return (
    <MainContainerContext.Provider
      value={{
        isMenuOpenMobile,
        setIsMenuOpenMobile,
        isNotificationBarVisible,
        setIsNotificationBarVisible,
      }}
    >
      {children}
    </MainContainerContext.Provider>
  );
}

export function useMainContainerContext() {
  const context = useContext(MainContainerContext);
  if (context === undefined) {
    throw new Error(
      "useMainContainerContext must be used within a MainContainerProvider"
    );
  } else {
    return context;
  }
}
