import { Link, Outlet } from "react-router-dom";
import styled from "styled-components";

export default function MainArea() {
  return (
    <Container>
      <Wrapper>
        <Outlet />
      </Wrapper>
      <Footer>
        ©2022 Botshelf srl
        <LinkText to="https://botshelf.ai/terms-of-service/" target="_blank">
          {" "}
          Terms of service{" "}
        </LinkText>
        <Divider>|</Divider>
        <LinkText to="https://botshelf.ai/privacy-policy/" target="_blank">
          {" "}
          Privacy Policy{" "}
        </LinkText>
        <Divider>|</Divider>
        <LinkText to="https://botshelf.ai/manage-cookies/" target="_blank">
          {" "}
          Manage cookies
        </LinkText>
      </Footer>
    </Container>
  );
}

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.gray};
  padding-top: var(--content-gap);

  display: grid;
  grid-template-rows: 1fr auto;
  width: 100%;
`;

const Wrapper = styled.div`
  overflow-x: auto;
  width: 100%;
  padding: 30px 0;
`;

const Footer = styled.footer`
  display: flex;
  margin: 20px 25px;
  font-weight: 500;
  font-size: 14px;
  line-height: 116.4%;

  @media (min-width: 1024px) {
    margin: 20px 50px;
  }
`;

const LinkText = styled(Link)`
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  font-weight: 700;
  font-size: 14px;
  line-height: 116.4%;
  margin-left: 10px;
  margin-right: 5px;
  color: #000000;
  text-decoration: none;
`;
const Divider = styled.p`
  color: #ff9b23;
  margin-bottom: 0 !important;
  margin-top: 0 !important;
`;
