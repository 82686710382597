import { DeleteConfirmationDialog } from "./Dialog";
import { useOptimisticDeleteKey } from "./utils/deleteKey";
import React from "react";
import { APIKey } from "./utils/types";

export interface DeleteKeyDialogProps {
  apiKey: APIKey;
  closeModal: () => void;
}

export default function DeleteKeyDialog({
  apiKey,
  closeModal,
}: DeleteKeyDialogProps) {
  const [deleteKey] = useOptimisticDeleteKey();
  return (
    <DeleteConfirmationDialog
      apiKeyName={apiKey.name}
      onCancel={closeModal}
      onConfirm={() => {
        closeModal();
        deleteKey(apiKey.api_key_hash);
      }}
    />
  );
}
