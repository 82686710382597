import styled from "styled-components";

const DocumentationIcon = styled.svg.attrs((props) => ({
  ...props,
  role: "presentation",
  viewBox: "0 0 22 20",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: (
    <>
      <path
        d="M18.2802 0L2.94287 0.000739044C2.93885 0.000739044 2.93557 0.0029562 2.93156 0.00300237C2.92352 0.0029562 2.91617 0.000739044 2.90814 0.000739044C1.30441 0.000739044 0 1.30515 0 2.90888V5.57535C0 6.15254 0.470032 6.62257 1.04722 6.62257H4.37218V17.7297C4.37218 18.9816 5.39058 20 6.64252 20H18.9195C20.1715 20 21.1899 18.9816 21.1899 17.7297V2.90592C21.1899 2.90227 21.1878 2.89922 21.1878 2.89557C21.1804 1.29813 19.8794 0 18.2802 0ZM1.51356 2.90888C1.51356 2.13953 2.13953 1.5143 2.97761 1.5143C3.74621 1.5143 4.37218 2.13953 4.37218 2.90888V5.10901H1.51356V2.90888ZM18.9195 18.4864H6.64252C6.22496 18.4864 5.88574 18.1472 5.88574 17.7297V2.90888C5.88574 2.40064 5.74297 1.92964 5.51077 1.51356H18.2802C18.374 1.51356 18.4654 1.52354 18.5541 1.54128C19.1926 1.66899 19.6756 2.2333 19.6756 2.90888L19.6763 17.7297C19.6763 18.1472 19.3371 18.4864 18.9195 18.4864Z"
        fill="currentColor"
      />
      <path
        d="M16.6081 9.24561H8.956C8.5377 9.24561 8.19922 9.58409 8.19922 10.0024C8.19922 10.4207 8.5377 10.7592 8.956 10.7592H16.6081C17.0264 10.7592 17.3648 10.4207 17.3648 10.0024C17.3648 9.58409 17.0264 9.24561 16.6081 9.24561Z"
        fill="currentColor"
      />
      <path
        d="M16.6081 5.41943H8.956C8.5377 5.41943 8.19922 5.75792 8.19922 6.17621C8.19922 6.59451 8.5377 6.933 8.956 6.933H16.6081C17.0264 6.933 17.3648 6.59451 17.3648 6.17621C17.3648 5.75792 17.0264 5.41943 16.6081 5.41943Z"
        fill="currentColor"
      />
      <path
        d="M16.6081 13.0713H8.956C8.5377 13.0713 8.19922 13.4098 8.19922 13.8281C8.19922 14.2464 8.5377 14.5849 8.956 14.5849H16.6081C17.0264 14.5849 17.3648 14.2464 17.3648 13.8281C17.3648 13.4098 17.0264 13.0713 16.6081 13.0713Z"
        fill="currentColor"
      />
    </>
  ),
}))`
  width: 22px;
`;

export default DocumentationIcon;
