import { WidgetContext } from "./Context";
import React, { useContext, ComponentPropsWithoutRef } from "react";
import { StyledHeading } from "./Styled";

export type WidgetHeadingProps = ComponentPropsWithoutRef<typeof StyledHeading>;

export function WidgetHeading({ children, ...props }: WidgetHeadingProps) {
  const { title } = useContext(WidgetContext);
  return <StyledHeading {...props}>{children || title}</StyledHeading>;
}
