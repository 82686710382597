export const StepsTitle = [
  { title: "Sign Up" },
  { title: "Verify your email" },
  { title: "Complete Registration" },
  { title: "Payment method" },
];

export const initialValues = {
  email: "",
  password: "",
  confirmPassword: "",
  verificationCode: "",
  name: "",
  surname: "",
  prefix: "",
  phoneNumber: "",
  role: "",
  companyName: "",
  offers: false,
  privacy: false,
  businessType: "BUSINESS",
  taxId: "",
  taxType: "",
  state: "",
  country: "",
  vat: "",
  sdiCode: "",
  city: "",
  address: "",
  address2: "",
  zipCode: "",
  pec: "",
};
