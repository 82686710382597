import { createContext } from "react";
import React, { ReactNode } from "react";

export type NotifyBannerContextType = {
  title?: string | ReactNode;
  description?: string | ReactNode;
  hideButtonLabel?: string | ReactNode;
};

export const NotifyBannerContext = createContext<NotifyBannerContextType>({});

export type NotifyBannerProviderProps = {
  children: React.ReactNode;
  value: NotifyBannerContextType;
};

export const NotifyBannerProvider: React.FC<NotifyBannerProviderProps> = ({
  children,
  value,
}) => {
  return (
    <NotifyBannerContext.Provider value={value}>
      {children}
    </NotifyBannerContext.Provider>
  );
};
