import styled from "styled-components";

export const StickyHeader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  z-index: 999;
  height: 96px;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.18);
`;

export const Logo = styled.img`
  position: absolute;
  width: 270px;
  height: 40px;
  left: 60px;
  top: 30px;
`;
