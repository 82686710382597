import React from "react";
import NotifyBanner, { NotifyBannerContent } from "../../Common/NotifyBanner";
import { useCustomerInfo } from "../Billing/useCustomerInfo";
import FreeCallsCounter from "./FreeCallsCounter";
import WELCOME_IMG from "./Welcome.png";
import Widget, { WidgetContent } from "./Widget";

const bannerContent: NotifyBannerContent = {
  title: "Your free trial is about to end",
  description:
    "Once you reach the limit of 1000 complimentary API calls, you'll be billed for any further calls.",
};

const widgetContent: WidgetContent = {
  title: "Good news! Here's your free trial: test & explore our AI platform",
  description: `You have a total of 1000 free API calls to discover our AI technologies and test the performance of our BOTs.`,
  image: {
    src: WELCOME_IMG,
    alt: "",
  },
};

const { Greeting, Heading, Paragraph, Image, Footer } = Widget;

export default function FreeCallsWidget() {
  const { customer, isReachingEndOfTrial } = useCustomerInfo();

  return (
    <>
      {isReachingEndOfTrial && (
        <NotifyBanner
          content={bannerContent}
          title={<NotifyBanner.Title />}
          description={<NotifyBanner.Description />}
          icon={<NotifyBanner.WarningIcon />}
        />
      )}
      <Widget
        button={null}
        content={{
          ...widgetContent,
          username: customer?.first_name,
        }}
        greeting={<Greeting />}
        heading={<Heading />}
        paragraph={<Paragraph />}
        image={<Image />}
        footer={
          <Footer>
            <Footer.DocsLink />
          </Footer>
        }
      >
        <FreeCallsCounter />
      </Widget>
    </>
  );
}
