import styled from "styled-components";

const Button = styled.button`
  display: block;
  appearance: none;
  border: none;
  border-radius: 6px;
  padding: 15px 24px;

  background: var(--bg-color);
  transition: background 0.2s ease-in-out;

  &:hover {
    background: var(--bg-color-hover);
  }

  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  color: var(--text-color);
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

export const ButtonPrimary = styled(Button)`
  --bg-color: ${({ theme }) => theme.colors.black};
  --bg-color-hover: ${({ theme }) => theme.colors.dimmed.black};
  --text-color: ${({ theme }) => theme.colors.white};
`;

export const ButtonSecondary = styled(Button)`
  --bg-color: ${({ theme }) => theme.colors.yellow};
  --bg-color-hover: ${({ theme }) => theme.colors.dimmed.yellow};
  --text-color: ${({ theme }) => theme.colors.black};
`;
