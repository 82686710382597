import { NavLink } from "react-router-dom";
import styled from "styled-components";
import BotsCatalogueIcon from "./SecondaryNav/BotsCatalogueIcon";
import DocumentationIcon from "./SecondaryNav/DocumentationIcon";

const secondaryLinks = [
  {
    to: "https://www.beta.botshelf.ai/catalogue",
    label: "Bot Library",
    icon: <BotsCatalogueIcon />,
    newTab: true,
  },
  {
    to: "https://developer.beta.botshelf.ai/",
    label: "Documentation",
    icon: <DocumentationIcon />,
    newTab: true,
  },
  // {
  //   // FIXME: add the correct link
  //   to: "https://www.youtube.com/watch?v=2Q_ZzBGPdqE",
  //   label: "Help",
  //   icon: <HelpIcon />,
  //   newTab: true,
  //   isDisabled: true,
  // },
];

export default function SecondaryNav() {
  return (
    <SecondaryNavContainer>
      {secondaryLinks.map((link) => {
        const isExternal = link.to.startsWith("http");
        return (
          <SecondaryNavLink
            to={link.to}
            key={link.label}
            target={link.newTab && "_blank"}
            // https://developer.chrome.com/docs/lighthouse/best-practices/external-anchors-use-rel-noopener/
            rel={link.newTab && "noreferrer"}
            $isDisabled={link.isDisabled}
          >
            {link.icon} {link.label} {isExternal && <ExternalLinkIcon />}
          </SecondaryNavLink>
        );
      })}
    </SecondaryNavContainer>
  );
}

const SecondaryNavContainer = styled.nav.attrs(() => ({
  id: "sidebar-secondary",
}))`
  flex: 0 1 auto;
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 24px 0 32px;
`;
const SecondaryNavLink = styled(NavLink)`
  display: flex;
  align-items: center;
  gap: 12px;
  color: inherit;
  margin: 0 27px;
  font-weight: 700;
  font-size: 18px;
  line-height: 116.4%;
  text-decoration-thickness: 0.8px;
  text-underline-offset: 2px;

  ${({ $isDisabled }) =>
    $isDisabled && `opacity: 0.5; cursor: not-allowed; pointer-events: none;`}
`;

// function HelpIcon() {
//   return (
//     <svg
//       width="20"
//       height="20"
//       viewBox="0 0 20 20"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         d="M9.70352 12.6533C9.15066 12.6533 8.70312 13.114 8.70312 13.6668C8.70312 14.2066 9.1375 14.6804 9.70352 14.6804C10.2695 14.6804 10.717 14.2066 10.717 13.6668C10.717 13.114 10.2563 12.6533 9.70352 12.6533Z"
//         fill="currentColor"
//       />
//       <path
//         d="M9.87437 4.97949C8.09734 4.97949 7.28125 6.03258 7.28125 6.74336C7.28125 7.25672 7.71563 7.49367 8.07102 7.49367C8.78184 7.49367 8.49227 6.48008 9.83488 6.48008C10.493 6.48008 11.0196 6.76969 11.0196 7.3752C11.0196 8.08598 10.2824 8.49402 9.84805 8.86258C9.46629 9.1916 8.96613 9.73133 8.96613 10.8634C8.96613 11.5478 9.15043 11.7453 9.69008 11.7453C10.335 11.7453 10.4667 11.4557 10.4667 11.2055C10.4667 10.5211 10.4798 10.1262 11.2038 9.5602C11.5592 9.28379 12.678 8.38867 12.678 7.15137C12.678 5.91406 11.5592 4.97949 9.87437 4.97949Z"
//         fill="currentColor"
//       />
//       <path
//         d="M10 0C4.47301 0 0 4.4725 0 10C0 15.5269 4.4725 20 10 20C15.527 20 20 15.5275 20 10C20 4.47301 15.5275 0 10 0ZM10 18.6046C5.25539 18.6046 1.39535 14.7446 1.39535 10C1.39535 5.25535 5.25539 1.39535 10 1.39535C14.7446 1.39535 18.6046 5.25535 18.6046 10C18.6046 14.7446 14.7446 18.6046 10 18.6046Z"
//         fill="currentColor"
//       />
//     </svg>
//   );
// }

function ExternalLinkIcon() {
  return (
    <svg
      role="presentation"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      width="15"
      height="15"
    >
      <path
        d="M11.333 15.5H3c-.668 0-1.296-.26-1.768-.732A2.484 2.484 0 0 1 .5 13V4.667c0-.668.26-1.296.732-1.768A2.484 2.484 0 0 1 3 2.167h3.333a.833.833 0 1 1 0 1.666H3a.828.828 0 0 0-.59.244.828.828 0 0 0-.243.59V13c0 .223.086.432.244.59a.828.828 0 0 0 .589.243h8.333a.828.828 0 0 0 .59-.244.828.828 0 0 0 .244-.589V9.667a.833.833 0 0 1 1.666 0V13c0 .668-.26 1.296-.732 1.768s-1.1.732-1.768.732Zm-5-5a.833.833 0 0 1-.59-1.423l6.912-6.91H9.667a.833.833 0 1 1 0-1.667h5a.83.83 0 0 1 .582.237l.002.002.001.002h.001l.001.001.002.002.002.002.001.002.002.001.002.002a.832.832 0 0 1 .237.582v5a.833.833 0 0 1-1.667 0V3.345l-6.91 6.91a.83.83 0 0 1-.59.245Z"
        fill="currentColor"
      />
    </svg>
  );
}
