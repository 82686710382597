import styled, { keyframes } from "styled-components";
import { useState, useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import { notification } from "antd";
import React from "react";

export type DialogProps = {
  children: React.ReactNode;
  onEscapeKey: () => void;
};

export default function Dialog({ children, onEscapeKey }: DialogProps) {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onEscapeKey();
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [onEscapeKey]);

  return createPortal(
    <Wrapper>
      <Container>{children}</Container>
    </Wrapper>,
    document.getElementById("dialog-root")!
  );
}

export type DeleteConfirmationDialogProps = {
  onCancel: () => void;
  onConfirm: () => void;
  apiKeyName: string;
};

export function DeleteConfirmationDialog({
  onCancel,
  onConfirm,
  apiKeyName,
}: DeleteConfirmationDialogProps) {
  return (
    <Dialog onEscapeKey={onCancel}>
      <CloseButton onClick={onCancel} />
      <Header>
        <Title>Delete API key</Title>
        <Description>
          Are you sure about deleting <em>{apiKeyName}</em>?<br />
          Keep in mind: once you do it, the API key will no longer be showed on
          you API key list and this action cannot be reversed.
        </Description>
      </Header>
      <ButtonsContainer>
        <CancelButton onClick={onCancel} />
        <ConfirmButton onClick={onConfirm} />
      </ButtonsContainer>
    </Dialog>
  );
}

export type RevokeConfirmationDialogProps = {
  onCancel: () => void;
  onConfirm: () => void;
  apiKeyName: string;
};

export function RevokeConfirmationDialog({
  onCancel,
  onConfirm,
  apiKeyName,
}: RevokeConfirmationDialogProps) {
  const [confirmation, setConfirmation] = useState("");
  const isEmpty = confirmation === "";
  const isInvalid = confirmation !== "REVOKE";

  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    // Focus on input when dialog is opened
    inputRef.current?.focus();
  }, []);

  return (
    <Dialog onEscapeKey={onCancel}>
      <CloseButton onClick={onCancel} />
      <Header>
        <Title>Revoke API key</Title>
        <Description>
          Be aware: by revoking this key, you'll be responsible for any service
          interruption. Once confirmed, this action cannot be undone.
          <br />
          <br />
          Are you sure you want to revoke <em>{apiKeyName}</em>?<br />
          Type "REVOKE" to confirm.
        </Description>
      </Header>
      <UserInputContainer>
        <UserInput
          ref={inputRef}
          value={confirmation}
          onChange={(event) => setConfirmation(event.target.value)}
          aria-invalid={isInvalid && !isEmpty}
          aria-errormessage="confirmation-error"
        />
        <UserInputError id="confirmation-error">
          Please type the word as displayed above
        </UserInputError>
      </UserInputContainer>
      <ButtonsContainer>
        <CancelButton onClick={onCancel} />
        <ConfirmButton onClick={onConfirm} disabled={isInvalid || isEmpty} />
      </ButtonsContainer>
    </Dialog>
  );
}

export type KeyCreatedDialogProps = {
  onContinue: () => void;
  secretApiKey: string;
};

export function KeyCreatedDialog({
  onContinue,
  secretApiKey,
}: KeyCreatedDialogProps) {
  const onCopyKey = () => {
    navigator.clipboard.writeText(secretApiKey);
    onContinue();
    notification.success({
      message: "Copied to clipboard",
    });
  };
  return (
    <Dialog onEscapeKey={onContinue}>
      <CloseButton onClick={onContinue} />
      <Header>
        <Title>Your new API key</Title>
        <Description>
          Please save this secret code somewhere safe and accessible. For
          security reasons <strong>you won’t be able to view it again</strong>.
          If you loose this key you’ll need to revoke the API key and generate a
          new one.
          <br />
          <br />
          <strong data-testid="secret-key">{secretApiKey}</strong>
        </Description>
      </Header>
      <ButtonsContainer>
        <CancelButton onClick={onContinue}>OK</CancelButton>
        <ConfirmButton onClick={onCopyKey}>COPY KEY</ConfirmButton>
      </ButtonsContainer>
    </Dialog>
  );
}

export type CreateKeyDialogProps = {
  onCancel: () => void;
  onConfirm: (name: string) => void;
};

export function CreateKeyDialog({ onCancel, onConfirm }: CreateKeyDialogProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [name, setName] = useState("");
  const length = name.trimStart().trimEnd().length;
  const maxLength = 25;

  useEffect(() => {
    // Focus on input when dialog is opened
    inputRef.current?.focus();
  }, []);

  const onChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    if (
      length + 1 <= maxLength ||
      (event.nativeEvent as any).inputType === "deleteContentBackward"
    ) {
      setName(event.target.value);
    }
  };

  return (
    <Dialog onEscapeKey={onCancel}>
      <CloseButton onClick={onCancel} />
      <Header>
        <Title>Name your API key</Title>
        <Description>
          Give your API key a distinct name: it will make management easier.
        </Description>
      </Header>
      <UserInputContainer>
        <UserInputLabel htmlFor="api_key_name">API key name</UserInputLabel>
        <UserInput
          id="api_key_name"
          ref={inputRef}
          value={name}
          onChange={onChange}
          placeholder={"My App - Prod"}
        />
        <UserInputHelper>
          {length}/{maxLength}
        </UserInputHelper>
      </UserInputContainer>
      <ButtonsContainer>
        <CancelButton onClick={onCancel} />
        <ConfirmButton
          onClick={() => onConfirm(name)}
          disabled={length > maxLength || length === 0}
        >
          CREATE
        </ConfirmButton>
      </ButtonsContainer>
    </Dialog>
  );
}

const fadeIn = keyframes`
    from {
        opacity: 0;
    } to {
        opacity: 1;
    }
`;

const Wrapper = styled.div`
  position: fixed;
  inset: 0;
  z-index: 200;

  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 768px) {
    background-color: rgba(0, 0, 0, 0.5);
    animation: ${fadeIn} 0.3s ease-in-out;
  }
`;

const Container = styled.div`
  background-color: #fff;
  width: 625px;
  padding: 50px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  position: relative;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.18);

  @media (max-width: 767px) {
    width: 100%;
    height: 100%;
    padding: 80px 16px 65px;
    border-radius: 0;
  }
`;

export const CloseButton = styled.button.attrs(() => ({
  "aria-label": "Close dialog",
  children: (
    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.4265 19.4281C19.9472 18.9074 19.9472 18.0632 19.4265 17.5425L11.884 9.99993L19.4264 2.45753C19.9471 1.93683 19.9471 1.09261 19.4264 0.571913C18.9057 0.0512141 18.0615 0.0512143 17.5408 0.571913L9.99837 8.11431L2.45595 0.57189C1.93526 0.0511912 1.09104 0.0511913 0.570336 0.57189C0.0496371 1.09259 0.0496373 1.93681 0.570336 2.45751L8.11276 9.99993L0.570212 17.5425C0.0495127 18.0632 0.0495128 18.9074 0.570212 19.4281C1.09091 19.9488 1.93513 19.9488 2.45583 19.4281L9.99837 11.8855L17.5409 19.4281C18.0616 19.9488 18.9058 19.9488 19.4265 19.4281Z"
        fill="currentColor"
      />
    </svg>
  ),
}))`
  position: absolute;
  top: 0;
  right: 0;
  margin: 36px;
  border: none;
  background: none;
  cursor: pointer;
  appearance: none;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 20px;
    height: 20px;
  }

  @media (max-width: 767px) {
    margin: 16px;
    padding: 8px;
    svg {
      width: 18px;
      height: 18px;
    }
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const Title = styled.h1`
  font-weight: 900;
  font-size: 27px;
  line-height: 116.4%;
  margin: 0;
`;

export const Description = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  margin: 0;

  em {
    font-style: normal;
    font-weight: 700;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;

  @media (max-width: 767px) {
    margin-top: auto;
    flex-direction: column-reverse;
    gap: 15px;
  }
`;

export const Button = styled.button`
  appearance: none;
  border: none;
  background: none;

  display: block;
  appearance: none;
  border: solid 2px;
  border-radius: 8px;
  padding: 10px 16px;
  min-width: 160px;

  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }
`;

export const CancelButton = styled(Button).attrs((props) => ({
  children: "CANCEL",
  ...props,
}))``;

export const ConfirmButton = styled(Button).attrs((props) => ({
  children: "CONFIRM",
  ...props,
}))`
  background: #000;
  color: #fff;
`;

const UserInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const UserInputLabel = styled.label`
  font-weight: 800;
  font-size: 18px;
  line-height: 116.4%;
  margin-bottom: 10pxpx;
`;

const UserInputError = styled.div`
  visibility: hidden;
  color: ${({ theme }) => theme.colors.red};
  font-weight: 400;
  font-size: 14px;
  line-height: 116.4%;
`;

const UserInputHelper = styled.div`
  color: #000;
  font-size: 14px;
  line-height: 116.4%;
`;

const UserInput = styled.input`
  background: ${({ theme }) => theme.colors.gray};
  border: 1px solid;
  border-radius: 10px;

  &[aria-invalid="true"] {
    border-color: ${({ theme }) => theme.colors.red};
  }

  &[aria-invalid="true"] ~ ${UserInputError} {
    visibility: visible;
  }
`;
