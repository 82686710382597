import useSWR, { Fetcher, SWRConfiguration } from "swr";
import { getAccessToken } from "./getAccessToken";
import { APIKey, APIResponse } from "./types";

export type GetAllKeysResponse = APIResponse<APIKey[]>;

/**
 * Fetches all API keys
 */
export async function getAllKeys() {
  const url = new URL(
    "/v1/api-key-system/api-key",
    process.env.REACT_APP_API_URL
  );
  // url.searchParams.append("timestamp", Date.now().toString());
  const res = await fetch(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${await getAccessToken()}`,
      "Cache-Control": "no-cache",
    },
    // Bypass the browser cache while making the request
    // This is important for optimistic updates to work because
    // following any mutation, the client will re-fetch this data.
    // If the response doesn't reflect the mutation, the client will be presented with stale data.
    cache: "no-store",
  });
  const json = (await res.json()) as GetAllKeysResponse;
  if (json.status === "success") {
    return json.data;
  } else {
    console.error(json.error);
    throw new Error("Failed to fetch API keys");
  }
}

export const GET_ALL_KEYS = "GET_ALL_KEYS";

/**
 * The cache key for the get all keys query
 */
export type UseGetAllKeysQueryKey = typeof GET_ALL_KEYS;

/**
 * The data returned by the get all keys query
 */
export type UseGetAllKeysData = Awaited<ReturnType<typeof getAllKeys>>;

/**
 * The error returned by the get all keys query
 */
export type UseGetAllKeysError = Error;

/**
 * The fetcher for the get all keys query
 */
export type UseGetAllKeysFetcher = Fetcher<
  UseGetAllKeysData,
  UseGetAllKeysQueryKey
>;

/**
 * SWR configuration for the get all keys query
 */
export type UseGetAllKeysConfiguration = SWRConfiguration<
  UseGetAllKeysData,
  UseGetAllKeysError,
  typeof fetcher
>;

const fetcher: UseGetAllKeysFetcher = (_) => getAllKeys();

/**
 * Hook to invoke the get all keys query
 */
export function useGetAllKeysQuery(options: UseGetAllKeysConfiguration = {}) {
  return useSWR<UseGetAllKeysData, UseGetAllKeysError, UseGetAllKeysQueryKey>(
    GET_ALL_KEYS,
    fetcher,
    options
  );
}
