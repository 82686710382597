import { DownOutlined } from "@ant-design/icons";
import { Select } from "antd";
import { useField } from "formik";
import { StyledSelectField } from "./Styled";

const { Option } = Select;

function CustomSelectField({ name, options, space, error, disabled }) {
  const [field] = useField(name);

  return (
    <StyledSelectField
      error={error}
      showSearch
      defaultValue={disabled && options[0].value}
      filterOption={(input, option) =>
        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      space={space}
      onChange={field.onChange(name)}
      placeholder="Select an option"
      suffixIcon={
        <DownOutlined
          style={{
            pointerEvents: "none",
            cursor: "pointer",
          }}
        />
      }
      disabled={disabled}
    >
      {options.map((option) => (
        <Option key={option.value} label={option.label}>
          {option.label}
        </Option>
      ))}
    </StyledSelectField>
  );
}

export default CustomSelectField;
