import styled from "styled-components";
import React from "react";

export function HelpBanner() {
  return (
    <Container>
      <Icon />
      <Text>
        <em>Need help?</em> If you didn't receive your invoices or if you have
        any further questions regarding your Billing information,{" "}
        <a href="mailto:support@botshelf.ai">contact us</a>.
      </Text>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: baseline;
  gap: 8px;
  max-width: 654px;
`;
<svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
  <path
    d="M9.704 12.652c-.553 0-1 .461-1 1.014 0 .54.433 1.014 1 1.014.566 0 1.013-.474 1.013-1.014 0-.553-.46-1.014-1.013-1.014ZM9.874 4.979c-1.777 0-2.593 1.053-2.593 1.763 0 .514.435.75.79.75.71 0 .421-1.013 1.764-1.013.658 0 1.185.29 1.185.895 0 .711-.738 1.12-1.172 1.488-.382.329-.882.868-.882 2 0 .685.184.882.724.882.645 0 .777-.29.777-.54 0-.684.013-1.079.737-1.645.355-.276 1.474-1.171 1.474-2.409 0-1.237-1.119-2.171-2.804-2.171Z"
    fill="#000"
  />
  <path
    d="M10 0C4.473 0 0 4.473 0 10s4.473 10 10 10 10-4.473 10-10S15.527 0 10 0Zm0 18.605c-4.745 0-8.605-3.86-8.605-8.605 0-4.745 3.86-8.605 8.605-8.605 4.745 0 8.605 3.86 8.605 8.605 0 4.745-3.86 8.605-8.605 8.605Z"
    fill="#000"
  />
</svg>;

const Icon = styled.svg.attrs({
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 20 20",
  children: (
    <>
      <path d="M9.704 12.652c-.553 0-1 .461-1 1.014 0 .54.433 1.014 1 1.014.566 0 1.013-.474 1.013-1.014 0-.553-.46-1.014-1.013-1.014ZM9.874 4.979c-1.777 0-2.593 1.053-2.593 1.763 0 .514.435.75.79.75.71 0 .421-1.013 1.764-1.013.658 0 1.185.29 1.185.895 0 .711-.738 1.12-1.172 1.488-.382.329-.882.868-.882 2 0 .685.184.882.724.882.645 0 .777-.29.777-.54 0-.684.013-1.079.737-1.645.355-.276 1.474-1.171 1.474-2.409 0-1.237-1.119-2.171-2.804-2.171Z" />
      <path d="M10 0C4.473 0 0 4.473 0 10s4.473 10 10 10 10-4.473 10-10S15.527 0 10 0Zm0 18.605c-4.745 0-8.605-3.86-8.605-8.605 0-4.745 3.86-8.605 8.605-8.605 4.745 0 8.605 3.86 8.605 8.605 0 4.745-3.86 8.605-8.605 8.605Z" />
    </>
  ),
})`
  width: 20px;
  height: 20px;
  flex: 0 0 auto;
  position: relative;
  top: 5px;
  path {
    fill: currentColor;
  }
`;

const Text = styled.p`
  font-style: normal;
  font-size: 16px;
  line-height: 25px;
  margin: 0;

  em {
    font-weight: 700;
    font-style: normal;
  }
  a {
    color: inherit;
  }
`;
