import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useContext, useState } from "react";
import {
  Button,
  CustomLabel,
  Details,
  FieldError,
  FlexBox,
  Input,
  RegistrationFormWrapper,
  Separator,
  StyledLink,
  StyledSpan,
  StyledWarningIcon,
  Title,
} from "./Styled";
import GoogleLoginButton from "../../Common/Oauth/GoogleLogin";
import { Auth } from "aws-amplify";
import { AuthContext } from "../../../AppContext";

const SignInForm = () => {
  const { setUserEmail, setUpdatedUser } = useContext(AuthContext);

  const initialValues = {
    email: "",
    password: "",
  };

  const [isProcessing, setIsProcessing] = useState(false);
  const [beError, setBeError] = useState("");
  const onChange = (e) => {
    e.persist();
    setUserEmail(e.target.value);
  };

  async function signIn(values, { setErrors }) {
    if (isProcessing) {
      return false;
    }
    setIsProcessing(true);
    try {
      await Auth.signIn({
        username: values.email,
        password: values.password,
      });
      setIsProcessing(false);
      setUpdatedUser(
        await Auth.currentAuthenticatedUser({ bypassCache: true })
      );

      return (window.location.href = "/");
    } catch (error) {
      let message = error.message;

      setIsProcessing(false);
      if (error.message !== "Password attempts exceeded") {
        setErrors({ email: message });
      } else {
        setBeError(message);
      }
    }
  }
  const fieldErrorMessage = "This field is mandatory";
  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required(fieldErrorMessage),
    password: Yup.string().required(fieldErrorMessage),
  });

  return (
    <RegistrationFormWrapper>
      <Formik
        initialValues={initialValues}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={validationSchema}
        onSubmit={signIn}
      >
        {({ errors, touched }) => (
          <div>
            <Form>
              <FlexBox>
                <Title>Sign In</Title>
                <Details>
                  Don’t have an account?{" "}
                  <u>
                    <strong>
                      <StyledLink to="/register">Sign up</StyledLink>
                    </strong>
                  </u>
                </Details>
                <GoogleLoginButton
                  state={"success"}
                  text={"Login with Google"}
                />
                <Separator>Or sign in using your email</Separator>
                <CustomLabel htmlFor="email">Email</CustomLabel>
                <Input
                  type="text"
                  name="email"
                  onInput={onChange}
                  error={errors.email && touched.email}
                />
                <FieldError
                  name="email"
                  className="FieldError"
                  component="div"
                />
                <CustomLabel htmlFor="password">Password</CustomLabel>
                <Input
                  type="password"
                  name="password"
                  error={errors.password && touched.password}
                />
                <FieldError name="password" component="div" />
                <StyledLink to="/reset-password">
                  <u>Forgot your password?</u>
                </StyledLink>
                <Button type="submit" disabled={isProcessing}>
                  <span id="button-text">
                    {isProcessing ? "Processing ... " : "Login"}
                  </span>
                </Button>
                {beError !== "" && (
                  <div>
                    <StyledWarningIcon />
                    <StyledSpan>Too many failed attempts</StyledSpan>
                    <Details>
                      Please try again in a few minutes or{" "}
                      <strong>
                        <u>contact our support team.</u>
                      </strong>
                    </Details>
                  </div>
                )}
              </FlexBox>
            </Form>
          </div>
        )}
      </Formik>
    </RegistrationFormWrapper>
  );
};
export default SignInForm;
