import { notification } from "antd";
import { Auth } from "aws-amplify";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as Yup from "yup";
import { PasswordValidation } from "../../../Constants/Validations";
import { signOutAllDevices } from "../../../Constants/helpers";
import Header from "../../Common/Header/Presendational";
import {
  Button,
  CustomLabel,
  Details,
  FieldError,
  FlexBox,
  Input,
  List,
  MarginText,
  Title,
  Validations,
  ValidationsTitle,
} from "../Register/Styled";

const PasswordResetPage = ({ onSubmit }) => {
  const [step, setStep] = useState(1);
  const [isProcessing, setIsProcessing] = useState(false);
  const [passwordInput, setPasswordInput] = useState("");
  const [containUpperCase, setContainUppercase] = useState(false);
  const [containLetterNumber, setContainLetterNumber] = useState(false);
  const [containCharacter, setContainCharacter] = useState(false);
  const [passwordLength, setPasswordLength] = useState(false);
  const fieldErrorMessage = "This field is mandatory";
  const [searchParams] = useSearchParams();
  const initialValues = {
    password: "",
    confirmPassword: "",
  };
  let username = searchParams.get("data");
  let code = searchParams.get("ccode");

  async function resetPassword(values, { setErrors }) {
    if (isProcessing === true) {
      return false;
    }
    setIsProcessing(true);
    try {
      const { user } = await Auth.forgotPasswordSubmit(
        JSON.parse(atob(username)).username,
        code,
        values.confirmPassword
      );
      setIsProcessing(false);
      signOutAllDevices();
      setStep(step + 1);
    } catch (error) {
      let message = error.message;
      notification.error({ message: message });
      setIsProcessing(false);
    }
  }

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .matches(PasswordValidation, fieldErrorMessage)
      .required(fieldErrorMessage),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required(fieldErrorMessage),
  });
  const onChange = (e) => {
    e.persist();
    setPasswordInput(e.target.value);
  };
  function hasLowerCaseAndUpperCase(str) {
    return /[a-z]/.test(str) && /[A-Z]/.test(str);
  }
  useEffect(() => {
    setContainUppercase(hasLowerCaseAndUpperCase(passwordInput));
    setContainLetterNumber(
      Boolean(passwordInput.match(/[a-zA-Z]/) && passwordInput.match(/\d/))
    );
    setContainCharacter(
      Boolean(passwordInput.match(/[!@#$%^.&*()_+\-=\[\]{};':"\\|,.<>\/?]/))
    );
    setPasswordLength(passwordInput.length >= 12);
  }, [passwordInput]);
  const navigate = useNavigate();
  return (
    <div>
      <Header />
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={resetPassword}
        >
          {({ isSubmitting, errors, touched, values }) => (
            <div>
              {step === 1 && (
                <Form>
                  <FlexBox>
                    <Title>Complete Registration</Title>
                    <Details>
                      Please fill in the fields below with the required
                      information.
                    </Details>

                    <CustomLabel htmlFor="password">New Password</CustomLabel>
                    <Input
                      type="password"
                      name="password"
                      onInput={onChange}
                      error={errors.password && touched.password}
                    />
                    <FieldError name="password" component="div" />
                    {passwordInput !== "" && (
                      <div>
                        <ValidationsTitle>
                          {" "}
                          Password should be composed of:
                        </ValidationsTitle>
                        <List>
                          <Validations condition={passwordLength}>
                            At least 12 characters
                          </Validations>
                          <Validations condition={containUpperCase}>
                            Both uppercase and lowercase letters
                          </Validations>
                          <Validations condition={containLetterNumber}>
                            Both letters and numbers
                          </Validations>
                          <Validations condition={containCharacter}>
                            {" "}
                            At least one special character (e.g. ! @ # ? . ] )
                          </Validations>
                        </List>
                      </div>
                    )}

                    <CustomLabel htmlFor="confirmPassword">
                      Repeat Password
                    </CustomLabel>
                    <Input
                      type="password"
                      name="confirmPassword"
                      error={errors.confirmPassword && touched.confirmPassword}
                    />
                    <FieldError name="confirmPassword" component="div" />
                    <Button disabled={isProcessing} type="submit">
                      <span id="button-text">
                        {isProcessing ? "Processing ... " : "Reset Password"}
                      </span>
                    </Button>
                  </FlexBox>
                </Form>
              )}
              {step === 2 && (
                <Form>
                  <FlexBox>
                    <Title>Reset completed</Title>
                    <MarginText>
                      {" "}
                      Your password reset has been successfully completed.{" "}
                    </MarginText>
                    <Button type="button" onClick={() => navigate("/login")}>
                      SIGN IN
                    </Button>
                  </FlexBox>
                </Form>
              )}
            </div>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default PasswordResetPage;
