import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import React, { useState } from "react";
import styled from "styled-components";
import EmptyOverlay from "./EmptyOverlay";
import ErrorOverlay from "./ErrorOverlay";
import LoadingOverlay from "./LoadingOverlay";
import RowActions from "./RowActions";
import SortableHeader from "./SortableHeader";
import {
  GrayStatusBadge,
  GreenStatusBadge,
  RedStatusBadge,
} from "./StatusBadge";
import {
  formatIssuedAt,
  parseIssuedAt,
  MAX_NUMBER_OF_KEYS,
} from "./utils/common";
import { useGetAllKeysQuery } from "./utils/getAllKeys";
import { APIKey, isOptimisticAPIKey } from "./utils/types";

const InvalidCellValue = styled.span.attrs(() => ({
  children: "Data not available",
}))`
  font-weight: 500;
  font-size: 16px;
  line-height: 116.4%;
  display: flex;
  align-items: center;
  color: #868686;
`;

const ApiKeyCellValue = styled.code`
  font-size: 14px;
`;

const columnHelper = createColumnHelper<APIKey>();

const defaultColumns = [
  columnHelper.accessor("name", {
    header: () => "Name",
    cell: (info) => <span>{info.renderValue()}</span>,
  }),
  columnHelper.accessor("api_key_masked", {
    header: () => "Key",
    cell: (info) => <ApiKeyCellValue>{info.renderValue()}</ApiKeyCellValue>,
  }),
  columnHelper.accessor("status", {
    header: () => "Status",
    cell: (info) => {
      const apiKey = info.row.original;
      const status = info.getValue();
      const Container = isOptimisticAPIKey(apiKey)
        ? GrayStatusBadge
        : status === "ACTIVE"
        ? GreenStatusBadge
        : RedStatusBadge;
      return <Container>{status}</Container>;
    },
  }),
  columnHelper.accessor("issued_at", {
    header: (info) => {
      const label = "Date created";
      if (info.column.getCanSort()) {
        return (
          <SortableHeader
            isSorted={info.column.getIsSorted()}
            toggleSorting={info.column.toggleSorting}
          >
            {label}
          </SortableHeader>
        );
      } else {
        return label;
      }
    },
    cell: (info) => {
      const date = parseIssuedAt(info.getValue());
      if (date.isValid()) return formatIssuedAt(date);
      console.error("Invalid date in `issued_at`", info.row.original);
      return <InvalidCellValue />;
    },
  }),
  columnHelper.display({
    id: "actions",
    cell: (props) => <RowActions row={props.row} />,
  }),
];

export default function DesktopTable() {
  const { data = [], error, isLoading } = useGetAllKeysQuery();
  const [sorting, setSorting] = useState([{ id: "issued_at", desc: true }]);
  const [rowSelection, setRowSelection] = useState({});

  const table = useReactTable({
    data,
    columns: defaultColumns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
      rowSelection,
    },
    onSortingChange: setSorting,
    onRowSelectionChange: setRowSelection,
    enableRowSelection: true,
    enableMultiRowSelection: false,
  });

  return (
    <TableWrapper>
      {isLoading ? (
        <LoadingOverlay />
      ) : error ? (
        <ErrorOverlay />
      ) : data.length === 0 ? (
        <EmptyOverlay />
      ) : null}
      <TableContainer>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => {
            const isOptimistic = isOptimisticAPIKey(row.original);
            const isSelected = row.getIsSelected();
            return (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <TableCell
                    key={cell.id}
                    $isOptimistic={isOptimistic}
                    $rowSelected={isSelected}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </tr>
            );
          })}
        </tbody>
      </TableContainer>
    </TableWrapper>
  );
}

const TableWrapper = styled.div`
  --row-height: 65px;
  --border-radius: 20px;

  display: flex;
  position: relative;
  flex-direction: column;

  // Otherwise the menu will be truncated
  padding-bottom: var(--row-height);
`;

const TableCell = styled.td<{
  $isOptimistic?: boolean;
  $rowSelected?: boolean;
}>`
  ${({ $isOptimistic }) =>
    $isOptimistic && `pointer-events: none; & > * { opacity: 0.5; }`}

  ${({ $rowSelected, theme }) =>
    $rowSelected && `background: ${theme.colors.gray};`}
`;

const TableContainer = styled.table`
    position: relative;

    width: 100%;
    grid-template-rows: repeat(auto-fill, var(--row-height));
    min-height: calc(var(--row-height) * ${MAX_NUMBER_OF_KEYS + 1});    
    text-align: left;
    
    display: grid;
    grid-template-columns:
    minmax(auto, 250px)
    minmax(auto, 250px)
    minmax(auto, 210px)
    minmax(auto, 1fr)
    minmax(auto, 20px);
    
    thead,
    tbody,
    tr {
      display: contents;
    }
    
    th,td {
      padding-left 24px;
      &:last-child {
        padding-right: 24px;
      }
    }
    
    border-radius: var(--border-radius);
    background-color: ${({ theme }) => theme.colors.white};

    th:first-child {
      border-top-left-radius: var(--border-radius);
    }
    th:last-child {
      border-top-right-radius: var(--border-radius);
    }
    tr:last-child td:first-child {
      border-bottom-left-radius: var(--border-radius);
    }
    tr:last-child td:last-child {
      border-bottom-right-radius: var(--border-radius);
    }

    th,
    td {
      height: var(--row-height);
      display: flex;
      align-items: center;
      min-width: max-content;
    }
  
    td:last-child {
      position: relative;
      overflow: visible;
    }
  
    tbody tr:last-child td {
      border-bottom: none;
    }
    
    th {
      background: ${({ theme }) => theme.colors.yellow};
      font-weight: 700;
      font-size: 21px;
      line-height: 116.4%;
    }
    td {
      background: #ffffff;
      border-bottom: 2px solid rgba(0, 0, 0, 0.5);
      font-weight: 500;
      font-size: 16px;
      line-height: 116.4%;
  
      &:first-child {
        border-left: solid 1px #fff;
      }
      &:last-child {
        border-right: solid 1px #fff;
      }
    }
  `;
