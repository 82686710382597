import styled from "styled-components";
import { useMainContainerContext } from "./Provider";
import PrimaryNav from "./Sidebar/PrimaryNav";
import SecondaryNav from "./Sidebar/SecondaryNav";
import UserMenu from "./Sidebar/UserMenu";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function Sidebar() {
  const { isMenuOpenMobile, setIsMenuOpenMobile } = useMainContainerContext();
  const location = useLocation();

  useEffect(() => {
    // Close the mobile menu when the user navigates to a new page.
    // We add a bit of a delay to allow the user to see the menu close.
    const timeoutId = setTimeout(() => setIsMenuOpenMobile(false), 200);
    return () => {
      clearTimeout(timeoutId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <Container isHiddenOnMobile={!isMenuOpenMobile}>
      <Inner>
        <PrimaryNav />
        <SecondaryNav />
        <UserMenu />
      </Inner>
    </Container>
  );
}

const Container = styled.div`
  position: fixed;
  z-index: 99;
  width: 100%;
  background-color: #000;
  color: #fff;
  padding-top: var(--content-gap);
  
  max-height: 100vh;
  overflow-y: clip;

  @media (min-width: 1024px) {
    flex: 0 0 auto;
    position: sticky;
    top: 0;
    width: 300px;
    background-color: #fff;
    color: #000;
  }

  @media (max-width: 1023px) {
    height: 100%;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    pointer-events: none;
    ${(props) => props.isHiddenOnMobile || "pointer-events: all; opacity: 1;"};
    & > div {
      transition: opacity 0.2s ease-out;
      transition-delay: 0.2s;
      opacity: 0;
      ${(props) => props.isHiddenOnMobile || "opacity: 1;"}};
    }
  }
`;

const Inner = styled.div`
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  background-color: inherit;

  @media (min-width: 1024px) {
    padding-top: 30px;
  }
`;
