import React, { useContext } from "react";
import { WidgetContext } from "./Context";
import { StyledImage } from "./Styled";

export type WidgetImageProps = React.ComponentPropsWithoutRef<
  typeof StyledImage
>;

export function WidgetImage({ src, alt, ...props }: WidgetImageProps) {
  const { image } = useContext(WidgetContext);
  return (
    <StyledImage
      {...props}
      src={src || image?.src}
      alt={alt || image?.alt || ""}
    />
  );
}
