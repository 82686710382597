import React from "react";
import { Customer } from "../../Billing/useCustomerInfo";
import CounterList from "./CounterList";

export type TotalRequestsProps = {
  customer?: Customer;
};

export default function TotalRequests({ customer }: TotalRequestsProps) {
  return (
    <>
      <CounterList.Label>Total requests</CounterList.Label>
      <CounterList.Value>{customer?.total_requests ?? "-"}</CounterList.Value>
    </>
  );
}
