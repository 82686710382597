import React from "react";
import styled from "styled-components";
import ResponsiveChart from "./MonthlyUsage/ResponsiveChart";

const Figure = styled.figure`
  margin: 0;
  border-radius: 16px;
  background: #ffffff;
  padding: 16px 24px;
`;

const Caption = styled.figcaption`
  font-weight: 800;
  font-size: 20px;
  line-height: 138.4%;
  margin-bottom: 14px;
`;

export default function MonthlyUsage() {
  return (
    <Figure>
      <Caption>Monthly usage</Caption>
      <ResponsiveChart />
    </Figure>
  );
}
