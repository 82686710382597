import React, { ComponentPropsWithoutRef } from "react";
import { StyledFooter } from "./Styled";
import { WidgetFooterDocsLink } from "./Footer/DocsLink";

export type WidgetFooterProps = ComponentPropsWithoutRef<typeof StyledFooter>;

export function WidgetFooter(props: WidgetFooterProps) {
  return <StyledFooter {...props} />;
}

WidgetFooter.DocsLink = WidgetFooterDocsLink;
