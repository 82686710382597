import { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import RowMenu from "./RowMenu";
import DeleteKeyDialog from "./DeleteKeyDialog";
import RevokeKeyDialog from "./RevokeKeyDialog";
import { Row } from "@tanstack/react-table";
import { APIKey } from "./utils/types";
import React from "react";

export type RowActionsProps = {
  row: Row<APIKey>;
};

export default function RowActions({ row }: RowActionsProps) {
  const container = useRef<HTMLDivElement>(null);

  // Menu is open when the row is selected.
  const showMenu = row.getIsSelected();
  const setShowMenu = useCallback(
    (value: boolean) => row.toggleSelected(value),
    [row]
  );

  // The action that the user has selected from the menu.
  const [menuAction, setMenuAction] = useState<"revoke" | "delete" | null>(
    null
  );

  useEffect(() => {
    // When the menu is open, listen for clicks outside of it.
    // If a click is detected, close the menu.
    if (showMenu) {
      function handleClick(event: MouseEvent) {
        if (
          container.current &&
          event.target instanceof Node &&
          !container.current.contains(event.target)
        ) {
          setShowMenu(false);
        }
      }
      document.addEventListener("click", handleClick);
      return () => {
        document.removeEventListener("click", handleClick);
      };
    }
  }, [showMenu, setShowMenu]);

  return (
    <Container ref={container}>
      <RowActionsButton onClick={() => setShowMenu(!showMenu)} />
      {showMenu && (
        <RowMenu
          row={row}
          onRevoke={() => {
            setMenuAction("revoke");
            setShowMenu(false);
          }}
          onDelete={() => {
            setMenuAction("delete");
            setShowMenu(false);
          }}
        />
      )}
      {menuAction === "revoke" && (
        <RevokeKeyDialog
          apiKey={row.original}
          closeModal={() => setMenuAction(null)}
        />
      )}
      {menuAction === "delete" && (
        <DeleteKeyDialog
          apiKey={row.original}
          closeModal={() => setMenuAction(null)}
        />
      )}
    </Container>
  );
}

const Container = styled.div`
  position: relative;
`;

const RowActionsButton = styled.button.attrs({
  children: (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 6C13.1046 6 14 5.10457 14 4C14 2.89543 13.1046 2 12 2C10.8954 2 10 2.89543 10 4C10 5.10457 10.8954 6 12 6Z"
        fill="currentColor"
      />
      <path
        d="M12 13.7778C13.1046 13.7778 14 12.8823 14 11.7778C14 10.6732 13.1046 9.77777 12 9.77777C10.8954 9.77777 10 10.6732 10 11.7778C10 12.8823 10.8954 13.7778 12 13.7778Z"
        fill="currentColor"
      />
      <path
        d="M12 21.5555C13.1046 21.5555 14 20.6601 14 19.5555C14 18.4509 13.1046 17.5555 12 17.5555C10.8954 17.5555 10 18.4509 10 19.5555C10 20.6601 10.8954 21.5555 12 21.5555Z"
        fill="currentColor"
      />
    </svg>
  ),
})`
  cursor: pointer;
  appearance: none;
  border: none;
  background: none;
  padding: 0;
  margin: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 24px;
    height: 24px;
  }
`;
