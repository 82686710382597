import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import TagManager from "react-gtm-module";

const TAG_MANAGER_ID = process.env.REACT_APP_TAG_MANAGER_ID;

const AppWrapper = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    if (TAG_MANAGER_ID) {
      // eslint-disable-next-line no-console
      console.log("Initializing Google Tag Manager...");
      TagManager.initialize({
        gtmId: TAG_MANAGER_ID,
      });
    } else {
      console.warn(
        "No Google Tag Manager ID found. " +
          "Please set REACT_APP_TAG_MANAGER_ID in .env."
      );
    }
  }, []);

  useEffect(() => {
    if (TAG_MANAGER_ID) {
      // track route changes and update the Data Layer
      TagManager.dataLayer({
        dataLayer: {
          event: "pageview",
          page: location.pathname,
        },
      });
    }
  }, [location]);

  return <>{children}</>;
};

export default AppWrapper;
