import styled from "styled-components";
import { Select } from "antd";

export const StyledSelectField = styled(Select)`
  margin-right: 17px;
  width: ${(props) =>
    props.space === "full" ? "435px !important" : "139px !important"};

  .ant-select-selector {
    border-radius: 10px !important;
    border: 1px solid
      ${({ error }) =>
        error ? "red !important" : "rgba(0, 0, 0, 0.5) !important"};
    width: ${(props) =>
      props.space === "full" ? "435px !important" : "139px !important"};
    display: flex !important;
    height: 51px !important;
    align-items: center !important;
    padding: 0 12px !important;
    background: rgb(245, 245, 245) !important;
  }

  .ant-select-selection-search-input {
    height: 100% !important;
  }

  .ant-select-arrow {
    font-size: 16px;
    color: black;
  }
`;
