import React from "react";
import { Link } from "react-router-dom";
import { useCustomerInfo } from "../Billing/useCustomerInfo";
import WELCOME_IMG from "./Welcome.png";
import Widget, { WidgetContent } from "./Widget";

const defaultContent: WidgetContent = {
  title: "Welcome! You are just one API key away from the AI revolution.",
  description:
    "Create your first API key and get unrestrained access to our whole library of AI-powered BOTs. Start automate now.",
  buttonLabel: "Create API key",
  image: {
    src: WELCOME_IMG,
    alt: "",
  },
};

const { Button, Heading, Paragraph, Image, Footer } = Widget;

export default function CreateKeyWidget() {
  const { customer } = useCustomerInfo();
  return (
    <Widget
      content={{
        ...defaultContent,
        username: customer?.first_name,
      }}
      button={<Button as={Link} to="/api-keys" />}
      heading={<Heading />}
      paragraph={<Paragraph />}
      image={<Image />}
      footer={
        <Footer>
          <Footer.DocsLink />
        </Footer>
      }
    />
  );
}
