// import original module declarations
import "styled-components";

export const myTheme = {
  colors: {
    yellow: "#FFCD23",
    black: "#000000",
    orange: "#FF9B23",
    gray: "#F5F5F5",
    white: "#FFFFFF",
    red: "#B3001B",
    green: "#4f9D69",
    dimmed: {
      yellow: "hsl(46.36deg 100% 65%)",
      black: "hsl(0deg 0% 15%)",
    },
  },
};

export type MyTheme = typeof myTheme;

// and extend them!
declare module "styled-components" {
  export interface DefaultTheme extends MyTheme {}
}
