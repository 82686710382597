import React, { ComponentPropsWithoutRef, useContext } from "react";
import { WidgetContext } from "./Context";
import { StyledGreeting } from "./Styled";

export type WidgetGreetingProps = ComponentPropsWithoutRef<
  typeof StyledGreeting
>;

export function WidgetGreeting({ children, ...props }: WidgetGreetingProps) {
  const { username } = useContext(WidgetContext);
  if (username) {
    return (
      <StyledGreeting {...props}>{children || `Hi ${username}`}</StyledGreeting>
    );
  }
  return null;
}
