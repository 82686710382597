import { Formik } from "formik";
import React, { useContext, useState } from "react";
import styled from "styled-components";
import { AuthContext } from "../../../AppContext";
import CustomSelectField from "../../Common/Fields/CustomSelectField";
import CustomLoader from "../../Common/Loader/CustomLoader";
import { CustomLabel, Input, SubmitButton, Wrapper } from "../Register/Styled";
import { Switch } from "antd";
import {
  countryOptions,
  roleOptions,
  taxIdTypes,
  countryPrefixes,
} from "../../../Constants/SelectOptions";
import edit from "../../../Assets/Images/edit.svg";

function findObjectByValue(arr, value) {
  for (let i = 0; i < arr.length; i++) {
    if (
      arr[i] &&
      (arr[i].value === value || arr[i].value.toString() === value.toString())
    ) {
      return arr[i];
    }
  }
  return undefined;
}

export default function UserData() {
  const { customer, billingCustomer } = useContext(AuthContext);
  const [updateContact, setUpdateContact] = useState(false);
  const [updateBillingInfo, setUpdateBillingInfo] = useState(false);
  const onEdit = () => {
    setUpdateContact(true);
  };
  const onBillingEdit = () => {
    setUpdateBillingInfo(true);
  };
  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <SectionTitle>Contact Info</SectionTitle>
        <div style={{ display: "flex" }}>
          <ActionText onClick={() => onEdit()}>Edit</ActionText>
          <img src={edit} />
        </div>
      </div>
      {!customer && !billingCustomer ? (
        <CustomLoader />
      ) : (
        <Formik
          initialValues={{
            name: customer?.data?.first_name,
            surname: customer?.data?.last_name,
            prefix: customer?.data?.phone?.substring(
              0,
              customer?.data?.phone.indexOf("-")
            ),
            phoneNumber: customer?.data?.phone?.slice(
              customer.data.phone.indexOf("-") + 1
            ),
            city: billingCustomer.address?.city,
            address: billingCustomer.address?.line1,
            address2: billingCustomer?.address?.line2,
            zipCode: billingCustomer.address?.postal_code,
            taxId: billingCustomer.tax_id,
            businessType: billingCustomer?.type,
            state: billingCustomer?.address?.state,
            vat: billingCustomer?.vat_id,
            sdiCode: billingCustomer.sdi,
            companyName: billingCustomer?.business_name,
          }}
          validateOnChange={false}
          validateOnBlur={false}
        >
          <div>
            <Form
              style={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: "white",
                borderRadius: "20px",
                paddingLeft: "24px",
                paddingBottom: "24px",
              }}
            >
              <CustomLabel htmlFor="name">Name</CustomLabel>
              <Input type="text" name="name" disabled={!updateContact} />
              <CustomLabel htmlFor="surname">Surname</CustomLabel>
              <Input type="text" name="surname" disabled={!updateContact} />
              <CustomLabel htmlFor="phoneNumber">Phone number</CustomLabel>
              <Wrapper>
                <CustomSelectField
                  options={[
                    findObjectByValue(
                      countryPrefixes,
                      customer?.data?.phone?.substring(
                        0,
                        customer?.data?.phone.indexOf("-")
                      )
                    ),
                  ]}
                  name="prefix"
                  disabled={!updateContact}
                />
                <div>
                  <Input
                    type="number"
                    name="phoneNumber"
                    disabled={!updateContact}
                    style={{ width: "280px" }}
                  />
                </div>
              </Wrapper>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <CustomLabel htmlFor="role">Role</CustomLabel>
                <CustomSelectField
                  name="role"
                  options={[
                    findObjectByValue(roleOptions, customer?.data.role),
                  ]}
                  disabled={!updateBillingInfo}
                  space="full"
                />
              </div>
              {updateContact && (
                <div
                  style={{
                    display: "flex",
                    marginTop: "40px",
                    marginBottom: "40px",
                  }}
                >
                  <Button
                    primary
                    onClick={() => {
                      setUpdateContact(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button style={{ marginLeft: "18px" }}>Save</Button>
                </div>
              )}
            </Form>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <SectionTitle>Billing Info</SectionTitle>
              <div style={{ display: "flex" }}>
                <ActionText onClick={() => onBillingEdit()}>Edit</ActionText>
                <img src={edit} />
              </div>
            </div>
            <Form
              style={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: "white",
                borderRadius: "20px",
                paddingLeft: "24px",
                paddingBottom: "24px",
              }}
            >
              <CustomLabel htmlFor="country">Country*</CustomLabel>
              <CustomSelectField
                name="country"
                options={[
                  findObjectByValue(
                    countryOptions,
                    billingCustomer?.address.country
                  ),
                ]}
                space="full"
                disabled={!updateBillingInfo}
              />
              {billingCustomer?.type !== "PRIVATE" && (
                <>
                  <CustomLabel htmlFor="companyName">Company Name*</CustomLabel>
                  <Input
                    placeholder="Company name"
                    type="text"
                    name="companyName"
                    disabled={!updateBillingInfo}
                  />
                </>
              )}

              {billingCustomer?.type === "BUSINESS" && (
                <>
                  <CustomLabel htmlFor="taxType">Vat type</CustomLabel>
                  <CustomSelectField
                    name="taxType"
                    options={[
                      findObjectByValue(taxIdTypes, billingCustomer?.vat_type),
                    ]}
                    space="full"
                    disabled={!updateBillingInfo}
                  />

                  <CustomLabel htmlFor="vat">VAT number*</CustomLabel>
                  <Input
                    placeholder="Vat number"
                    type="text"
                    name="vat"
                    disabled={!updateBillingInfo}
                  />
                </>
              )}

              {billingCustomer?.type === "PRIVATE" && (
                <>
                  <CustomLabel htmlFor="taxId">TAX ID*</CustomLabel>
                  <Input
                    placeholder="TAX ID"
                    type="text"
                    name="taxId"
                    disabled={!updateBillingInfo}
                  />
                </>
              )}

              <CustomLabel htmlFor="city">City*</CustomLabel>
              <Input
                placeholder="city"
                type="text"
                name="city"
                disabled={!updateBillingInfo}
              />
              <CustomLabel htmlFor="state">State/Region</CustomLabel>
              <Input placeholder="State/Region" type="text" name="state" />
              <CustomLabel htmlFor="address">Address*</CustomLabel>
              <Input
                placeholder="Address"
                type="text"
                name="address"
                disabled={!updateBillingInfo}
              />
              {billingCustomer?.address?.line2 !== "" && (
                <>
                  <CustomLabel htmlFor="address2">Address line 2</CustomLabel>
                  <Input placeholder="Address" type="text" name="address2" />
                </>
              )}
              <CustomLabel htmlFor="zipCode">Zip code*</CustomLabel>
              <Input
                placeholder="Zip code"
                type="text"
                name="zipCode"
                disabled={!updateBillingInfo}
              />

              {billingCustomer?.country === "IT" &&
                billingCustomer?.type !== "PRIVATE" && (
                  <>
                    <CustomLabel htmlFor="sdiCode">SDI code*</CustomLabel>
                    <Input
                      placeholder="SDI code"
                      type="text"
                      name="sdiCode"
                      disabled={!updateBillingInfo}
                    />
                    <CustomLabel htmlFor="pec">PEC address</CustomLabel>
                    <Input placeholder="Pec address" type="email" name="pec" />
                  </>
                )}
              {updateBillingInfo && (
                <div
                  style={{
                    display: "flex",
                    marginTop: "40px",
                    marginBottom: "40px",
                  }}
                >
                  <Button
                    primary
                    onClick={() => {
                      setUpdateBillingInfo(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button style={{ marginLeft: "18px" }}>Save</Button>
                </div>
              )}
            </Form>
            <SectionTitle>Email preferences</SectionTitle>

            <Form>
              <Title>Marketing emails</Title>
              <Wrapper
                style={{
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <p>
                  I want to receive custom offers and find out about
                  botshelf.ai's latest updates by email.
                </p>
                <Switch
                  disabled={true}
                  checked={customer.data.newsletter}
                  style={{ width: "50px", opacity: "0.3" }}
                />
              </Wrapper>
            </Form>
          </div>
        </Formik>
      )}
    </div>
  );
}

const Heading = styled.h1`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 33px;
  line-height: 116.4%;
  margin: 45px 0 15px;
`;

const SectionTitle = styled.p`
  font-style: normal;
  font-weight: 700;
  font-size: 27px;
  line-height: 138.4%;
  color: #000000;
`;

const ActionText = styled.p`
  text-align: initial;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 116.4%;
  margin: 45px 0 15px;
  text-decoration: underline;
`;

const Form = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 20px;
  padding: 24px;
`;
const Title = styled.p`
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 116.4%;
  margin-bottom: 0;
`;
const Button = styled.button`
  background: ${(props) => (props.primary ? "white" : "black")};
  color: ${(props) => (props.primary ? "black" : "white")};
  width: 207px;
  height: 57px;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.18);
  border-radius: 6px;
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 34px;
  /* identical to box height */
  text-align: center;
  text-transform: uppercase;
`;
