import styled from "styled-components";

const BotsCatalogueIcon = styled.svg.attrs((props) => ({
  ...props,
  role: "presentation",
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: (
    <path
      d="M10.573 22h-.002a2.328 2.328 0 0 1-1.657-.688l-6.23-6.24a2.348 2.348 0 0 1 0-3.311l8.399-8.416A4.552 4.552 0 0 1 14.326 2h5.329a2.346 2.346 0 0 1 2.344 2.344v5.312a4.551 4.551 0 0 1-1.344 3.242l-8.426 8.417a2.329 2.329 0 0 1-1.656.685Zm3.753-18.438a3 3 0 0 0-2.137.887l-8.4 8.415a.783.783 0 0 0 0 1.104l6.231 6.24c.148.148.344.23.552.23h.001a.776.776 0 0 0 .552-.229l8.426-8.416a3 3 0 0 0 .885-2.137V4.344c0-.431-.35-.782-.781-.782h-5.33Zm2.008 6.368a2.346 2.346 0 0 1-2.343-2.344 2.346 2.346 0 0 1 2.343-2.344 2.346 2.346 0 0 1 2.344 2.344 2.346 2.346 0 0 1-2.344 2.344Zm0-3.125a.782.782 0 1 0 .002 1.564.782.782 0 0 0-.002-1.564Z"
      fill="currentColor"
    />
  ),
}))`
  width: 22px;
`;

export default BotsCatalogueIcon;
