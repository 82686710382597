import moment from "moment";
import { useGetAllKeysQuery } from "./getAllKeys";

export function parseIssuedAt(unixEpoch: number) {
  return moment.unix(unixEpoch);
}

export function formatIssuedAt(momentDate: moment.Moment) {
  return momentDate.format("LLL");
}

export function generateIssuedAt() {
  return moment().unix();
}

export const MAX_NUMBER_OF_KEYS = 5;

export function useHasTooManyKeys() {
  const { data = [] } = useGetAllKeysQuery();
  return data?.length > MAX_NUMBER_OF_KEYS;
}
