import styled from "styled-components";
import { APIKey } from "./utils/types";
import { Row } from "@tanstack/react-table";
import React from "react";

export type RowMenuProps = {
  row: Row<APIKey>;
  onRevoke: () => void;
  onDelete: () => void;
};

export default function RowMenu({ row, onRevoke, onDelete }: RowMenuProps) {
  const apiKey = row.original;
  return (
    <Container>
      {apiKey.status === "ACTIVE" && <RevokeButton onClick={onRevoke} />}
      {apiKey.status === "REVOKED" && <DeleteButton onClick={onDelete} />}
    </Container>
  );
}

const Container = styled.div`
  position: absolute;
  top: 80%;
  right: 0px;

  background: #ffffff;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.18);
  z-index: 1;

  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.18);
`;

const RowActionsMenuButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  appearance: none;

  width: 188px;
  height: 55px;
  padding: 0 17px;
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: inherit;

  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  text-decoration-line: underline;

  cursor: pointer;
  &:not(:disabled):hover {
    background: ${({ theme }) => theme.colors.gray};
  }

  &:not(:first-child) {
    border-top: solid 1px #e5e5e5;
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

const RevokeButton = styled(RowActionsMenuButton).attrs({
  "aria-label": "Revoke API key",
  children: (
    <>
      <svg
        role="presentation"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10 0C4.47301 0 0 4.4725 0 10C0 15.5269 4.4725 20 10 20C15.527 20 20 15.5275 20 10C20 4.47301 15.5275 0 10 0ZM10 18.6046C5.25539 18.6046 1.39535 14.7446 1.39535 10C1.39535 5.25535 5.25539 1.39535 10 1.39535C14.7446 1.39535 18.6046 5.25535 18.6046 10C18.6046 14.7446 14.7446 18.6046 10 18.6046Z"
          fill="currentColor"
        />
        <path
          d="M13.3334 14C13.1627 14 12.9921 13.9349 12.862 13.8046L6.19536 7.13801C5.93488 6.87753 5.93488 6.4557 6.19536 6.19537C6.45569 5.93503 6.87767 5.93489 7.138 6.19537L13.8046 12.862C14.0651 13.1224 14.0651 13.5443 13.8046 13.8046C13.6744 13.9349 13.504 14 13.3334 14Z"
          fill="currentColor"
        />
        <path
          d="M6.66661 14C6.496 14 6.32553 13.9349 6.19536 13.8046C5.93488 13.5443 5.93488 13.1223 6.19536 12.862L12.862 6.19536C13.1224 5.93488 13.5443 5.93488 13.8046 6.19536C14.0649 6.45569 14.0651 6.87767 13.8046 7.138L7.138 13.8046C7.00784 13.9349 6.83722 14 6.66661 14Z"
          fill="currentColor"
        />
      </svg>
      <span>Revoke</span>
    </>
  ),
})``;

const DeleteButton = styled(RowActionsMenuButton).attrs({
  "aria-label": "Delete API key",
  children: (
    <>
      <svg
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <path
          d="M15.84 22.25H8.16a3.05 3.05 0 0 1-3-2.86L4.25 5.55a.76.76 0 0 1 .45-.731.77.77 0 0 1 .3-.069h14a.75.75 0 0 1 .75.8l-.87 13.84a3.05 3.05 0 0 1-3.04 2.86Zm-10-16 .77 13.05a1.55 1.55 0 0 0 1.55 1.45h7.68a1.56 1.56 0 0 0 1.55-1.45l.81-13-12.36-.05Z"
          fill="currentColor"
        />
        <path
          d="M21 6.25H3a.75.75 0 0 1 0-1.5h18a.75.75 0 1 1 0 1.5Z"
          fill="currentColor"
        />
        <path
          d="M15 6.25H9a.76.76 0 0 1-.75-.75V3.7a2 2 0 0 1 1.95-1.95h3.6a2 2 0 0 1 1.95 2V5.5a.76.76 0 0 1-.75.75Zm-5.25-1.5h4.5v-1a.45.45 0 0 0-.45-.45h-3.6a.45.45 0 0 0-.45.45v1ZM15 18.25a.76.76 0 0 1-.75-.75v-8a.75.75 0 1 1 1.5 0v8a.76.76 0 0 1-.75.75ZM9 18.25a.76.76 0 0 1-.75-.75v-8a.75.75 0 0 1 1.5 0v8a.76.76 0 0 1-.75.75ZM12 18.25a.76.76 0 0 1-.75-.75v-8a.75.75 0 1 1 1.5 0v8a.76.76 0 0 1-.75.75Z"
          fill="currentColor"
        />
      </svg>
      <span>Delete</span>
    </>
  ),
})``;
