import React, { ComponentPropsWithoutRef } from "react";
import { StyledDocsLink, StyledQuestionIcon } from "./Styled";

export type WidgetDocsLinkProps = ComponentPropsWithoutRef<
  typeof StyledDocsLink
>;

export function WidgetFooterDocsLink({
  children,
  ...props
}: WidgetDocsLinkProps) {
  return (
    <StyledDocsLink {...props}>
      <StyledQuestionIcon />
      Don’t know where to start? Read our{" "}
      <a href="https://developer.beta.botshelf.ai/quick-start">
        quick start guide.
      </a>
    </StyledDocsLink>
  );
}
