import React from "react";
import NotifyBanner, { NotifyBannerContent } from "../../Common/NotifyBanner";
import { useCustomerInfo } from "../Billing/useCustomerInfo";
import TotalCallsCounter from "./TotalCallsCounter";
import WELCOME_IMG from "./Welcome.png";
import Widget, { WidgetContent } from "./Widget";

const bannerContent: NotifyBannerContent = {
  title: "Your free trial has ended",
  description:
    "From now on you will be charged for any further calls, billed monthly.",
};

const widgetContent: WidgetContent = {
  title: "Keep on going: here's your AI journey in a glimpse",
  description: `Thanks for trusting botshelf.ai for your AI solutions. You are making your business smarter one BOT at a time.`,
  image: {
    src: WELCOME_IMG,
    alt: "",
  },
};

const { Greeting, Heading, Paragraph, Image, Footer } = Widget;

export default function TotalCallsWidget() {
  const { customer } = useCustomerInfo();
  return (
    <>
      <NotifyBanner
        content={bannerContent}
        title={<NotifyBanner.Title />}
        description={<NotifyBanner.Description />}
        icon={<NotifyBanner.WarningIcon />}
      />
      <Widget
        content={{
          ...widgetContent,
          username: customer?.first_name,
        }}
        greeting={<Greeting />}
        heading={<Heading />}
        paragraph={<Paragraph />}
        image={<Image />}
        footer={
          <Footer>
            <Footer.DocsLink />
          </Footer>
        }
      >
        <TotalCallsCounter />
      </Widget>
    </>
  );
}
