import NotifyBanner from "../../../Common/NotifyBanner";
import styled from "styled-components";
import React from "react";

const topBannerContent = {
  title: "Free trial ended",
  description:
    "You have consumed all free API calls. You will now be billed monthly for all future calls.",
  hideButtonLabel: "Don’t show this message again",
};

export type TopBannerComponentProps = {
  onHide: () => void;
  className?: string;
};

function TopBannerComponent({ onHide, className }: TopBannerComponentProps) {
  return (
    <NotifyBanner
      className={className}
      content={topBannerContent}
      title={<NotifyBanner.Title />}
      description={<NotifyBanner.Description />}
      icon={<NotifyBanner.WarningIcon />}
      hideButton={<NotifyBanner.HideButton onClick={onHide} />}
    />
  );
}

export const TopBanner = styled(TopBannerComponent)`
  margin-bottom: 24px;
`;
