import React, { ComponentPropsWithoutRef, useContext } from "react";
import { WidgetContext } from "./Context";
import { StyledParagraph } from "./Styled";

export type WidgetParagraphProps = ComponentPropsWithoutRef<
  typeof StyledParagraph
>;

export function WidgetParagraph({ children, ...props }: WidgetParagraphProps) {
  const { description } = useContext(WidgetContext);
  return (
    <StyledParagraph {...props}>{children || description}</StyledParagraph>
  );
}
