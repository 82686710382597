import React from "react";
import { useCustomerInfo } from "../Billing/useCustomerInfo";
import BillableRequests from "./Counters/BillableRequests";
import CounterList from "./Counters/CounterList";
import FreeRequests from "./Counters/FreeRequests";
import MonthlyRequests from "./Counters/MonthlyRequests";
import TotalRequests from "./Counters/TotalRequests";

export default function Counters() {
  const { customer, hasFreeTrial } = useCustomerInfo();
  return (
    <CounterList>
      {hasFreeTrial ? (
        <FreeRequests customer={customer} />
      ) : (
        <>
          <TotalRequests customer={customer} />
          <MonthlyRequests />
          <BillableRequests customer={customer} />
        </>
      )}
    </CounterList>
  );
}
