import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import { Auth } from "aws-amplify";
import React from "react";
import { useTheme } from "styled-components";
import { GoogleText } from "../../Screens/Register/Styled";
import googleIcon from "../../../Assets/Images/googleIcon.svg";

const GoogleLoginButton = ({ state, text }) => {
  const responseGoogle = (response) => {
    Auth.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider.Google,
      customState: state,
    });
  };
  const theme = useTheme();

  return (
    <button
      onClick={responseGoogle}
      type="button"
      style={{
        backgroundColor: theme.colors.white,
        display: "flex",
        justifyContent: "center",
        height: 57,
        alignItems: "center",
        borderRadius: 6,
        width: 435,
        cursor: "pointer",
      }}
    >
      <GoogleText>
        <img src={googleIcon} style={{ width: "24px", height: "24px" }} />
        &nbsp; {text}
      </GoogleText>
    </button>
  );
};

export default GoogleLoginButton;
