import styled from "styled-components";

export const StyledContainer = styled.div`
  background: #ffffff;
  border-radius: 16px;
  padding: 24px 30px;
  column-gap: 60px;
  row-gap: 24px;
  max-width: 1000px;

  display: flex;
  flex-direction: column;

  @media (min-width: 1024px) {
    display: grid;
    grid-template-columns:
      minmax(min-content, 520px)
      minmax(250px, 350px);
  }
`;
export const StyledImage = styled.img`
  width: 100%;
  height: 300px;
  object-fit: contain;
  object-position: center;
`;
export const StyledGreeting = styled.p`
  font-weight: 400;
  font-size: 20px;
  line-height: 116.4%;
  margin: 0 0 16px;
`;
export const StyledHeading = styled.h3`
  font-weight: 700;
  font-size: 33px;
  line-height: 116.4%;
  margin: 0 0 16px;
`;
export const StyledParagraph = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  margin: 0 0 32px;
`;
export const StyledButton = styled.button`
  appearance: none;
  margin: 0;
  color: #ffffff;
  background: #000000;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.18);
  border: none;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 24px;

  width: max-content;
  min-width: 200px;
  border-radius: 6px;

  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  text-decoration: none;
`;

export const StyledFooter = styled.div`
  grid-column: 1 / -1;
`;
