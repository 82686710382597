import React from "react";
import loader from "../../../Assets/Images/giffycanvas.gif";
import styled from "styled-components";

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background: white;
`;

const LoaderImage = styled.img`
  max-width: 100%;
`;

const CustomLoader = () => {
  return (
    <LoaderContainer>
      <LoaderImage src={loader} alt={"loader"} />
    </LoaderContainer>
  );
};

export default CustomLoader;
