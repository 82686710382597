import { StyledContainer } from "./Styled";
import React from "react";

export type NotifyBannerContainerProps = React.ComponentProps<
  typeof StyledContainer
>;

export function NotifyBannerContainer(props: NotifyBannerContainerProps) {
  return <StyledContainer {...props} />;
}
