import React from "react";
import styled from "styled-components";
import FreeTrial from "./FreeTrial";
import PostFreeTrial from "./PostFreeTrial";
import { useCustomerInfo } from "./useCustomerInfo";

export default function Billing() {
  const { hasFreeTrial } = useCustomerInfo();
  return (
    <Container>
      <Heading>Billing</Heading>
      {hasFreeTrial ? <FreeTrial /> : <PostFreeTrial />}
    </Container>
  );
}

const Container = styled.div`
  padding: 1em 24px;
  @media (min-width: 768px) {
    padding: 1em 40px;
  }
  max-width: 1000px;
  margin: 0 auto;
`;

const Heading = styled.h1`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 33px;
  line-height: 116.4%;
  margin: 0 0 15px;
`;
