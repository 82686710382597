import {
  Chart as ChartJS,
  ChartData,
  ChartOptions,
  LinearScale,
  LineElement,
  PointElement,
  TimeScale,
  Tooltip,
} from "chart.js";
import "chartjs-adapter-moment";
import moment from "moment";
import React from "react";
import { Line } from "react-chartjs-2";
import styled from "styled-components";

ChartJS.defaults.font.family = "Nunito";

ChartJS.register(LinearScale, LineElement, TimeScale, PointElement, Tooltip);

const options: ChartOptions<"line"> = {
  responsive: true,
  font: {
    size: 12,
  },
  plugins: {
    tooltip: {
      displayColors: false,
      callbacks: {
        label: (context) => {
          const label = context.dataset.label || "";
          const value = context.parsed.y || 0;
          return `${value} ${label} `;
        },
        title: (context) => {
          const value = context[0].parsed.x || 0;
          return moment(value).format("DD MMMM YYYY");
        },
      },
      backgroundColor: "#000",
      titleFont: {
        size: 12,
        weight: "normal",
      },
      bodyFont: {
        size: 14,
        weight: "bold",
      },
    },
  },
  scales: {
    x: {
      type: "time",
      time: {
        unit: "day",
        displayFormats: {
          day: "DD/MM",
        },
      },
      ticks: {
        stepSize: 2,
      },
    },
    y: {
      position: "right",
      grid: {
        display: false,
      },
      ticks: {
        stepSize: 1,
      },
      beginAtZero: true,
    },
  },
  layout: {
    padding: 0,
  },
  maintainAspectRatio: false,
};

/** https://www.chartjs.org/docs/latest/configuration/responsive.html#important-note */
const Container = styled.div`
  position: relative;
  height: 300px;
  width: 100%;
`;

export default function DesktopChart({ data }: { data?: ChartData<"line"> }) {
  return (
    <Container>{data && <Line options={options} data={data} />}</Container>
  );
}
