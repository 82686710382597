import { useCallback, useContext, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { AuthContext } from "../../../../AppContext";
import { useCustomerInfo } from "../../Billing/useCustomerInfo";

const userMenuLinks = [
  // {
  //   to: "/settings",
  //   label: "Settings",
  // },
  // {
  //   to: "/payments",
  //   label: "Payment Method",
  // },
  {
    to: "/account",
    label: "Account details",
  },
  {
    to: "/payment-method",
    label: "Payment Method",
  },
  {
    to: "/billing",
    label: "Billing",
  },
];

export default function UserMenu() {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <UserMenuContainer>
      <UserMenuButton isOpen={isOpen} toggleIsOpen={() => setIsOpen(!isOpen)} />
      <UserMenuNav isOpen={isOpen} />
    </UserMenuContainer>
  );
}

const UserMenuContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border-top: 4px solid ${({ theme }) => theme.colors.yellow};
  position: sticky;
  bottom: 0;
  z-index: 1;
  background: inherit;
`;

function UserMenuButton(props) {
  const { customer } = useCustomerInfo();

  if (!customer) return null;

  const fullName = customer.first_name + " " + customer.last_name;
  const email = customer.email;
  const initial = customer.first_name[0];

  return (
    <UserMenuButtonContainer aria-label={fullName} onClick={props.toggleIsOpen}>
      <UserMenuButtonAvatar>{initial}</UserMenuButtonAvatar>
      <UserMenuButtonInfo>
        {fullName && (
          <UserMenuButtonInfoName>{fullName}</UserMenuButtonInfoName>
        )}
        <UserMenuButtonInfoEmail>{email}</UserMenuButtonInfoEmail>
      </UserMenuButtonInfo>
      <UserMenuButtonIcon $isOpen={props.isOpen} />
    </UserMenuButtonContainer>
  );
}

const UserMenuButtonContainer = styled.button`
  appearance: none;
  border: none;
  background: none;
  padding: 30px 27px;
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 16px;
  width: 100%;
  color: inherit;

  @media (min-width: 1024px) {
    padding: 30px 22px;
    gap: 12px;
  }
`;
const UserMenuButtonAvatar = styled.div.attrs(() => ({
  role: "presentation",
}))`
  width: 20px;
  height: 20px;
  background: ${({ theme }) => theme.colors.yellow};
  color: #000000;

  align-self: flex-start;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  font-weight: 700;
  font-size: 13px;
  line-height: 1;
`;

const UserMenuButtonInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;

  flex: 1;
  min-width: 0;
`;
const UserMenuButtonInfoName = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 116.4%;

  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const UserMenuButtonInfoEmail = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 116.4%;

  @media (min-width: 1024px) {
    color: rgba(0, 0, 0, 0.5);
  }

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
`;

function UserMenuNav(props) {
  const navigate = useNavigate();
  const { signOut } = useContext(AuthContext);

  const handleLogout = useCallback(async () => {
    await signOut();
    navigate("/login");
  }, [navigate, signOut]);

  return (
    <UserMenuNavContainer $isOpen={props.isOpen}>
      {userMenuLinks.map((link) => (
        <UserMenuNavLink to={link.to} key={link.label}>
          {link.icon} {link.label}
        </UserMenuNavLink>
      ))}
      <UserMenuLogoutButton onClick={() => handleLogout()}>
        <LogoutIcon /> Logout
      </UserMenuLogoutButton>
      <UserMenuNavSpacer />
    </UserMenuNavContainer>
  );
}

const UserMenuNavSpacer = styled.div`
  height: 25px;
  @media (min-width: 1024px) {
    height: 27px;
  }
`;

const UserMenuNavContainer = styled.nav.attrs(() => ({
  id: "sidebar-user-nav",
}))`
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out, opacity 0.5s ease-in-out;
  opacity: 0;
  ${(props) => props.$isOpen && "max-height: 203px; opacity: 1;"}
  box-sizing: content-box;
`;

const UserMenuNavLink = styled(NavLink)`
  display: block;
  padding: 10px 0;
  text-decoration: none;

  height: 44px;
  display: flex;
  align-items: center;
  padding: 0 30px;

  font-weight: 500;
  font-size: 17px;
  line-height: 23px;

  gap: 12px;
  color: inherit;

  &.active {
    background: ${({ theme }) => theme.colors.yellow};
    color: #000;
    font-weight: 800;
  }
  @media (min-width: 768px) {
    padding: 0 54px;
  }
`;

const UserMenuLogoutButton = styled.button`
  appearance: none;
  border: none;
  background: none;
  display: block;
  padding: 10px 0;
  cursor: pointer;
  display: block;

  height: 44px;
  display: flex;
  align-items: center;
  padding: 0 30px;

  font-weight: 500;
  font-size: 17px;
  line-height: 23px;

  gap: 12px;
  color: inherit;

  @media (min-width: 768px) {
    padding: 0 54px;
  }
`;

function LogoutIcon() {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width={21}
      height={20}
      viewBox="0 0 21 20"
    >
      <path
        d="M10 18.333H2.5a.834.834 0 0 1-.833-.833v-15c0-.46.373-.833.833-.833H10A.833.833 0 1 0 10 0H2.5A2.503 2.503 0 0 0 0 2.5v15C0 18.878 1.122 20 2.5 20H10a.833.833 0 1 0 0-1.667Z"
        fill="currentColor"
      />
      <path
        d="m19.82 9.407-5.067-5a.833.833 0 1 0-1.17 1.187l3.62 3.573H7.502a.833.833 0 1 0 0 1.667h9.703l-3.621 3.573a.833.833 0 1 0 1.17 1.187l5.067-5a.835.835 0 0 0 0-1.187Z"
        fill="currentColor"
      />
    </svg>
  );
}

const UserMenuButtonIcon = styled((props) => {
  return (
    <svg
      role="presentation"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 15 9"
      {...props}
    >
      <path
        d="m1.25 1.375 6.25 6.25 6.25-6.25"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
})`
  width: 14px;
  height: 20px;
  box-sizing: border-box;
  transition: transform 0.3s ease-in-out;
  ${({ $isOpen }) => $isOpen && "transform: rotate(180deg)"};
  margin-bottom: auto;
`;
