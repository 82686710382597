import { Auth } from "aws-amplify";
import isEqual from "lodash.isequal";
import React, { createContext, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { get_customer } from "./Components/Common/Billing";
export const AuthContext = createContext();
const base_url = process.env.REACT_APP_API_URL + "/v1/billing-system";

export const AuthProvider = ({ children }) => {
  const [step, setStep] = useState(1);
  const [hasUpdatedPayment, setHasUpdatedPayment] = useState(null);
  const [setupIntent, setSetupIntent] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [user, setUpdatedUser] = useState(null);
  const [generatePayment, setGeneratePayment] = useState(false);
  const [oauthState, setOauthState] = useState(null);
  const [resetPageStep, setResetPageStep] = useState(1);
  const [customer, setCustomer] = useState(null);
  const [billingCustomer, setBillingCustomer] = useState(null);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (user === null) {
      Auth.currentAuthenticatedUser({ bypassCache: true })
        .then((user) => setUpdatedUser(user))
        .catch(() => setUpdatedUser(null));
    } else if (user && customer === null) {
      getCustomer();
    } else {
      checkBillingData();
    }

    // else if (user.attributes.name) {
    //   checkBillingData();
    // }
  }, [user, customer]);

  const [prospect, setProspect] = useState(null);
  const [idempotencyKey, setIdempotencyKey] = useState(null);
  async function getCustomer() {
    if (user) {
      setLoader(true);
      const res = await Auth.currentSession();
      const accessToken = res.getAccessToken();
      const jwt = accessToken.getJwtToken();

      const isDuplicate = prospect && isEqual(prospect, jwt);
      const localIdempotencyKey = isDuplicate ? idempotencyKey : uuidv4();
      if (!isDuplicate) {
        setProspect(jwt);
        setIdempotencyKey(localIdempotencyKey);
      }
      const customerData = await get_customer(jwt, localIdempotencyKey);
      if (customerData.status === "success") {
        setCustomer(false);
        setCustomer(customerData);
      } else {
        setLoader(false);
      }
    }
  }

  async function checkBillingData() {
    if (customer) {
      const res = await Auth.currentSession();
      const accessToken = res.getAccessToken();
      const jwt = accessToken.getJwtToken();

      // try {
      const customerRes = await fetch(`${base_url}/customer`, {
        headers: {
          Authorization: `${jwt}`,
        },
      });
      const customerData = await customerRes.json();
      if (customerData?.status !== "success") {
        throw new Error(customerData?.error?.message);
      }
      if (customerData?.data?.default_payment_method) {
        setBillingCustomer(customerData.data);
        setHasUpdatedPayment(true);
      } else {
        setHasUpdatedPayment(false);
      }
      // } catch (err) {
      //   console.error(err);
      // }
    }
  }

  async function signIn(username, password) {
    try {
      const userData = await Auth.signIn(username, password);
      setUpdatedUser(userData);
    } catch (err) {
      console.error("error signing in", err);
    }
  }

  async function signOut() {
    await Auth.signOut();
    setUpdatedUser(null);
    setStep(1);
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        setUpdatedUser,
        signIn,
        signOut,
        step,
        setStep,
        hasUpdatedPayment,
        setupIntent,
        setSetupIntent,
        userEmail,
        setUserEmail,
        oauthState,
        setOauthState,
        generatePayment,
        setGeneratePayment,
        resetPageStep,
        setResetPageStep,
        customer,
        billingCustomer,
        loader,
        setLoader,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
