import React, { useCallback, useState } from "react";
import styled from "styled-components";
import CreateKeyFunnel from "./CreateKeyFunnel";
import { useHasTooManyKeys } from "./utils/common";
import { ButtonSecondary } from "../../Common/Button";

export default function Header() {
  const [showFunnel, setShowFunnel] = useState(false);
  const tooManyKeys = useHasTooManyKeys();
  const createDisabled = showFunnel || tooManyKeys;

  // Memoize the functions passed to the funnel
  const startFunnel = useCallback(() => setShowFunnel(true), []);
  const stopFunnel = useCallback(() => setShowFunnel(false), []);

  return (
    <>
      <Container>
        <div>
          <Title>API keys</Title>
          <Description>Create and manage your API keys.</Description>
        </div>
        <DesktopCreateButton onClick={startFunnel} disabled={createDisabled} />
      </Container>
      <MobileCreateButton onClick={startFunnel} disabled={createDisabled} />
      {showFunnel && <CreateKeyFunnel onComplete={stopFunnel} />}
    </>
  );
}

const Container = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.h1`
  margin: 0;
  font-weight: 700;
  font-size: 32px;
  line-height: 116.4%;
  margin-bottom: 10px;
`;

const Description = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  margin: 0;
`;

const DesktopCreateButton = styled(ButtonSecondary).attrs((props) => ({
  children: "CREATE API KEY",
  ...props,
}))`
  display: none;
  @media (min-width: 768px) {
    display: block;
  }
`;

const MobileCreateButton = styled.button.attrs((props) => ({
  "aria-label": "Create API key",
  children: (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15">
      <path
        d="M14.063 8.438H.937a.938.938 0 0 1 0-1.876h13.126a.938.938 0 0 1 0 1.875Z"
        fill="currentColor"
      />
      <path
        d="M7.5 15a.938.938 0 0 1-.938-.938V.938a.938.938 0 0 1 1.875 0v13.124c0 .518-.42.938-.937.938Z"
        fill="currentColor"
      />
    </svg>
  ),
  ...props,
}))`
  appearance: none;
  border: none;
  background: ${({ theme }) => theme.colors.yellow};
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.18);
  border-radius: 6px;
  margin: 25px;

  display: flex;
  justify-content: center;
  align-items: center;

  position: fixed;
  right: 0;
  bottom: 0;
  width: 56px;
  height: 56px;
  z-index: 1;

  svg {
    width: 16px;
    height: 16px;
  }

  @media (min-width: 768px) {
    display: none;
  }
`;
