import { useContext, useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { Auth } from "aws-amplify";
import { AuthContext } from "../../../AppContext";

/**
 * Wraps a route and redirects to the login page if the user is not logged in.
 * @see https://github.com/remix-run/react-router/blob/dev/examples/auth/src/App.tsx
 */
export default function RequireAuth({ children }) {
  const [user, setUser] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const { hasUpdatedPayment, customer } = useContext(AuthContext);

  useEffect(() => {
    setIsLoading(true);
    // Check if the user is logged in
    Auth.currentAuthenticatedUser()
      .then((user) => {
        setUser(user);
      })
      .catch((err) => {
        if (process.env.NODE_ENV !== "test") {
          console.error(err);
        }
        setUser(null);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const location = useLocation();
  if (!user && !customer && !isLoading && !hasUpdatedPayment) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  if (user && customer && hasUpdatedPayment) {
    // If the user is logged in, render the route as normal
    return <>{children}</>;
  }

  // If we get here, the user is still loading. Render a loading indicator.
  return null; // TODO: Add a loading indicator
}
