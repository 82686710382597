import { Auth } from "aws-amplify";

export async function signOutAllDevices() {
  try {
    const user = await Auth.currentAuthenticatedUser();
    const accessToken = user.signInUserSession.accessToken.jwtToken;

    user.globalSignOut({
      onSuccess: () => {
        console.log("Successfully signed out from all devices");
        // Perform any additional sign out actions here
      },
      onFailure: (err) => {
        console.error(err);
      },
    });
  } catch (error) {
    console.error(error);
  }
}

// Call the signOutAllDevices function to sign out from all devices
