import React, { useMemo } from "react";
import styled from "styled-components";
import { useCustomerInfo } from "../Billing/useCustomerInfo";

export default function TotalCallsCounter() {
  const { customer } = useCustomerInfo();

  const totalCalls = useMemo(() => {
    const count = customer?.total_requests || 0;
    return Intl.NumberFormat().format(count);
  }, [customer]);

  return (
    <Container>
      <CounterLabel>Total API calls made</CounterLabel>
      <CounterValue>{totalCalls}</CounterValue>
    </Container>
  );
}

const Container = styled.div`
  margin-top: 48px;
  display: flex;
  flex-direction: column;
  gap: 14px;
`;

const CounterLabel = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
`;

const CounterValue = styled.span`
  font-weight: 700;
  font-size: 33px;
  line-height: 116.4%;
`;
